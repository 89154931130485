import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import exportIcon from '../assets/export.svg';
import saveIcon from '../assets/save.svg';
import printIcon from '../assets/print.svg';
import SVG from 'react-inlinesvg';
import { API } from '../constants';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Spinner } from "reactstrap";

function Trosak() {

    const codeRef = useRef();
    const nameRef = useRef();
    const taxRef = useRef();
    const errRef = useRef();
    let inputRef

    const [trosak, setTrosak] = useState(null)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [account, setAccount] = useState(null);
    const [selectDataAccount, setSelectDataAccount] = useState([]);
    const [tax, setTax] = useState(null);
    const [selectDataTax, setSelectDataTax] = useState([]);
    const [searchInput, setSearchInput] = useState([]);
    const [editTrosak, setEditTrosak] = useState(false);

    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [errorInputTax, setErrorInputTax] = useState(false)
    const [errorMsgTax, setErrorMsgTax] = useState(false)


    const [codeFocus, setCodeFocus] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);
    const [taxFocus, setTaxFocus] = useState(false);
    const [accountFocus, setAccountFocus] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);

    const [spinner, setSpinner] = useState(false);

    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    const companyNaziv = typeof window !== 'undefined' ? localStorage.getItem('companyNaziv') : null

    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };

    const [form, setForm] = useState({
        SIFRA: '',
        NAZIV: '',
        KONTID: '',
      

    })

    const [firstCode, setfirstCode] = useState(null)

    const onSubmit = () => {

        if (form.SIFRA == " " || form.SIFRA == 0 || form.NAZIV == " " || form.NAZIV == 0 || form.POREZID == " " || form.POREZID == 0) {
            setModalErrorOpen(true)
            nameRef.current.focus()
        } else {
            if (form && (form.SIFRA != firstCode) && editTrosak) {
                fetch(API + '/trosak/update', {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            SIFRA: '',
                            NAZIV: '',
                            KONTID: '',
                          
                        })
                       
                        setAccount(null)
                        codeRef.current.focus()
                        getTableData(1, perPage)
                    })
            } else {
                fetch(API + '/trosak/new', {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            SIFRA: '',
                            NAZIV: '',
                            KONTID: '',
                           
                        })
                     
                        setAccount(null)
                        codeRef.current.focus()
                        //  gettrosakFirstFreeNumberSifra()
                        getTableData(1, perPage)
                    })
            }
        }
    }

    const getAccountData = () => {
        fetch(API + `/konto/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataAccount(data)
            })
    }



    const getTableData = (page, limit) => {
        fetch(API + `/trosak/all/${page}/${limit}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setTrosak(data.result)
                setTotal(data.total)
            })
    }


    useEffect(() => {
        getAccountData()
  
        getTableData(1, perPage)
        getTrosakFirstFreeNumberSifra()
        codeRef.current.focus();
    }, [perPage])

    useEffect(() => {
    }, [form])

    useEffect(() => {

    }, [trosak])

      // prati promjenu stanja polja SIFRA
    // ako nije popunjeno polje SIFRA, aktivira se funkcija za pronalazenje prvog slobodnog broja 
    useEffect(() => {
        getTrosakFirstFreeNumberSifra()
    }, [!form.SIFRA])


    const getTrosakFirstFreeNumberSifra = () => {
        fetch(API + `/trosak/firstFreeNumberSifra`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, SIFRA: Number(data) }))
                setfirstCode(Number(data))
            })
    }


    const onDelete = (SIFRA) => {
        fetch(API + '/trosak/delete/' + SIFRA, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    SIFRA: '',
                    NAZIV: '',
                    KONTID: '',
                  
                })
              
                setAccount(null)
                getTrosakFirstFreeNumberSifra()
                codeRef.current.focus()
                getTableData(1, perPage)
            })
    }

    const onEdit = (item) => {
        nameRef.current.focus()
        selectDataAccount.map((account) => {
            if (account.ID == item.KONTID) {
                setAccount(account)
            }
        })

        

        setForm(item)
        setEditTrosak(true)
    }

    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    const onPrint = () => {
        setSpinner(true)
        if (trosak && trosak.length === 0) {
            setSpinner(false)
            setModalErrorOpenData(true)
        } else {
            if (form) {
                form.NAZIV_PRED = companyNaziv
            }
            fetch(API + `/trosak/print/${companyNaziv}`, {
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    console.log(data, 'DATA');
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            console.log(API + data.link, 'link');
                            window.open(API + data.link)
                        }, 200);

                    }
                    setSpinner(false)
                })

        }
    }



    const defaultPropsAccount = {
        options: selectDataAccount,
        getOptionLabel: (option) => option ? `${option.KONTO} ${option.NAZ}` : '',
    };



    // validacija broja i obaveznog polja
    const helperValidateOnlyNumbers = (input) => {
        if (input.replace(/^[0-9.]+$/, '').length > 0) {
            return 'Dozvoljeni su samo brojevi!';
        } else if (input.length < 1) {
            return 'Polje je obavezno!'
        } else {
            return false
        }

    }

    // validacija praznog polja
    const helperValidateEmptyField = (input) => {
        if (input.length < 1) return 'Polje je obavezno!';
    }

    const checkTaxRequired = (value) => {
        if (!value || value == 0) {
            console.log('sssss');
            setErrorMsgTax('Porez mora biti izabran')
            setErrorInputTax(true)
            taxRef.current.focus();
            // setRepositoryFocus(true)
        } else {
            setErrorMsgTax(null)
            setErrorInputTax(false)
        }
    }

    // stepenasta
    const formCheck = (event) => {

        const target = event.target;
        let value = target.value;
        const name = event.target.name;
        switch (name) {
            case 'SIFRA': {
                setErrorMsgCode(helperValidateOnlyNumbers(value))
                setErrorInputCode(helperValidateOnlyNumbers(value) ? true : false)
                break;

            }
            case 'NAZIV': {
                setErrorMsgName(helperValidateEmptyField(value))
                setErrorInputName(helperValidateEmptyField(value) ? true : false)
                break;
            }
            case 'KONT': {
                console.log('bbbbbbbbbbbbbbbbbb');
                setErrorMsgName(helperValidateEmptyField(form.NAZIV))
                setErrorInputName(helperValidateEmptyField(form.NAZIV) ? true : false)
                break;
            }
            case 'KONTO': {
                console.log('aaaaaaaaaaaaaaaa');
                setErrorMsgName(helperValidateEmptyField(form.NAZIV))
                setErrorInputName(helperValidateEmptyField(form.NAZIV) ? true : false)
                break;
            }
      

        }
        if (name === 'SIFRA') {
            setCodeFocus(false)
        }

        if (name === 'NAZIV') {
            if (errorInputCode) {
                setCodeFocus(true)
                codeRef.current.focus()
            } else {
                setNameFocus(false)
                setAccountFocus(true)
            }

        }

        if (name === 'KONTO') {
            if (errorInputName) {
                setNameFocus(true)
                nameRef.current.focus()
            } else {
                setAccountFocus(false)
                inputRef.current.focus();
            }
        }

        if (name === 'KONT') {
            if (errorInputName) {
                setNameFocus(true)
                nameRef.current.focus()
            } else {
                setAccountFocus(false)
            }
        }

    
    }



    const columns = [
        {
            name: 'Šifra',
            selector: row => row.SIFRA,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.SIFRA}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'KONTO',
            selector: row => row.kontoKONTO,
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.kontoKONTO}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.kontNaziv,
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.kontNaziv}</span>
                </>
            ),
        },
     
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{''}
                    <span onClick={() => onDelete(row.SIFRA)} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>

            ),

        }
    ];


    const handlePageChange = page => {
        getTableData(page, perPage)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }

    const searchChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        var searchData = trosak.filter((item) => {
            return Object.keys(item).some(key => {
                return item[key].toString().toLowerCase().includes(e.target.value.toLowerCase())
            })
        });
        setTrosak(searchData);
    };

    const handleClear = () => {
        setSearchInput('');
        getTableData(1, perPage)
    }

    return (

        <div>
            <div className="title-container">

                <header className="title-header">
                    <h1 id="media-query-title">Trosak</h1>
                </header>


                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li class="nav-item toolbaritem">
                            <input type="image" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="4" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li class="nav-item toolbaritem">
                            <input type="image" className='dugme-stampa' title='Štampa' tabIndex="5" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                    </ul>
                </div>

            </div>
            <Container fluid>
                <Row>
                    <div className='products-form'>
                        <Row>
                        <div className="col-6" style={{ display: 'flex', marginBottom: '0px', width: '60%' }}>

                                <div className="col-1">
                                <div className='col-form-right'>
                                    <div className={errorInputCode ? 'my-input' : 'field-container-number'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                                        <label id="media-query-label">Šifra</label>
                                        </div>
                                        <input type="number"
                                            id="media-query-input-text"
                                            tabIndex="1"       
                                            placeholder="Šifra"
                                            name='SIFRA'
                                            value={form?.SIFRA}
                                            ref={codeRef}
                                            onFocus={() => setCodeFocus(true)}
                                            onBlur={(e) => formCheck(e)}
                                            onChange={(e) => handleChange(e)} />
                                        {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}

                                    </div>
                                </div>

                                <div className="col-7">
                                    <div className={errorInputName ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Naziv</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="200"
                                            ref={nameRef}
                                            tabIndex="2"
                                            placeholder="Naziv"
                                            name='NAZIV'
                                            value={form ? form.NAZIV : ''}
                                            onFocus={() => setNameFocus(true)}
                                            onBlur={(e) => formCheck(e)}
                                            onChange={(e) => handleChange(e)} />
                                        {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}

                                    </div>
                                </div>


                                <div className="col-4">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Konto</label>
                                        <Autocomplete
                                            {...defaultPropsAccount}
                                            value={account}
                                            name='KONT'
                                     //       openOnFocus
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KONTID: newValue && newValue != null ? newValue.ID : null }))
                                                setAccount(newValue);
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    tabIndex="3"
                                                    name='KONTO'
                                                    onFocus={() => setAccountFocus(true)}
                                                    // ref={accountRef}
                                                    inputRef={input => {
                                                        inputRef = input;
                                                    }}
                                                    onBlur={(e) => formCheck(e)}
                                                    placeholder=' Izaberite konto'
                                                    variant="standard" />
                                            )}
                                        />

                                    </div>
                                </div>

                               

                         

                          
                            </div>
                        </Row>

                    </div>
                </Row>
            </Container>

            <div style={{ display: 'flex' }} className='search'>
                <input type='text' tabIndex={9} id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => searchChange(e)} value={searchInput} />
                <button onClick={() => handleClear()} className='dugme-clear'>Očisti</button>

                {/* // <img src={Pencil} className='search-icon' /> */}
            </div>
            <DataTable
                columns={columns}
                data={trosak ? trosak : []}
                pagination
                paginationServer
                paginationTotalRows={total}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}

            />

            <div>
                <Modal isOpen={modalErrorOpen} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalErrorOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={modalErrorOpenData} centered  >
                     <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

        </div>
    );

}

export default Trosak;


