// importovanje komponenti
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import exportIcon from '../assets/export.svg';
import saveIcon from '../assets/save.svg';
import printIcon from '../assets/print.svg';
import SVG from 'react-inlinesvg';
import { API } from '../constants';
import axios from '../api/axios';
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import { NumericFormat } from 'react-number-format';
// komponenta koju kreiramo

function VrstaKnjizenja() {
    // definisanje konstanti koje koristimo za fokusiranje polja

    const codeRef = useRef();
    const descriptionRef = useRef();
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat

    const [vrstaKnjizenja, setvrstaKnjizenja] = useState(null)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState([]);
    const [firstVrsta, setfirstVrsta] = useState(null);
    // error messages inputs
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputDescription, setErrorInputDescription] = useState(false);
    // error messages inputs

    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgDescription, setErrorMsgDescription] = useState(false);

    const [kursFocus, setKursFocus] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    // niz prema kome se formira pagination u mrezi(pri kreiranju 'DataTable')

    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        VRSTAD: '',
        OPISD: '',
        stavkaPronadjena: false,
    })

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {
        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;
        // u suprotnom ide na upis sloga
        // poziva api za upis
        // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)

        fetch(API + '/blagdok/new', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    VRSTAD: 0,
                    OPISD: '',
                    stavkaPronadjena: false
                })
                codeRef.current.focus()
                getTableData(1, perPage)
            })
    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setCountry) i puni ukupan broj stranica(setTotal)

    const getTableData = (page, limit) => {
        console.log('upada u citanje tabele')
        fetch(API + `/blagdok/all/${page}/${limit}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setvrstaKnjizenja(data.result)
                setTotal(data.total)
            })
    }

    // prilikom pocetne inicijalizacije postavlja prvi slobodan broj
    useEffect(() => {
        // zaustavljanje fokusa(pauza za fokus dok ne odradi upis) 
        const timeout = setTimeout(() => {
            // codeRef.current.focus()
            getVrstaKnjizenjaFirstFreeNumberVrsta()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    // funkcija za pronalazenje prvog slobodnog broja
    // poziva api
    // u responsu stejt setForm poziva funkciju za pronalazenje prethodne sifre(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna sifra
    // setFirstCode - stejt koji se koristi za razlikovanje izmjene i upisa 
    const getVrstaKnjizenjaFirstFreeNumberVrsta = () => {
        fetch(API + `/blagdok/firstFreeNumberVrsta`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, VRSTAD: Number(data[0].VRSTAD) }))
                setfirstVrsta(Number(data[0].VRSTAD))
            })
    }

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  

    const onDelete = (VRSTAD) => {
        fetch(API + '/blagdok/delete/' + VRSTAD, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm(prev => ({
                    ...prev, OPISD: '', stavkaPronadjena: false
                }))
                codeRef.current.focus()
                getTableData(1, perPage)
            })
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje Opis 
    // popunjava polja podacima iz mreze
    const onEdit = (item) => {
        item.stavkaPronadjena = true
        setForm(item)
        // zaustavljanje fokusa(pauza za fokus dok ne odradi upis) 
        const timeout = setTimeout(() => {
            descriptionRef.current.focus()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };

    }

    // funkcija koja se poziva klikom na dugme 'Stampa'
    const onPrint = () => {
        // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
        // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
        // poziva api za stampu, otvara izvjestaj(API + data.link)
        if (form) {
            form.NAZIV_PRED = form.companyName
        }
        fetch(API + '/blagdok/print', {
            method: 'POST',
            body: JSON.stringify(form),
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                console.log(data, 'DATA');
                if (typeof window != 'undefined') {
                    setTimeout(() => {
                        console.log(API + data.link, 'link');
                        window.open(API + data.link)
                    }, 200);

                }
            })
    }
    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        // pokretanje forma i zamjena novih vrijednosti
        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran dokument, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkCodeRequired = (value) => {
        //    console.log(value, 'value')
        if (!value || value == 0) {
            setErrorMsgCode('Šifra je obavezna!')
            setErrorInputCode(true)
            codeRef.current.focus();
            return false
        } else {
            setErrorMsgCode(null)
            setErrorInputCode(false)
            return true
        }
    }

    // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran dokument, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkDescriptionRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgDescription('Opis je obavezan!')
            setErrorInputDescription(true)
            descriptionRef.current.focus();
            return false
        } else {
            setErrorMsgDescription(null)
            setErrorInputDescription(false)
            return true
        }
    }

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'VRSTAD') {
            return true
        }

        if (!checkCodeRequired(form.VRSTAD)) {
            return false
        }

        if (name === 'OPISD') {
            return true
        }

        if (!checkDescriptionRequired(form.OPISD)) {
            return false
        }
        return true
    }

    // definisu se polja u tabeli i popunjavaju

    const columns = [
        {
            name: 'Vrsta',
            selector: row => row.VRSTAD,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.VRSTAD}</span>
                </>
            ),
        },
        {
            name: 'Opis',
            selector: row => row.OPISD,
            //  width: '500px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OPISD}</span>
                </>
            ),
        },
        {
            name: 'Action',
            cell: (row) => (
                // klikom na dugme Edit, aktivira se funkcija onEdit 
                // klikom na dugme Brisanje, aktivira se funkcija onDelete
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{''}
                    <span onClick={() => onDelete(row.VRSTAD)} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>

            ),

        }
    ];

    // funkcija se poziva prilikom promjene stranice u tabeli stranica 1,2,3 ...
    const handlePageChange = page => {
        getTableData(page, perPage)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        if (perPage != newPerPage) {
            setPerPage(newPerPage)
            getTableData(1, newPerPage)
            codeRef.current.focus()
        }
    }

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 

    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value
        setSearchInput(searchInput);
        const fetchData = async () => {
            const res = await axios.get(`${API}/blagdok/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setvrstaKnjizenja(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length >= 1) {
            fetchData();
        }
    };

    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container">
                {/* Naziv registra */}

                <header className="title-header">
                    <h1 id="media-query-title">Vrsta Knjizenja</h1>
                </header>
                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}

                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="3" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" className='dugme-stampa' title='Štampa' tabIndex="4" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                    </ul>
                </div>

            </div>
            {/* Kontejner pune sirine */}
            <Container fluid>
                {/* postavljanje novog reda */}
                <Row>
                    <div className='products-form'>
                        <Row>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div style={{ display: 'flex', marginBottom: '0px', width: '60%' }}>
                                {/* Sirina polja */}
                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className={errorInputCode ? 'my-input' : 'field-container'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                                            <label id="media-query-label">Vrsta:</label>
                                            <NumericFormat
                                                type="number"
                                                getInputRef={codeRef}
                                                id="media-query-input-text"
                                                tabIndex="1"
                                                maxLength="10"
                                                decimalScale={0}
                                                autoFocus={true}
                                                placeholder=""
                                                name='VRSTAD'
                                                value={form?.VRSTAD}
                                                // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                                onFocus={() => {
                                                    getVrstaKnjizenjaFirstFreeNumberVrsta()
                                                    unosIspravanDo("SIFRA")
                                                }}
                                                // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                                //  onBlur={(e) => formCheck(e)}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)}
                                                onClick={() => {
                                                    // ako je odradjen dvoklik na stavku u mrezi, cisti polja  
                                                    setForm(prev => ({
                                                        ...prev, OPISD: '', stavkaPronadjena: false
                                                    }))
                                                }} />
                                            {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                            {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                                {/* Sirina polja */}
                                <div className="col-8">
                                    <div className={errorInputDescription ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                        {/* Provjera da li je polje popunjeno jer je obavezno */}
                                        <label id="media-query-label">Opis:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="40"
                                            ref={descriptionRef}
                                            tabIndex="2"
                                            placeholder="opis"
                                            name='OPISD'
                                            value={form ? form.OPISD : ''}
                                            // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                            onFocus={() => {
                                                unosIspravanDo("OPISD")
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgDescription ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDescription}</span> : ''}
                                    </div>
                                </div>
                            </div>

                        </Row>

                    </div>
                </Row>
            </Container>
            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' id="media-query-input-text" placeholder='Pretraga...'
                    onChange={(e) => {
                        // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                        if (searchInput.length >= 0) {
                            searchChange(e)
                        } else {
                            getTableData(1, perPage)
                        }
                    }}
                    value={searchInput} />

                {/* dugme 'Ocisti'( poziva funkciju handleClear za brisanje) */}
                {/* <button onClick={() => handleClear()} className='dugme-clear'>Očisti</button> */}
                {/* // <img src={Pencil} className='search-icon' /> */}
            </div>
            {/* Mreza */}
            <DataTable
                columns={columns}
                data={vrstaKnjizenja ? vrstaKnjizenja : []}
                pagination
                paginationServer
                paginationTotalRows={total}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
            />

            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}
            <div>
                <Modal isOpen={Boolean(modalErrorOpen)} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );

}

export default VrstaKnjizenja;


