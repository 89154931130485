import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import exportIcon from '../assets/export.svg';
import saveIcon from '../assets/save.svg';
import printIcon from '../assets/print.svg';
import SVG from 'react-inlinesvg';
import { API } from '../constants';

function OrganizationalUnit() {

    const [organizationalUnit, setOrganizationalUnit] = useState(null)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null

    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };

    const [form, setForm] = useState({
        SIFRAP: companyCode,
        SIFRA: '',
        NAZIV: '',
        ENTITET: ''
    })

    const onSubmit = () => {
        if (form && form.ID) {
            fetch(API + `/organizationalUnit/update/${companyCode}`, {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => {
                    response.json()
                    setForm({
                        SIFRAP: '',
                        SIFRA: '',
                        NAZIV: '',
                        ENTITET: ''
                    })
                    getTableData(1, perPage)
                })
        } else {
            fetch(API + `/organizationalUnit/new/${companyCode}`, {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => {
                    response.json()
                    setForm({
                        SIFRAP: '',
                        SIFRA: '',
                        NAZIV: '',
                        ENTITET: ''
                    })
                    getTableData(1, perPage)
                })
        }
    }

    const getTableData = (page, limit) => {
        fetch(API + `/organizationalUnit/all/${page}/${limit}/${companyCode}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setOrganizationalUnit(data.result)
                setTotal(data.total)
            })
    }


    useEffect(() => {
        getTableData(1, perPage)
    }, [perPage])


    useEffect(() => {
    }, [form])

    useEffect(() => {
    }, [organizationalUnit])


    const onDelete = (ID) => {
        fetch(API + '/organizationalUnit/delete/' + ID, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                getTableData(1, perPage)
            })
    }

    const onEdit = (item) => {
        setForm(item)

    }

    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;


        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    const columns = [
        {
            name: 'Šifra',
            selector: row => row.SIFRA,
            width: '200px'
        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
        },
        {
            name: 'Entitet',
            selector: row => row.ENTITET,
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} className='btn btn-primary'>Edit</span>{'     '}
                    <span onClick={() => onDelete(row.ID)} className='btn btn-danger'>Brisanje</span>
                </>

            ),

        }
    ];


    const handlePageChange = page => {
        getTableData(page, perPage)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }


    return (

        <div>
            <div className="title-container">

                <header className="title-header">
                    <h1>Organizaciona jedinica</h1>
                </header>


                <div className="title-options-group">

                    <button onFocus={() => onSubmit()}>
                        <div className="option" tabIndex="4">
                            <SVG src={saveIcon} />
                        </div>
                        <span>Snimi</span>
                    </button>
                    <button /*onClick={() => this.export()}*/>
                        <div className="option" tabIndex="5">
                            <SVG src={printIcon} />
                        </div>
                        <span>Štampaj</span>
                    </button>
                    <button /*onClick={() => this.export()}*/>
                        <div className="option" tabIndex="6">
                            <SVG src={exportIcon} />
                        </div>
                        <span>Export</span>
                    </button>


                </div>

            </div>
            <Container fluid>
                <Row>
                    <div className='products-form'>
                        <Row>

                            <div className="col-1">
                            <div className='field-container'>
                                    <label>Šifra:</label>
                                    <div className="error-input">
                                        <input type="number" tabIndex="1" className="my-input" autoFocus={true} placeholder="šifra" name='SIFRA' value={form ? form.SIFRA : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-3">
                            <div className='field-container' style={{ marginRight: '3px' }} >
                                    <label>Naziv:</label>
                                    <input type="text" tabIndex="2" placeholder="naziv" className="my-input" name='NAZIV' value={form ? form.NAZIV : ''} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>

                            <div className="col-1">
                            <div className='field-container' style={{ marginRight: '3px' }} >
                                    <label>Entitet:</label>
                                    <input type="text" tabIndex="3" placeholder="entitet" name='ENTITET' value={form ? form.ENTITET : ''} onChange={(e) => handleChange(e)} />
                                </div>

                            </div>

                        </Row>

                    </div>
                </Row>
            </Container>

            {organizationalUnit ?
                <DataTable
                    columns={columns}
                    data={organizationalUnit}
                    pagination
                    paginationServer
                    paginationTotalRows={total}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    responsive
                    striped
                    dense
                    pointerOnHover
                    paginationComponentOptions={defaultComponentOptions}
                />
                : null
            }
        </div>
    );

}

export default OrganizationalUnit;


