// importovanje komponenti
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import exportIcon from '../assets/export.svg';
import saveIcon from '../assets/save.svg';
import printIcon from '../assets/print.svg';
import SVG from 'react-inlinesvg';
import { API } from '../constants';
import axios from '../api/axios';
// komponenta koju kreiramo

function Valuta() {
    // definisanje konstanti koje koristimo za fokusiranje polja

    const codeRef = useRef();
    const nameRef = useRef();
    const errRef = useRef();
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat

    const [valuta, setValuta] = useState(null)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState([]);
    const [editValuta, setEditValuta] = useState(false);
    // error messages inputs
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    // error messages inputs

    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);

    const [valutaFocus, setValutaFocus] = useState(false);
    const [kursFocus, setKursFocus] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    // niz prema kome se formira pagination u mrezi(pri kreiranju 'DataTable')

    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null
    const companyNaziv = typeof window !== 'undefined' ? localStorage.getItem('companyNaziv') : null
    // niz prema kome se formira pagination u mrezi(pri kreiranju 'DataTable')
    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };
    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        VALUTA: '',
        KURS: 0.00000
    })

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {
        // provjera obaveznih polja na dugmetu
        // ukoliko nisu popunjena obavezna polja, zaustavlja akciju i prikazuje upozorenje(poruka)
        // ukoliko su popunjena obavezna polja, upada u else i izvrsava se funkcija
        if (form.VALUTA == " " || form.VALUTA == 0 || form.KURS == " " || form.KURS == 0) {
            setModalErrorOpen(true)
            nameRef.current.focus();
        } else {
            // ako je u pitanju form(objekat) i ako SIFRA iz tog objekta nije nova(ako je pod tim brojem upisan slog ), odradice se izmjena
            // poziva api za izmjenu
            // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)

            if (form && (form.VALUTA && editValuta)) {
                fetch(API + '/valuta/update', {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            VALUTA: '',
                            KURS: 0.000000
                        })
                        codeRef.current.focus()
                        getTableData(1, perPage)
                    })
                // u suprotnom ide na upis sloga
                // poziva api za upis
                // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)

            } else {
                fetch(API + '/valuta/new', {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            VALUTA: '',
                            KURS: 0.00000
                        })
                        codeRef.current.focus()
                        getTableData(1, perPage)
                    })
            }
        }
    }
    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setCountry) i puni ukupan broj stranica(setTotal)

    const getTableData = (page, limit) => {
        fetch(API + `/valuta/all/${page}/${limit}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setValuta(data.result)
                setTotal(data.total)
            })
    }

    // poziva se inicijalno pri otvaranju forma i poziva api za popunjavanje tabele
    // postavlja fokus na polje sifra
    // prati promjenu state perPage 

    useEffect(() => {
        getTableData(1, perPage)
        codeRef.current.focus();
    }, [perPage])


    useEffect(() => {

    }, [form])

    useEffect(() => {

    }, [valuta])





    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  

    const onDelete = (VALUTA) => {
        fetch(API + '/valuta/delete/' + VALUTA, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()

                codeRef.current.focus()
                getTableData(1, perPage)
            })
    }

    const onEdit = (item) => {
        nameRef.current.focus()
        setForm(item)
        setEditValuta(true)

    }

    // funkcija koja se poziva klikom na dugme 'Stampa'
    const onPrint = () => {
        // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
        // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
        // poziva api za stampu, otvara izvjestaj(API + data.link)
        if (form) {
            form.NAZIV_PRED = companyNaziv
        }
        fetch(API + `/valuta/print/${companyNaziv}`, {
            method: 'POST',
            body: JSON.stringify(form),
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                console.log(data, 'DATA');
                if (typeof window != 'undefined') {
                    setTimeout(() => {
                        console.log(API + data.link, 'link');
                        window.open(API + data.link)
                    }, 200);

                }
            })
    }
    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        // switch (name) {
        //     case 'SIFRA': {
        //         console.log('case - sifra');
        //         setErrorMsgCode(helperValidateOnlyNumbers(value))
        //         setErrorInputCode(helperValidateOnlyNumbers(value) ? true : false);
        //         break;
        //     }
        //     case 'NAZIV': {
        //         setErrorMsgName(helperValidateNumberAndLetters(value))
        //         setErrorInputName(helperValidateNumberAndLetters(value) ? true : false)
        //         break;
        //     }
        // }
        // pokretanje forma i zamjena novih vrijednosti
        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // validacija broja i obaveznog polja
    const helperValidateOnlyNumbers = (input) => {
        if (input.replace(/^[0-9.]+$/, '').length > 0) {
            return 'Dozvoljeni su samo brojevi!';
        } else if (input.length < 1) {
            return 'Polje je obavezno!'
        } else {
            return false
        }

    }

    // validacija praznog polja
    const helperValidateEmptyField = (input) => {
        if (input.length < 1) return 'Polje je obavezno!';
    }

    // stepenasta
    const formCheck = (event) => {

        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        switch (name) {
            case 'VALUTA': {
                setErrorMsgCode(helperValidateEmptyField(value))
                setErrorInputCode(helperValidateEmptyField(value) ? true : false)
                break;

            }
            case 'KURS': {
                setErrorMsgName(helperValidateOnlyNumbers(value))
                setErrorInputName(helperValidateOnlyNumbers(value) ? true : false)
                break;
            }

        }
        if (name === 'VALUTA') {
            setValutaFocus(false)
        }

        if (name === 'KURS') {
            setKursFocus(false)
        }

    }
    // prati promjenu stanja fokusa
    // ako je errorInputCode true( polje sifra nije popunjeno), vraca fokus na polje sifra i ne moze se proci dalje dok se ne popuni
    useEffect(() => {
        if (codeRef.current && errorInputCode) {
            codeRef.current.focus();
        }
    }, [!valutaFocus]);
    // prati promjenu stanja fokusa
    // ako je errorInputName true( polje naziv nije popunjeno), vraca fokus na polje naziv i ne moze se proci dalje dok se ne popuni
    useEffect(() => {
        if (nameRef.current && errorInputName) {
            nameRef.current.focus();
        }
    }, [!kursFocus]);
    // definisu se polja u tabeli i popunjavaju

    const columns = [
        {
            name: 'Valuta',
            selector: row => row.VALUTA,
            width: '100px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.VALUTA}</span>
                </>
            ),
        },
        {
            name: 'Kurs',
            selector: row => row.KURS,
            width: '170px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.KURS}</span>
                </>
            ),
        },
        {
            name: 'Action',
            width: '1250px',
            right: 'boolean',
            cell: (row) => (
                // klikom na dugme Edit, aktivira se funkcija onEdit 
                // klikom na dugme Brisanje, aktivira se funkcija onDelete
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{''}
                    <span onClick={() => onDelete(row.VALUTA)} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>

            ),

        }
    ];

    // prati promjenu stanja fokusa
    // ako je errorInputName true( polje naziv nije popunjeno), vraca fokus na polje naziv i ne moze se proci dalje dok se ne popuni

    const handlePageChange = page => {
        getTableData(page, perPage)
    }
    // funkcija se poziva prilikom promjene stranice u tabeli stranica 1,2,3 ...

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }


    // const searchChange = (e) => {
    //     e.preventDefault();
    //     setSearchInput(e.target.value);
    //     var searchData = valuta.filter((item) => {
    //         return Object.keys(item).some(key => {
    //             return item[key].toString().toLowerCase().includes(e.target.value.toLowerCase())
    //         })
    //     });
    //     setvaluta(searchData);
    // };
    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 

    const searchChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        const fetchData = async () => {
            const res = await axios.get(`${API}/valuta/query/${companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setValuta(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length > 2) fetchData();

    };

    // funkcija za brisanje pretrage klikom na dugme 'Ocisti'
    // osvjezava polje za pretragu
    // osvjezava mrezu
    const handleClear = () => {
        setSearchInput('');
        getTableData(1, perPage)
    }

    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container">
                {/* Naziv registra */}

                <header className="title-header">
                    <h1 id="media-query-title">Valuta</h1>
                </header>
                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}

                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li class="nav-item toolbaritem">
                            <input type="image" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="3" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li class="nav-item toolbaritem">
                            <input type="image" className='dugme-stampa' title='Štampa' tabIndex="4" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                    </ul>
                </div>

            </div>
            {/* Kontejner pune sirine */}
            <Container fluid>
                {/* postavljanje novog reda */}
                <Row>
                    <div className='products-form'>
                        <Row>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-6" style={{ display: 'flex', marginBottom: '0px', width: '60%' }}>
                                {/* Sirina polja */}
                                <div className="col-1">
                                    <div className={errorInputCode ? 'my-input' : 'field-container'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                                        <label id="media-query-label">Valuta:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            tabIndex="1"
                                            autoFocus={true}
                                            placeholder="Valuta"
                                            name='VALUTA'
                                            value={form?.VALUTA}
                                            ref={codeRef}
                                            // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                            onFocus={() => setValutaFocus(true)}
                                            // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                            onBlur={(e) => formCheck(e)}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} ></input>
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}
                                    </div>
                                </div>
                                {/* Sirina polja */}
                                <div className="col-2">
                                    <div className='col-form-right'>
                                        <div className={errorInputName ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                            {/* Provjera da li je polje popunjeno jer je obavezno */}
                                            <label id="media-query-label">Kurs:</label>
                                        </div>
                                        <input type="number"
                                            id="media-query-input-text"
                                            maxLength="40"
                                            ref={nameRef}
                                            tabIndex="2"
                                            placeholder="Kurs"
                                            name='KURS'
                                            value={form ? form.KURS : ''}
                                            // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                            onFocus={() => setKursFocus(true)}
                                            // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                            onBlur={(e) => formCheck(e)}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}
                                    </div>
                                </div>
                            </div>

                        </Row>

                    </div>
                </Row>
            </Container>
            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => searchChange(e)} value={searchInput} />
                {/* dugme 'Ocisti'( poziva funkciju handleClear za brisanje) */}
                <button onClick={() => handleClear()} className='dugme-clear'>Očisti</button>

                {/* // <img src={Pencil} className='search-icon' /> */}
            </div>
            {/* Mreza */}
            <DataTable
                columns={columns}
                data={valuta ? valuta : []}
                pagination
                paginationServer
                paginationTotalRows={total}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
            />

            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}
            <div>
                <Modal isOpen={modalErrorOpen} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );

}

export default Valuta;


