import { fontSize } from '@mui/system';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = styled(Link)`
  display: flex;
 // color: #e1e9fc;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 35px;
  text-decoration: none;
  font-size: 20px;
  color: black;
  margin: 10px;
  font-weight: bold;

  &:hover {
  //  background: #252831;
    background: #1E90FF;
    border-left: 4px solid #632ce4;
    cursor: pointer;
    color: black;
    height: 35px;
  }

  &:focus {
    background-color: #1E90FF !important;
    box-shadow: 0 0 0 .2rem #1E90FF
    height: 35px;
  }

}
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
 // background: #414757;
 background: white;
  height: 35px;
  padding-left: 0.7rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  //color: #f5f5f5;
  color: black;
 // padding-bottom: 10px;
  font-size: 18px;
 // margin-top: 10px;

  &:hover {
   // background: #632ce4;
   background:#1E90FF;
   border-left: 4px solid #632ce4;
    cursor: pointer;
    color: black; 
    height: 35px;
  }

  &:focus {
    background-color: #1E90FF !important;
    box-shadow: 0 0 0 .2rem #1E90FF
    height: 35px;
  }
`;

const SubMenuItems = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
  const [showNav, setShowNav] = useState(true)

  const showSubnav = () => setSubnav(!subnav);

  const onSubMenuItemsClicked = () => {
    if (window.innerHeight <= 705 && window.innerWidth <= 1024) {
      setSubnav(!subnav)
    }
  }

  const onSubMenuClicked = () => {
    if (window.innerHeight <= 705 && window.innerWidth <= 1024) {
      if (showNav) {
        return 'initial';
      } else return 'none'
    }
  }
  return (
    <>
      <SidebarLink id='font-size-menu' to={item.subSubNav} onClick={item.subSubNav && showSubnav}>
        <div style={{ display: 'contents', fontSize: 'inherit' }}>
          {item.icon}
          <SidebarLabel onClick={onSubMenuClicked}>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subSubNav
              ? item.iconClosed
              : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subSubNav.map((item, index) => {
          return (
            <DropdownLink id='font-size-SubMenu' to={item.path} key={index}>
              {item.icon}
              <SidebarLabel onClick={onSubMenuItemsClicked}>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenuItems;
