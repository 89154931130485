import React from 'react';
import SVG from 'react-inlinesvg';
import homeIcon from '../assets/home.svg';
import phoneIcon from '../assets/phone.svg';
import mailIcon from '../assets/mail.svg';
import earthIcon from '../assets/earth.svg';

function Home() {
  return (
    <div className="pocetna-stranica">

      {/* <div className="container"> */}
      <header className="pageHeader">
        <h1 id='media-query-aplication'><i className="page-caption"></i>POSLOVNE APLIKACIJE - INFOSISTEM</h1>
      </header>
      {/* </div> */}

      <div className='company-info' id='company-info-media-query'>

        <ul className="nav flex-column">
          <div className='nav flex-lg-row'>
            <SVG src={homeIcon} className='info-icons'></SVG>
            <h5 id='company-doo-media-query' className="info">INFOSISTEM doo </h5>
          </div>

          <div className='nav flex-lg-row'>
            <SVG src={phoneIcon} className='info-icons'></SVG>
            <li className="info">+387 (0)55/240-055
            </li>
          </div>

          <div className='nav flex-lg-row'>
            <SVG src={phoneIcon} className='info-icons'></SVG>
            <li className="info">+387 (0)55/425-414
            </li>
          </div>

          <div className='nav flex-lg-row'>
            <SVG src={mailIcon} className='info-icons'></SVG>
            <li className="info">infosistem.doo.bn@gmail.com
            </li>
          </div>

          <div className='nav flex-lg-row'>
            <SVG src={earthIcon} className='info-icons'></SVG>
            <li className="info">www.infosistembn.com
            </li>
          </div>
        </ul>
      </div>


      <footer className="pageFooter">
        <p>
          <a href="https://www.infosistembn.com" target="_blank" id='media-query-site'>Knjigovodstveni i poslovni programi-Infosistem</a>
        </p>
      </footer>
    </div>
  );
}

export default Home;
