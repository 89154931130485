
export function numberWithCommasColumns(x, noZeros = false) {
    if (x) {
        let val = Number(x);
        val = Math.round(val * 100) / 100
        if (val) {
            let returnValue = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (!noZeros) {
                let returnValueSplit = returnValue.split('.')
                if (returnValueSplit && returnValueSplit[1]) {
                    if (returnValueSplit[1].length == 1) {
                        returnValue += '0'
                    }
                } else {
                    returnValue += '.00'
                }
            }

            return returnValue;
        } else {
            if (!noZeros) {
                return '0.00'
            } else {
                return '0'
            }

        }

    } else {
        if (!noZeros) {
            return '0.00'
        } else {
            return '0'
        }
    }

}

export function numberWithCommasColumns3(x, noZeros = false) {
    if (x) {
        let val = Number(x);
        val = Math.round(val * 1000) / 1000
        if (val) {
            let returnValue = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (!noZeros) {
                let returnValueSplit = returnValue.split('.')
                if (returnValueSplit && returnValueSplit[1]) {
                    if (returnValueSplit[1].length == 1) {
                        returnValue += '0'
                    }
                } else {
                    returnValue += '.000'
                }
            }

            return returnValue;
        } else {
            if (!noZeros) {
                return '0.00'
            } else {
                return '0'
            }

        }

    } else {
        if (!noZeros) {
            return '0.00'
        } else {
            return '0'
        }
    }

}


const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null;
const companyName = typeof window !== 'undefined' ? localStorage.getItem('companyNaziv') : null;
const companyPdv = typeof window !== 'undefined' ? localStorage.getItem('companyPdv') : null;
const companyJib = typeof window !== 'undefined' ? localStorage.getItem('companyJib') : null;
const companyAddress = typeof window !== 'undefined' ? localStorage.getItem('companyAddress') : null;
const companyPlaces = typeof window !== 'undefined' ? localStorage.getItem('companyPlaces') : null;
const companyMb = typeof window !== 'undefined' ? localStorage.getItem('companyMb') : null;
const companyTelephone = typeof window !== 'undefined' ? localStorage.getItem('companyTelephone') : null;
const companyYear = typeof window !== 'undefined' ? localStorage.getItem('companyYear') : null;
const BankAccount = typeof window !== 'undefined' ? localStorage.getItem('BankAccount') : null;
const BankAccount1 = typeof window !== 'undefined' ? localStorage.getItem('BankAccount1') : null;
const userCode = typeof window !== 'undefined' ? localStorage.getItem('userCode') : null;

export const ovlfik = typeof window !== 'undefined' ? localStorage.getItem('ovlfik') : null;
export const ovlrobno = typeof window !== 'undefined' ? localStorage.getItem('ovlrobno') : null;
export const ovlfakt = typeof window !== 'undefined' ? localStorage.getItem('ovlfakt') : null;
export const ovlblagajna = typeof window !== 'undefined' ? localStorage.getItem('ovlblagajna') : null;
export const ovlascenje = typeof window !== 'undefined' ? localStorage.getItem('ovlascenje') : null;



export function getCompanyData(form) {
    form.companyCode = companyCode
    form.companyName = companyName 
    form.companyPdv = companyPdv
    form.companyJib = companyJib
    form.companyAddress = companyAddress
    form.companyPlaces = companyPlaces
    form.companyMb = companyMb
    form.companyTelephone = companyTelephone
    form.companyYear = companyYear
    form.BankAccount = BankAccount
    form.BankAccount1 = BankAccount1
    form.userCode = userCode
    form.ovlfik = ovlfik
    form.ovlfakt = ovlfakt
    return form;
}

export const defaultComponentOptions = {
    rowsPerPageText: 'Redovi po stranici:',
    rangeSeparatorText: 'od',
   // noRowsPerPage: false,
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Sve',
};

const roundTo = (number, places) => {
    const factor = 10 ** places;
    return Math.round(number * factor) / factor;
}

export function roundTO(number, places) {
    const factor = 10 ** places;
    return Math.round(number * factor) / factor;
}

// validacija broja i obaveznog polja
export function helperValidateOnlyNumbers(input) {
    if (input.replace(/^[0-9.]+$/, '').length >= 0) {
        return 'Dozvoljeni su samo brojevi!';
    } else if (input.length < 1) {
        return 'Polje je obavezno!'
    } else {
        return false
    }
}

    // funkcija za validaciju praznog polja tipa varchar
    const helperValidateEmptyField = (input) => {

        // ako je duzina unosa manja od 1(ako nije nista unijeto u polje) 
        if (input.length < 1) return 'Polje je obavezno!';
    }

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getFakturniIznos(KOL, FAKTCEN) {
    //   console.log(KOL, FAKTCEN)
    let _KOL = KOL;
    let _FAKTCEN = FAKTCEN;
    let IZNFAKT = roundTo(((_KOL * _FAKTCEN)), 2);
    //    console.log(IZNFAKT)
    return IZNFAKT;
}

export function getNabavniIznos(KOL, NABCEN) {
    //  console.log(KOL, NABCEN)
    let _KOL = KOL;
    let _NABCEN = NABCEN;
    let IZND = roundTo(((_KOL * _NABCEN)), 2);
    //   console.log(IZND)
    return IZND;
}

export function getFakturnaCijena(KOL, IZNFAKT) {
    let _KOL = KOL;
    let _IZNFAKT = IZNFAKT;
    let FAKTCEN = roundTo((_IZNFAKT / _KOL), 2);
    return FAKTCEN;
}

export function getIznosRabataIzlaz(RABVEL, IZNMALD) {
    let _RABVEL = RABVEL;
    let _IZNMALD = IZNMALD;
    let IZNOSRAB = roundTo(((_IZNMALD * _RABVEL) / 100), 2);
    return IZNOSRAB;
}

export function getProcenatRabataIzlaz(IZNOSRAB, IZNMALD) {
    let _IZNOSRAB = IZNOSRAB;
    let _IZNMALD = IZNMALD;
    let RABVEL = roundTo(((_IZNOSRAB * 100) / _IZNMALD), 2);
    return RABVEL;
}

export function getPorezUIznosu(PDV, KOL, MALCEN) {

    let _PDV = PDV;
    let _KOL = KOL;
    let _CENA = MALCEN;
    let IZPDV = roundTo((((_KOL * _CENA * _PDV)) / (_PDV + 100)), 2);
    //  let osnovica = (_IZNMALD - IZPDV).toFixed(2);
    return IZPDV;
}

export function getPorezIzIznosa(PDV, KOL, MALCENP) {
    let _PDV = PDV;
    let _KOL = KOL;
    let _MALCENP = MALCENP;
    let IZPDV = roundTo((((_KOL * _MALCENP) * _PDV) / 100), 4);
    //  let osnovica = (_IZNMALD - _IZNOSRAB).toFixed(2);
    return IZPDV;
}

export function getIznosMarzeM(IZNMALDP, IZND, IZPDV, IZNOSRAB) {
    //  let _KOL = KOL;
    let _IZNMALD = IZNMALDP;
    let _IZND = IZND;
    let _IZPDV = IZPDV;
    let _IZNOSRAB = IZNOSRAB;
    let IZNRAZD = roundTo((_IZNMALD - _IZNOSRAB - _IZND - _IZPDV), 2);
    //  let osnovica = (_IZNMALD - _IZNOSRAB).toFixed(2);
    return IZNRAZD;
}

export function getIznosMarzeV(IZNMALD, IZND, IZNOSRAB) {
    //   console.log(IZNMALD, IZND)
    let _IZNMALD = IZNMALD;
    let _IZND = IZND;
    let _IZNOSRAB = IZNOSRAB;
    let IZNRAZD = roundTo((_IZNMALD - _IZND - _IZNOSRAB), 2);
    //  let osnovica = (_IZNMALD - _IZNOSRAB).toFixed(2);
    return IZNRAZD;
}

export function getProcenatMarzeM(IZNRAZD, IZND) {
    // console.log(IZNRAZD,IZND)
    let _IZND = IZND;
    let _IZNRAZD = IZNRAZD;
    let RAZCEN = roundTo(((_IZNRAZD * 100) / _IZND), 3);
    //   console.log(RAZCEN)
    return RAZCEN;
}

export function getProcenatMarzeV(IZND, IZNRAZD) {
    //   console.log(IZND, IZNRAZD)
    let _IZND = IZND;
    let _IZNRAZD = IZNRAZD;
    let RAZCEN = roundTo((((_IZNRAZD * 100) / _IZND)), 3);
    //    console.log(RAZCEN)
    return RAZCEN;
}

export function getProdajnaCijena(KOL, IZNMALD, IZNOSRAB) {
    //   console.log(IZNMALD,IZNOSRAB)
    let _KOL = KOL;
    let _IZNMALD = IZNMALD;
    let _IZNOSRAB = IZNOSRAB;
    let MALCENP = roundTo((((_IZNMALD - _IZNOSRAB) / _KOL)), 2);
    //  console.log(MALCENP);
    return MALCENP;
}

export function getProdajniIznos(IZNMALD, IZNOSRAB) {
    //   console.log(IZNMALD,IZNOSRAB)
    let _IZNMALD = IZNMALD;
    let _IZNOSRAB = IZNOSRAB;
    let IZNMALDP = roundTo((_IZNMALD - _IZNOSRAB), 2);
    //   console.log(IZNMALDP);
    return IZNMALDP;
}

export function getRabatProcenat(IZNOSRAB, IZNMALD) {
    //      console.log(IZNOSRABP,IZNMALD)
    let _IZNMALD = IZNMALD;
    let _IZNOSRAB = IZNOSRAB;
    let RABVEL = roundTo(((_IZNOSRAB * 100) / _IZNMALD), 2);
    //     console.log(RABVEL);
    return RABVEL;
}