// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API } from '../constants';
import TextField from '@mui/material/TextField';
import { RadioButton } from '../components/radioButton'
import Autocomplete from '@mui/material/Autocomplete';
import SVG from 'react-inlinesvg';
import axios from '../api/axios';
import deleteNalog from '../assets/delete_document.svg';
import moment from 'moment';
import { Spinner } from "reactstrap";
import {numberWithCommasColumns} from '../components/utilities';
import {numberWithCommas} from '../components/utilities';
// komponenta koju kreiramo

function FinNalog() {
    // definisanje konstanti koje koristimo za fokusiranje polja

    const codeRef = useRef();
    const nameRef = useRef();
    const errRef = useRef();
    //    const yearRef = useRef();
    const nalogRef = useRef()
    const dateRef = useRef()
    const clientsRef = useRef()
    const kontoRef = useRef()
    const dispatchNoteRef = useRef()
    const contractRef = useRef()
    const noteRef = useRef()
    const numberRef = useRef()
    const descriptionRef = useRef()
    const accountRef = useRef()
    const unitRef = useRef()
    const dugujeRef = useRef()
    const potrazRef = useRef()
    const vrstaRef = useRef()
    const totalRef = useRef()
    const repositoryRef = useRef();
    const redRef = useRef()

    let date = new Date();
    let year = new Date().getFullYear()
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [finDok, setFinDok] = useState([])
    const [finNalog, setFinNalog] = useState([])
    const [total, setTotal] = useState(0)
    const [totalstrana, setTotalstrana] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [repository, setRepository] = useState(null)
    const [clients, setClients] = useState(null);
    const [konto, setKonto] = useState(null);
    const [dok, setDok] = useState(null);
    const [vrsta, setVrsta] = useState(null);
    const [selectData, setSelectData] = useState([]);
    const [selectDataClients, setSelectDataClients] = useState([]);
    const [selectDataKonto, setSelectDataKonto] = useState([]);
    const [selectDataVrsta, setSelectDataVrsta] = useState([]);
    const [startDate, setStartDate] = useState(date);
    const [documentDate, setDocumentDate] = useState(date);
    const [currencyDate, setCurrencyDate] = useState(date);
    const [firstNalogNumber, setFirstNalogNumber] = useState(null)
    const [itemUpdate, setItemUpdate] = useState(false)
    const [searchInput, setSearchInput] = useState([]);
    // error row inputs
    const [errorInputRepository, setErrorInputRepository] = useState(false);
    const [errorInputDescription, setErrorInputDescription] = useState(false);
    const [errorMsgRepository, setErrorMsgRepository] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [errorInputNalog, setErrorInputNalog] = useState(false)
    const [errorMsgNalog, setErrorMsgNalog] = useState(false)
    const [errorInputClients, setErrorInputClients] = useState(false)
    const [errorMsgClients, setErrorMsgClients] = useState(false)
    const [errorInputKonto, setErrorInputKonto] = useState(false)
    const [errorMsgKonto, setErrorMsgKonto] = useState(false)
    const [errorInputDuguje, setErrorInputDuguje] = useState(false)
    const [errorMsgDuguje, setErrorMsgDuguje] = useState(false)
    const [errorInputPotraz, setErrorInputPotraz] = useState(false)
    const [errorMsgDescription, setErrorMsgDescription] = useState(false)
    const [errorInputValue, setErrorInputValue] = useState(false)
    const [errorMsgOpis, setErrorMsgOpis] = useState(false)
    const [errorMsgValue, setErrorMsgValue] = useState(false)
    const [errorInputRed, setErrorInputRed] = useState(false)
    const [errorMsgRed, setErrorMsgRed] = useState(false)
    const [errorInputVrsta, setErrorInputVrsta] = useState(false)
    const [errorMsgVrsta, setErrorMsgVrsta] = useState(false)
    const [errorMsgPotraz, setErrorMsgPotraz] = useState(false)


    const [repositoryFocus, setRepositoryFocus] = useState(true);
    const [nameFocus, setNameFocus] = useState(false);
    const [vrstaFocus, setVrstaFocus] = useState(false);
    const [nalogFocus, setNalogFocus] = useState(false);
    const [clientsFocus, setClientsFocus] = useState(false);
    const [redFocus, setRedFocus] = useState(false)
    const [yearFocus, setYearFocus] = useState(false);
    const [kontoFocus, setKontoFocus] = useState(false);
    const [dispatchNoteFocus, setDispatchNoteFocus] = useState(false);
    const [descriptionFocus, setDescriptionFocus] = useState(false)
    const [dugujeFocus, setDugujeFocus] = useState(false)
    const [potrazFocus, setPotrazFocus] = useState(false)
    const [totalFocus, setTotalFocus] = useState(false)
    const [sumDuguje, setSumDuguje] = useState(false)
    const [sumPotraz, setSumPotraz] = useState(false)
    const [sumTotal, setSumTotal] = useState(false)

    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };


    const [modalOpen, setModalOpen] = useState(false);
    const [modalDeleteItem, setModalDeleteItem] = useState(false);

    const [modalErrorDeleteNalogOpen, setModalErrorDeleteNalogOpen] = useState(false);

    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [modalErrorCheckClient, setModalErrorCheckClient] = useState(false);

    const [modalErrorDeleteItemOpen, setModalErrorDeleteItemOpen] = useState(false)
    // povlacenje podataka iz lokal storidza

    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null
    const userCode = typeof window !== 'undefined' ? localStorage.getItem('userCode') : null
    const companyNaziv = typeof window !== 'undefined' ? localStorage.getItem('companyNaziv') : null

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        SIFRA: companyCode,
        NALOG: '',
        RED: 0,
        DATN: '',
        DATD: '',
        KOMTID: '',
        KONTID: '',
        OPIS: '',
        VRSTA: '',
        DUGUJE: 0,
        POTRAZ: 0,

    })

    const onSubmit = () => {
        //    console.log(form);
        // provjera obaveznih polja na dugmetu
        // ukoliko nisu popunjena obavezna polja, zaustavlja akciju i prikazuje upozorenje(poruka)
        // ukoliko su popunjena obavezna polja, upada u else i izvrsava se funkcija

        if (form.NALOG === 0 || form.NALOG === "" || form.RED === 0 || form.RED === "" || form.KONTID === 0 || form.KONTID === "") {
            setModalErrorOpen(true)
        } else if (konto.OZNAKA === 'D' && !clients) {
            //   console.log(konto.OZNAKA, 'oznaka', clients);
            setModalErrorCheckClient(true)
        } else {
            if (form.DUGUJE !== "" || form.POTRAZ !== "") {
                if (form) {
                    form.DATN = startDate
                    form.DATD = documentDate
                }

                form.SIFKOR = userCode

                if (form && itemUpdate) {
                    //   console.log(form.NALOG);
                    fetch(API + `/finNalog/update/${companyCode}`, {
                        method: 'POST',
                        body: JSON.stringify(form),
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        },
                    })
                        .then((response) => {
                            response.json()
                            setForm(prev => ({ ...prev, OPIS: '', DUGUJE: '', POTRAZ: '' }))
                            setItemUpdate(false)
                            setClients(null)
                            setKonto(null)
                            getTotal()
                        }).then(() => {
                            getFinNalogData(1, perPage)
                        })
                }
                // u suprotnom ide na upis sloga
                // poziva api za upis
                // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)

                else {
                    fetch(API + `/finNalog/new/${companyCode}`, {

                        method: 'POST',
                        body: JSON.stringify(form),
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-type': 'application/json; charset=UTF-8'
                        },
                    })
                        .then((response) => {
                            response.json()
                            setForm(prev => ({ ...prev, KOMTID: '', OPIS: '', DUGUJE: '', POTRAZ: '' }))
                            //     setVrsta(null)
                            setClients(null)
                            setKonto(null)
                            getTotal()
                        }).then(() => {
                            getFinNalogData(1, perPage)
                        })
                }
            }
        }
    }
    // poziva se inicijalno pri otvaranju forma i poziva api za popunjavanje tabele
    // postavlja fokus na polje sifra
    // prati promjenu state perPage 

    useEffect(() => {
        getVrstaData()
        getClientsData()
        getKontoData()
        //       repositoryRef.current.focus();
    }, [!vrsta && !clients && !konto])

    useEffect(() => {
        getFinNalogFirstFreeNumber()
    }, [itemUpdate && !form && !form.NALOG])

    useEffect(() => {
        // getVATPayerData()
        getFinNalogData(1, perPage)
        //  codeRef.current.focus();
    }, [perPage])

    useEffect(() => {
    }, [finNalog])

    // useEffect(() => {
    //    getFinNalogFirstFreeNumberRed()
    // }, [!form.RED])



    // prati promjene state form i vrijednosti u polju dokument i u zavisnosti od uslova mjenja vrijednosti u samom state form
    useEffect(() => {
        if (form && form.NALOG && form.NALOG != firstNalogNumber) {
            setForm(prev => ({
                ...prev, DATN: '', DATN: '', RED: 1, VRSTA: '',
                DATD: '', KOMTID: '', OPIS: '', KONTID: '', DUGUJE: '', POTRAZ: '',
            }))
            getFinNalogData(1, perPage)
            setFinNalog(null)
            setVrsta(null)
            setClients(null)
            setKonto(null)
            checkNalog()
        } else {
            setFinNalog(null)
        }

    }, [form.NALOG])

    // funkcija za pronalazenje komitenta i odabir postojeceg

    const getClientsData = () => {
        fetch(API + `/client/all/${companyCode}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataClients(data)
            })
    }
    // funkcija za pronalazenje konta i odabir postojeceg

    const getKontoData = () => {
        fetch(API + `/konto/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataKonto(data)
            })
    }
    // funkcija za pronalazenje vrste dokumenta i odabir postojeceg

    const getVrstaData = () => {
        fetch(API + `/dokument/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataVrsta(data)
            })
    }

    // funkcija za pronalazenje prvog slobodnog broja
    // poziva api
    // u responsu stejt setForm poziva funkciju za pronalazenje prethodnog naloga(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna sifra
    // setFirstCode - stejt koji se koristi za razlikovanje izmjene i upisa 


    const getFinNalogFirstFreeNumber = () => {
        fetch(API + `/finNalog/firstFreeNumber/${companyCode}`, {

            method: 'POST',
            body: JSON.stringify({ sifra: form.SIFRA }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, NALOG: data[0].NALOG }))
                setFirstNalogNumber(data[0].NALOG)
                getFinNalogFirstFreeNumberRed()
                //   setfirstCode(Number(data))
            })
    }

    // funkcija za pronalazenje prvog rednog broja u okviru naloga

    const getFinNalogFirstFreeNumberRed = () => {
        fetch(API + `/finNalog/firstFreeNumberRed/${companyCode}`, {
            method: 'POST',
            body: JSON.stringify({ sifra: form.SIFRA, nalog: form.NALOG }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, RED: Number(data) }))
            })
    }

    // validacija naloga za izmjenu
    const checkNalog = () => {
        fetch(API + `/finNalog/check/nalogData/${companyCode}`, {

            method: 'POST',
            body: JSON.stringify({ sifra: form.SIFRA, nalog: form.NALOG }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                if (data && data.length) {
                    setForm(prev => ({ ...prev, NALOG: data[0].NALOG, DATD: data[0].DATD, DATN: data[0].DATN, KOMTID: data[0].KOMTID }))
                    // selectDataClients.map((item) => {
                    //     if (item.ID == data[0].KOMTID) {
                    //         setClients(item)
                    //     }
                    // })
                    setStartDate(new Date(data[0].DATN))
                    setDocumentDate(new Date(data[0].DATD))
                    getFinNalogData(1, perPage)
                    //    setUpdateDocument(true)
                    getTotal()
                }
            })
    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setCountry) i puni ukupan broj stranica(setTotal)

    const getFinNalogData = (page, limit) => {
        fetch(API + `/finNalog/nalog/${page}/${limit}/${companyCode}`, {
            method: 'POST',
            body: JSON.stringify({ sifra: form.SIFRA, nalog: form.NALOG }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                getFinNalogFirstFreeNumberRed()
                setFinNalog(data.result)
                setTotalstrana(data.totalstrana)
                // let red = data?.map((item) => item.RED);
                // setForm(prev => ({ ...prev, RED: Number(red.length - 1) + 2 }))
                //   console.log(data.result);
                //   console.log(data.totalstrana);
            })

    }

    const getTotal = () => {
        //  console.log(companyCode);
        fetch(API + `/finNalog/total/${companyCode}`, {
            method: 'POST',
            body: JSON.stringify({ sifra: form.SIFRA, nalog: form.NALOG }),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        }).then((response) => response.json())
            .then(data => {
                if (data && data.length) {
                    setSumDuguje(data[0].DUGUJE)
                    setSumPotraz(data[0].POTRAZ)
                    setSumTotal(data[0].UKUPNO)


                }
            })
    }


    // validacija za brisanje naloga 
    const onDelete = (companyCode, nalog) => {
        fetch(API + `/finNalog/delete/${companyCode}/${nalog}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                if (response.status == 200) {
                    setModalOpen(false)
                    setFinNalog(null)
                    setClients(null)
                    setKonto(null)
                    setVrsta(null)
                    checkNalog()
                    setForm(prev => ({
                        ...prev, DATN: '', RED: 1, VRSTA: '', DATD: '', KOMTID: '', OPIS: '', KONTID: '', DUGUJE: '', POTRAZ: '',
                    }))
                }
            })
    }
    // validacija za brisanje reda u okviru naloga 
    const onDeleteItems = (companyCode, nalog, red,) => {
        //   console.log(companyCode, 'sifra', nalog, 'nalog', red, 'red');
        fetch(API + `/finNalog/item/delete/${companyCode}/${nalog}/${red}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                if (response.status == 200) {
                    setModalDeleteItem(false)
                    setItemUpdate(false)
                    setFinNalog(null)
                    setClients(null)
                    setKonto(null)
                    //      setVrsta(null)
                    checkNalog()
                    setForm(prev => ({
                        ...prev, RED: 1,
                        DATD: '', KOMTID: '', OPIS: '', KONTID: '', DUGUJE: '', POTRAZ: '',
                    }))
                    getFinNalogFirstFreeNumberRed()
                    getFinNalogData(1, perPage)
                }
            })
    }
    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje VRSTA
    // popunjava polja podacima iz mreze

    const onEdit = (item) => {
        setItemUpdate(true)
        //  setVrsta(null)
        //   setKonto(null)
        //   setClients(null)

        setForm((prevState) => ({
            ...prevState, SIFRA: item.SIFRA, NALOG: item.NALOG, RED: item.RED, VRSTA: item.VRSTA, DATD: item.DATD, KOMTID: item.KOMTID, OPIS: item.OPIS,
            KONTID: item.KONTID, DUGUJE: item.DUGUJE, POTRAZ: item.POTRAZ
        }));

        console.log(item);
        // funkcija koja se poziva Vrstu dokumenta

        selectDataVrsta.map((vrsta) => {
            if (vrsta.VRSTAD == item.VRSTA) {
                setVrsta(vrsta)
            }
        })
        // funkcija koja se poziva Konto

        selectDataKonto.map((konto) => {
            if (konto.ID == item.KONTID) {
                setKonto(konto)
            }
        })

        // funkcija koja se poziva Komitenta

        selectDataClients.map((clients) => {
            if (clients.ID == item.KOMTID) {
                setClients(clients)
            }
        })


    }


    // funkcija koja hvata promjene na poljima i  setuje vrijednost u form state
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // propsovi za auto komplit  - vrsta dokumenta komitent, konto
    const defaultPropsVrsta = {
        options: selectDataVrsta,
        getOptionLabel: (option) => option ? `${option.VRSTAD} ${option.OPISD}` : '',
    };

    const defaultPropsClients = {
        options: selectDataClients,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    const defaultPropsKonto = {
        options: selectDataKonto,
        getOptionLabel: (option) => option ? `${option.KONTO} ${option.NAZ} ${option.OZNAKA}` : '',
    };


    // validacija broja i obaveznog polja
    const helperValidateOnlyNumbers = (input) => {
        if (input.replace(/^[0-9.]+$/, '').length >= 0) {
            return 'Dozvoljeni su samo brojevi!';
        } else if (input.length < 1) {
            return 'Polje je obavezno!'
        } else {
            return false
        }
    }

    // funkcije za provjeru obaveznih polja - 3 vrsta, komitent, konto

    const checkVrstaRequired = (value) => {
        if (value && !vrsta || !value && !vrsta) {
            setErrorMsgVrsta('Vrsta dokumentna mora biti izabrana')
            setErrorInputVrsta(true)
            vrstaRef.current.focus();
            setVrstaFocus(true)

        } else {
            setErrorMsgVrsta(null)
            setErrorInputVrsta(false)
        }
    }

    const checkClientsRequired = (value) => {
        if (value && !clients || !value && !clients) {
            setErrorMsgClients('Komitent mora biti izabran')
            setErrorInputClients(true)
            clientsRef.current.focus();
            setClientsFocus(true)
        } else {
            setErrorMsgClients(null)
            setErrorInputClients(false)
        }
    }

    const checkKontoRequired = (value) => {
        //   console.log(value);
        if (value && !konto || !value && !konto) {
            setErrorMsgKonto('Konto mora biti izabrano')
            setErrorInputKonto(true)
            kontoRef.current.focus();
            setKontoFocus(true)
        } else {
            setErrorMsgKonto(null)
            setErrorInputKonto(false)
        }
    }

    //povjera naloga
    const checkNalogRequired = (value) => {
        //   console.log(value);
        if (!value || value == 0) {
            setErrorMsgNalog('Nalog je obavezan')
            setErrorInputNalog(true)
            nalogRef.current.focus();
            // setDocumentFocus(true)
        } else {
            setErrorMsgNalog(null)
            setErrorInputNalog(false)
        }
    }

    // stepenasta provjera
    const formCheck = (event) => {

        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        switch (name) {
            case 'NALOG': {
                checkNalogRequired(form.NALOG)
                break;
            }
            case 'DATN': {

                break
            }
            case 'RED': {
                if (!value || value == 0) {
                    setErrorMsgRed('Red je obavezan')
                    setErrorInputRed(true)
                    redRef.current.focus();
                    // setDocumentFocus(true)
                } else {
                    setErrorMsgRed(null)
                    setErrorInputRed(false)
                }
                break
            }

            case 'VRSTA': {
                checkVrstaRequired(value)
                break
            }
            case 'DATD': {
                checkVrstaRequired(form.VRSTA)


                break
            }
            case 'KONTO': {
                checkVrstaRequired(form.VRSTA)
                // if (form.VRSTA == '') {
                //     setErrorMsgVrsta(helperValidateOnlyNumbers(value))
                //     setErrorInputVrsta(helperValidateOnlyNumbers(value) ? true : false)
                //     setErrorMsgVrsta('Vrsta mora se unijeti!')
                //     vrstaRef.current.focus();
                //     setVrstaFocus(true)

                // } else {
                //     setErrorMsgVrsta(null)
                //     setErrorInputVrsta(false)
                //     //                   setForm(prev => ({ ...prev, VRED: (Number(form.KOL) * Number(form.CENA)) }))
                // }
                // console.log(value);
                break
            }

            case 'OPIS': {
                checkVrstaRequired(form.VRSTA)
                checkKontoRequired(value)
                break
            }

            case 'KOMT': {
                checkVrstaRequired(form.VRSTA)
                checkKontoRequired(value)
                checkClientsRequired(value)
                break
            }

            // case 'DUGUJE': {
            //     checkVrstaRequired(form.VRSTA)
            //     checkKontoRequired(form.KONTO)
            //     checkClientsRequired(form.KOMTID)
            //     break
            // }

            // case 'POTRAZ': {
            //     checkVrstaRequired(form.VRSTA)
            //     checkKontoRequired(form.KONTO)
            //     checkClientsRequired(form.KOMTID)
            //     if (form.DUGUJE == 0 || form.POTRAZ == 0) {
            //         setErrorMsgPotraz(helperValidateOnlyNumbers(value))
            //         setErrorInputPotraz(helperValidateOnlyNumbers(value) ? true : false)
            //         setErrorMsgPotraz('Duguje ili potrazuje mora se unijeti!')
            //         potrazRef.current.focus();
            //         setPotrazFocus(true)

            //     } else {
            //         setErrorMsgPotraz(null)
            //         setErrorInputPotraz(false)
            //         //                   setForm(prev => ({ ...prev, VRED: (Number(form.KOL) * Number(form.CENA)) }))
            //     }

            //     break
            // }
        }


        if (name === 'NALOG') {
            // if (errorInputRepository) {
            //     repositoryRef.current.focus()
            // } else {
            //     setDocumentFocus(false)
            //     // dateRef.current.focus()
            // }

        }

        if (name === 'DATN') {
            if (errorInputNalog) {
                //   console.log(errorInputNalog, 'focus');
                setNalogFocus(false)
                nalogRef.current.focus()
            }
        }

        if (name === 'VRSTA') {
            if (errorInputNalog) {
                setNalogFocus(false)
                nalogRef.current.focus()
            }
        }
        if (name === 'DATD') {
            if (errorInputVrsta) {
                //   console.log(errorInputVrsta, 'focus');
                setVrstaFocus(false)
                vrstaRef.current.focus()
            }
        }

        if (name === 'KONTO') {
            if (errorInputVrsta) {
                //   console.log(errorInputVrsta, 'focus');
                setVrstaFocus(false)
                vrstaRef.current.focus()
            }
        }

        if (name === 'OPIS') {
            setDescriptionFocus(false)
            if (errorInputVrsta) {
                //   console.log(errorInputVrsta, 'focus');
                setVrstaFocus(false)
                vrstaRef.current.focus()
            }
            if (errorInputKonto) {
                setKontoFocus(false)
                kontoRef.current.focus()
            }

        }
        if (name === 'KOMT') {
            if (errorInputVrsta) {
                //    console.log(errorInputVrsta, 'focus');
                setVrstaFocus(false)
                vrstaRef.current.focus()
            }
            if (errorInputKonto) {
                setKontoFocus(false)
                kontoRef.current.focus()
            }

        }

        // if (name === 'DUGUJE') {
        //     if (errorInputVrsta) {
        //         console.log(errorInputVrsta, 'focus');
        //         setVrstaFocus(false)
        //         vrstaRef.current.focus()
        //     }
        //     if (errorInputKonto) {
        //         setKontoFocus(false)
        //         kontoRef.current.focus()
        //     }

        //     if (!form.KOMT) {
        //         komtRef.current.focus();
        //         setDescriptionFocus(false)
        //     } else {
        //         dugujeRef.current.focus()
        //     }
        // }
        // if (name === 'POTRAZ') {
        //     // if (form.DUGUJE === 0 && form.POTRAZ === 0) {
        //     //     dugujeRef.current.focus();
        //     //     setDugujeFocus(false)
        //     // }
        //     if (errorInputVrsta) {
        //         console.log(errorInputVrsta, 'focus');
        //         setVrstaFocus(false)
        //         vrstaRef.current.focus()
        //     }
        //     if (errorInputKonto) {
        //         setKontoFocus(false)
        //         kontoRef.current.focus()
        //     }

        //     if (!form.KOMT) {
        //         komtRef.current.focus();
        //         setDescriptionFocus(false)
        //     } else {
        //         potrazRef.current.focus()
        //     }

        // }


    }
    // funkcija koja se poziva klikom na dugme 'Stampa'

    const onPrint = () => {
        // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
        // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
        // poziva api za stampu, otvara izvjestaj(API + data.link)

        if (form) {
            form.SIFRA = companyCode
        }
        fetch(API + '/finNalog/print', {
            method: 'POST',
            body: JSON.stringify(form),
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                if (response.ok) { // checks if the response is with status 200 (successful)
                    return response.blob().then(blob => {
                        let blobURL = URL.createObjectURL(blob);
                        let iframe = document.createElement('iframe'); //load content in an iframe to print later
                        document.body.appendChild(iframe);
                        iframe.style.display = 'none';
                        iframe.src = blobURL;
                        iframe.onload = function () {
                            setTimeout(function () {
                                iframe.focus();
                                iframe.contentWindow.print();
                            }, 1);
                        };
                    });
                }
            })
    }
    // definisu se polja u tabeli i popunjavaju

    const columns = [
        {
            name: 'Red',
            selector: row => row.RED,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.RED}</span>
                </>
            ),
        },
        {
            name: 'Vrsta',
            selector: row => row.VRSTA,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.VRSTA}</span>
                </>
            ),
        },
        {
            name: 'Dat. dok',
            selector: row => moment(row.DATD).format('DD.MM.YYYY'),
            width: '100px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{moment(row.DATD).format('DD.MM.YYYY')}</span>
                </>
            ),
        },
        {
            name: 'Konto',
            selector: row => row.KONTO,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.KONTO}</span>
                </>
            ),
        },
        {
            name: 'Naziv konta',
            selector: row => row.KONTONAZIV,
            width: '250px',
            left: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.KONTONAZIV}</span>
                </>
            ),
        },
        {
            name: 'Opis',
            selector: row => row.OPIS,
            width: '250px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OPIS}</span>
                </>
            ),
        },

        {
            name: 'Šifra komit.',
            selector: row => row.KOMTSIFRA,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.KOMTSIFRA}</span>
                </>
            ),
        },
        {
            name: 'Naziv komitenta',
            selector: row => row.KOMTNAZIV,
            width: '250px',
            left: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.KOMTNAZIV}</span>
                </>
            ),
        },

        {
            name: 'Duguje',
            selector: row => row.DUGUJE,
            width: '150px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.DUGUJE)}</span>
                </>
            ),
        },
        // {
        //     name: 'Porez',
        //     selector: row => row.IZNOSPOR,
        //     right: 'boolean',
        // },
        {
            name: 'Potražuje',
            selector: row => row.POTRAZ,
            width: '150px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.POTRAZ)}</span>
                </>
            ),
        },
    ];

    const handlePageChange = page => {
        getFinNalogData(page, perPage)
    }
    // funkcija se poziva prilikom promjene broja redova ispisa u tabeli 10, 15, 30 ...

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }

    const searchChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);

        const fetchData = async () => {
            const res = await axios.get(`${API}/finNalog/query/${companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setFinNalog(res.data);
            console.log(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka

        if (searchInput.length === 0 || searchInput.length > 2) fetchData();

    };

    // funkcija za brisanje pretrage klikom na dugme 'Ocisti'
    // osvjezava polje za pretragu
    // osvjezava mrezu

    const handleClear = () => {
        setSearchInput('');
        getFinNalogData(1, perPage)
    }

    return (

        <div>
            {/* Kontejner za naslov  */}

            <div className="title-container">
                {/* Naziv registra */}

                <header className="title-header">
                    <h1 id="media-query-title">Unos Naloga</h1>
                </header>
                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}


                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li class="nav-item toolbaritem">
                            <input type="image" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="11" src={require('./../assets/images/floppydisk.png')} onClick={onSubmit} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" className='dugme-stampa' title='Štampa' tabIndex="12" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <div onClick={form.NALOG && form.NALOG !== '' && form.NALOG !== 0 ? setModalOpen : setModalErrorDeleteNalogOpen} tabIndex="13">
                                <SVG src={deleteNalog} style={{ margin: '1px' }} title='Brisanje naloga' className='dugme-brisanje' />
                            </div>
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" className='dugme-stampa' title='Brisanje stavke' tabIndex="14" src={require('./../assets/images/trash1.png')} onClick={form.RED && form.RED !== '' && form.RED !== 0 ? setModalDeleteItem : setModalErrorDeleteItemOpen} />
                        </li>

                    </ul>
                </div>

            </div>
            {/* Kontejner pune sirine */}
            <Container fluid>
                <Row>
                    <div className='products-form'>
                        <Row>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-12" style={{ display: 'flex' }}>
                                {/* Sirina polja */}
                                <div className="col-1">
                                    <div className='col-form-right'>
                                        {/* Provjera da li je polje popunjeno jer je obavezno */}
                                        <div className={errorInputNalog ? 'my-input' : 'field-container-number'} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Nalog:</label>
                                            <input
                                                id="media-query-input-text"
                                                type="number"
                                                tabIndex="1"
                                                ref={nalogRef}
                                                // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                                onFocus={() => setNalogFocus(true)}
                                                placeholder="nalog"
                                                name='NALOG'
                                                value={form?.NALOG}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)}
                                                // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                                onBlur={(e) => formCheck(e)} />
                                            {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                            {errorMsgNalog ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgNalog}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                                {/* Sirina polja */}
                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        {/*  koristi program za formatiranje tj. odabir datuma */}
                                        <label id="media-query-label">Dat. nal.:</label>
                                        <DatePicker
                                            id="media-query-input-text"
                                            tabIndex="2"
                                            ref={dateRef}
                                            dateFormat="dd.MM.yyyy"
                                            name='DATN'
                                            // danasnji datum naloga 
                                            selected={startDate}
                                            // ako izmjena na datuma postavi novi datum odabrani
                                            onChange={(date) => setStartDate(date)}
                                            // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                            onBlur={(e) => formCheck(e)} />
                                    </div>
                                </div>
                            </div>

                        </Row>

                        <Row>
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                {/* Sirina reda */}
                                {/* <div className="col-12" style={{ display: 'flex' }}> */}
                                {/* Sirina polja */}
                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className={errorInputRed ? 'my-input' : 'field-container-number'} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Red:</label>
                                            <input
                                                id="media-query-input-text"
                                                type="number"
                                                ref={redRef}
                                                onFocus={() => setRedFocus(true)}
                                                tabIndex="3"
                                                placeholder="red"
                                                name='RED'
                                                value={form?.RED}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)}
                                                // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                                onBlur={(e) => formCheck(e)} />
                                            {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                            {errorMsgRed ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgRed}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                                {/* Sirina polja */}
                                <div className="col-4">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputVrsta ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Vrsta:</label>
                                        {/* Ako je Autocomplete  poziva odabir vrste dokumenta*/}
                                        <Autocomplete
                                            {...defaultPropsVrsta}
                                            value={vrsta}
                                            disablePortal={true}
                                            // Ako je izmjena na polju
                                            onChange={(event, newValue) => {
                                                //   console.log(newValue);
                                                setForm(prev => ({ ...prev, VRSTA: newValue && newValue != null ? newValue.VRSTAD : null }))
                                                setVrsta(newValue)
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    tabIndex='4'
                                                    ref={vrstaRef}
                                                    name='VRSTA'
                                                    onFocus={() => setVrstaFocus(true)}
                                                    className="vrsta-textinput"
                                                    placeholder=' Izaberite vrstu dokumenta'
                                                    variant="standard"
                                                    // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                                    onBlur={(e) => formCheck(e)} />
                                            )}
                                        />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgVrsta ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgVrsta}</span> : ''}
                                    </div>
                                </div>
                                {/* Sirina polja */}
                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        {/*  koristi program za formatiranje tj. odabir datuma */}
                                        <label id="media-query-label">Dat. dok:</label>
                                        <DatePicker
                                            id="media-query-input-text"
                                            tabIndex="5"
                                            ref={dateRef}
                                            dateFormat="dd.MM.yyyy"
                                            name='DATD'
                                            //  startuje danasnji datum  
                                            selected={documentDate}
                                            // ako izmjena na datuma postavi novi datum odabrani
                                            onChange={(date) => setDocumentDate(date)}
                                            // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                            onBlur={(e) => formCheck(e)} />
                                    </div>
                                </div>
                                {/* Sirina polja */}

                                {/* Sirina polja */}
                                <div className="col-5">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputKonto ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Konto:</label>
                                        {/* Ako je Autocomplete  poyiva na odabri mjesta*/}
                                        <Autocomplete
                                            {...defaultPropsKonto}
                                            value={konto}
                                            disablePortal={true}
                                            // ako   odabrani novi
                                            onChange={(event, newValue) => {
                                                //    console.log(newValue);
                                                setForm(prev => ({ ...prev, KONTID: newValue && newValue != null ? newValue.ID : null }))
                                                setKonto(newValue)
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    tabIndex='6'
                                                    ref={kontoRef}
                                                    name='KONT'
                                                    onFocus={() => setKontoFocus(true)}
                                                    className="clients-textinput"
                                                    placeholder=' Izaberite konto'
                                                    variant="standard"
                                                    // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                                    onBlur={(e) => formCheck(e)} />
                                            )}
                                        />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgKonto ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgKonto}</span> : ''}
                                    </div>
                                </div>
                            </div>
                            {/* Sirina polja */}
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px', marginRight: 'auto', marginLeft: '0px' }}>
                                <div className="col-4">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputDescription ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Opis:</label>
                                        <input type="text" id="media-query-input-text" ref={descriptionRef} tabIndex="7" placeholder="opis" name='OPIS' value={form ? form.OPIS : ''} onChange={(e) => handleChange(e)} onBlur={(e) => formCheck(e)} />
                                        {errorMsgDescription ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgClients}</span> : ''}
                                    </div>
                                </div>
                                <div className="col-4">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputClients ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Komitent:</label>
                                        {/* Ako je Autocomplete  poyiva na odabri mjesta*/}
                                        <Autocomplete
                                            {...defaultPropsClients}
                                            value={clients}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KOMTID: newValue && newValue != null ? newValue.ID : null }))
                                                setClients(newValue)
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    tabIndex='8'
                                                    ref={clientsRef}
                                                    name='KOMT'
                                                    onFocus={() => setClientsFocus(true)}
                                                    className="clients-textinput"
                                                    placeholder=' Izaberite komitenta'
                                                    variant="standard"
                                                    // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                                    onBlur={(e) => formCheck(e)} />
                                            )}
                                        />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgClients ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgClients}</span> : ''}
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-2">
                                    <div className='col-form-right'>
                                        {/* Provjera da li je polje popunjeno jer je obavezno */}
                                        <div className={errorInputDuguje ? 'my-input' : 'field-container-number'} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Duguje:</label>
                                            <input
                                                id="media-query-input-text"
                                                type="number"
                                                ref={dugujeRef}
                                                tabIndex="9"
                                                placeholder="duguje"
                                                name='DUGUJE'
                                                value={form ? form.DUGUJE : ''}
                                                onChange={(e) => handleChange(e)}
                                                // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                                onBlur={(e) => formCheck(e)} />
                                            {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                            {errorMsgDuguje ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDuguje}</span> : ''}
                                        </div>
                                    </div>
                                </div>

                                {/* Sirina polja */}

                                <div className="col-2">
                                    <div className='col-form-right'>
                                        {/* Provjera da li je polje popunjeno jer je obavezno */}
                                        <div className={errorInputPotraz ? 'my-input' : 'field-container-number'} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Potražuje:</label>
                                            <input
                                                id="media-query-input-text"
                                                type="number"
                                                ref={potrazRef}
                                                tabIndex="10"
                                                placeholder="potraz"
                                                name='POTRAZ'
                                                // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                                value={form ? form.POTRAZ : ''}
                                                onFocus={() => setPotrazFocus(true)}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)}
                                                // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                                onBlur={(e) => formCheck(e)} />
                                            {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                            {errorMsgPotraz ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgPotraz}</span> : ''}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Row>


                    </div>
                </Row >
            </Container >
            {/* Mreza */}

            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => searchChange(e)} value={searchInput} />
                {/* dugme 'Ocisti'( poziva funkciju handleClear za brisanje) */}
                <button onClick={() => handleClear()} className='dugme-clear'>Očisti</button>

                {/* // <img src={Pencil} className='search-icon' /> */}
            </div>
            {
                <DataTable
                    columns={columns}
                    data={finNalog ? finNalog : []}
                    pagination
                    paginationServer
                    paginationTotalRows={totalstrana}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    responsive
                    highlightOnHover
                    striped
                    dense
                    pointerOnHover
                    persistTableHead
                    paginationComponentOptions={defaultComponentOptions}
                    onRowDoubleClicked={(row, event) => {
                        onEdit(row)
                    }}
                />
            }
            {/* 
                columns={columns}
                    data={finNalog ? finNalog : []}
                    pagination
                    paginationServer
                    paginationTotalRows={totalstrana}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    responsive
                    highlightOnHover
                    striped
                    dense
                    pointerOnHover
                    paginationComponentOptions={defaultComponentOptions}
                    persistTableHead
                    onRowDoubleClicked={(row, event) => {
                        onEdit(row)
                    }}
                />
            } */}

            <div className="title-container">
                {/* Naziv registra */}

                <footer className="title-header">
                    <div></div>
                </footer>
                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}

                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">
                        <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>

                            <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '4px' }}>
                                <div className='col-form-right'>
                                    <div className="field-container-number">
                                        <label id="media-query-label-sum">Duguje</label>
                                        <input className="form-control" readOnly={true} type='text' id="media-query-input-text" value={sumDuguje && sumDuguje != 'NaN' ? numberWithCommas(sumDuguje) : 0} />
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '7px' }}>
                                <div className='col-form-right'>
                                    <div className="field-container-number">
                                        <label id="media-query-label-sum">Potražuje</label>
                                        <input type='text' className="form-control" readOnly={true} id="media-query-input-text" value={sumPotraz && sumPotraz != 'NaN' ? numberWithCommas(sumPotraz) : 0} />
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '5px' }}>
                                <div className='col-form-right'>
                                    <div className="field-container-number">
                                        <label id="media-query-label-sum">Saldo</label>
                                        <input className="total" readOnly={true} type='text' id="media-query-input-text" value={sumTotal && sumTotal != 'NaN' ? numberWithCommas(sumTotal) : 0} />
                                    </div>
                                </div>
                            </li>

                        </div>
                    </ul>
                </div>

            </div>

            {/* <Container fluid>

                <Row>
                    <footer className='container-promet-footer'>
                    </footer>
                    <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>
                        <div className='col-form-right'>
                            <div className="field-container-number">
                                <label id="media-query-label-sum">Saldo</label>
                                <input className="total" readOnly={true} type='text' id="media-query-input-text" value={sumTotal && sumTotal != 'NaN' ? numberWithCommas(sumTotal) : 0} />
                            </div>
                        </div>
                        <div className='col-form-right'>
                            <div className="field-container-number">
                                <label id="media-query-label-sum">Duguje</label>
                                <input className="form-control" readOnly={true} type='text' id="media-query-input-text" value={sumDuguje && sumDuguje != 'NaN' ? numberWithCommas(sumDuguje) : 0} />
                            </div>
                        </div>
                        <div className='col-form-right'>
                            <div className="field-container-number">
                                <label id="media-query-label-sum">Potražuje</label>
                                <input type='text' className="form-control" readOnly={true} id="media-query-input-text" value={sumPotraz && sumPotraz != 'NaN' ? numberWithCommas(sumPotraz) : 0} />
                            </div>
                        </div>
                    </div>
                </Row>

            </Container> */}


            <div>
                <Modal isOpen={modalOpen} centered  >
                    <ModalHeader>Brisanje naloga</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete nalog broj ${form.NALOG} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalOpen(false)} className="button-no">Ne</button>
                            <button onClick={() => onDelete(companyCode, form.NALOG)} className="button-yes">Da</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={modalDeleteItem} centered  >
                    <ModalHeader>Brisanje stavke</ModalHeader>
                    <ModalBody>
                        <span>{`Da li želite da obrišete stavku broj ${form.RED} ? `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalDeleteItem(false)} className="button-no">Ne</button>
                            <button onClick={() => onDeleteItems(companyCode, form.NALOG, form.RED)} className="button-yes">Da</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={modalErrorOpen} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalErrorOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={modalErrorCheckClient} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite komitenta ! `}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorCheckClient(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>


            <div>
                <Modal isOpen={modalErrorDeleteNalogOpen} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Morate odabrati dokument za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalErrorDeleteNalogOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={modalErrorDeleteItemOpen} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Morate odabrati stavku za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalErrorDeleteItemOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>


        </div >
    );

}

export default FinNalog;


