import { Link } from "react-router-dom";
import React from 'react';

const Missing = () => {
    return (
        <article style={{ padding: "100px" }}>
            <h1>Oops!</h1>
            <p>Ova stranica ne postoji</p>
            <div className="flexGrow">
                <Link to="/">Posjetite početnu stranicu</Link>
            </div>
        </article>
    )
}

export default Missing