// importovanje komponenti 
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import SVG from 'react-inlinesvg';
import saveIcon from '../assets/save.svg';
import printIcon from '../assets/print.svg';
import exportIcon from '../assets/export.svg';
import { API } from '../constants';
// komponenta koju kreiramo
function Item() {
   // definisanje konstanti koje koristimo za fokusiranje polja

    const [item, setItem] = useState(null)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null


    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };

    const [form, setForm] = useState({
        SIFRAP: localStorage.getItem('company'),
        SIFRA: '',
        NAZIV: '',
        JM: '',
        KOMPAK: '',
        OZNAKA: '',
        UVOZNIKID: '',
        POREZID: '',
        BAR_KOD: '',
        GRUPAID: '',
        BREND: '',
        KATBROJ: '',
        FAB_BROJ: '',
        AKCIZA: '',
    })

    const onSubmit = () => {
        if (form && form.ID) {
            fetch(API + `/item/update${companyCode}`, {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => {
                    response.json()
                    setForm({
                        SIFRAP: localStorage.getItem('company'),
                        SIFRA: '',
                        NAZIV: '',
                        JM: '',
                        KOMPAK: '',
                        OZNAKA: '',
                        UVOZNIKID: '',
                        POREZID: '',
                        BAR_KOD: '',
                        GRUPAID: '',
                        BREND: '',
                        KATBROJ: '',
                        FAB_BROJ: '',
                        AKCIZA: '',
                    })
                    getTableData(1, perPage)
                })
        } else {
            fetch(API + `/item/new/${companyCode}`, {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => {
                    response.json()
                    setForm({
                        SIFRAP: localStorage.getItem('company'),
                        SIFRA: '',
                        NAZIV: '',
                        JM: '',
                        KOMPAK: '',
                        OZNAKA: '',
                        UVOZNIKID: '',
                        POREZID: '',
                        BAR_KOD: '',
                        GRUPAID: '',
                        BREND: '',
                        KATBROJ: '',
                        FAB_BROJ: '',
                        AKCIZA: '',
                    })
                    getTableData(1, perPage)
                })
        }
    }

    const getTableData = (page, limit) => {
        fetch(API + `/item/all/${page}/${limit}/${companyCode}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setItem(data.result)
                setTotal(data.total)
            })
    }


    useEffect(() => {
        getTableData(1, perPage)
    }, [perPage])


    useEffect(() => {

    }, [form])

    useEffect(() => {

    }, [item])


    const onDelete = (ID) => {
        fetch(API + '/item/delete/' + ID, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                getTableData(1, perPage)
            })
    }

    const onEdit = (item) => {
        setForm(item)

    }

    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    const columns = [
        {
            name: 'Šifra',
            selector: row => row.SIFRA,
            width: '200px'
        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
        },
        {
            name: 'JM',
            selector: row => row.JM,
        },
        {
            name: 'Porez',
            selector: row => row.POREZID,
        },
        {
            name: 'Bar kod',
            selector: row => row.BAR_KOD,
        },
        {
            name: 'Bar kod',
            selector: row => row.GRUPAID,
        },
        {
            name: 'Grupa',
            selector: row => row.BREND,
        },
        {
            name: 'Kat.broj',
            selector: row => row.KATBROJ,
        },
        {
            name: 'Fab.broj',
            selector: row => row.FAB_BROJ,
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} className='btn btn-primary'>Edit</span>{'     '}
                    <span onClick={() => onDelete(row.ID)} className='btn btn-danger'>Brisanje</span>
                </>

            ),

        }
    ];


    const handlePageChange = page => {
        getTableData(page, perPage)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }

    return (

        <div>
            <div className="title-container">

                <header className="title-header">
                    <h1>Artikal</h1>
                </header>


                <div className="title-options-group">

                    <button onFocus={() => onSubmit()}>
                        <div className="option" tabIndex="14">
                            <SVG src={saveIcon} />
                        </div>
                        <span>Snimi</span>
                    </button>
                    <button /*onClick={() => this.export()}*/>
                        <div className="option" tabIndex="15">
                            <SVG src={printIcon} />
                        </div>
                        <span>Štampaj</span>
                    </button>
                    <button /*onClick={() => this.export()}*/>
                        <div className="option" tabIndex="16">
                            <SVG src={exportIcon} />
                        </div>
                        <span>Export</span>
                    </button>


                </div>

            </div>
            <Container fluid>
                <Row>
                    <div className='products-form'>
                        <Row>

                            <div className="col-12" style={{ display: 'flex' }}>
                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px', marginBottom: '0px' }}>
                                        <label>Šifra:</label>
                                        <input type="text" tabIndex="1" autoFocus={true} placeholder="šifra" name='SIFRA' value={form ? form.SIFRA : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label>Naziv:</label>
                                        <input type="text" tabIndex="2" placeholder="naziv" name='NAZIV' value={form ? form.NAZIV : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label>JM:</label>
                                        <input type="text" tabIndex="3" placeholder="jedinica mjere" name='JM' value={form ? form.JM : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label>Porez:</label>
                                        <input type="text" tabIndex="4" placeholder="porez" name='POREZID' value={form ? form.POREZID : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>



                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label>Bar kod:</label>
                                        <input type="text" tabIndex="5" placeholder="bar_kod" name='BAR_KOD' value={form ? form.BAR_KOD : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label>Grupa:</label>
                                        <input type="text" tabIndex="6" placeholder="grupa" name='GRUPAID' value={form ? form.GRUPAID : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label>Kompak:</label>
                                        <input type="text" tabIndex="7" placeholder="kom/pak" name='KOMPAK' value={form ? form.KOMPAK : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label>Oznaka:</label>
                                        <input type="text" tabIndex="8" placeholder="oznaka" name='OZNAKA' value={form ? form.OZNAKA : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>

                        </Row>

                        <Row>
                            <div className="col-12" style={{ display: 'flex' }}>

                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label>Uvoznik:</label>
                                        <input type="text" tabIndex="9" placeholder="uvoznik" name='UVOZNIKID' value={form ? form.UVOZNIKID : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label>Brend:</label>
                                        <input type="text" tabIndex="10" placeholder="brend" name='BREND' value={form ? form.BREND : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label>Kat.broj:</label>
                                        <input type="text" tabIndex="11" placeholder="kat. broj" name='KATBROJ' value={form ? form.KATBROJ : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>



                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label>Fab.broj:</label>
                                        <input type="text" tabIndex="12" placeholder="fab. broj" name='FAB_BROJ' value={form ? form.FAB_BROJ : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label>Akciza:</label>
                                        <input type="text" tabIndex="13" placeholder="akciza" name='AKCIZA' value={form ? form.AKCIZA : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>



                                {/* <button onClick={onSubmit} style={{ marginTop: '18px' }} className='button-dark btn-md' type='submit'>Sačuvaj</button> */}
                            </div>
                        </Row>

                    </div>
                </Row>
            </Container>

            {item ?
                <DataTable
                    columns={columns}
                    data={item}
                    pagination
                    paginationServer
                    paginationTotalRows={total}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    responsive
                    striped
                    dense
                    pointerOnHover
                    paginationComponentOptions={defaultComponentOptions}
                />
                : null
            }
        </div>
    );

}

export default Item;


