// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import Pencil from '../assets/pencil.svg';
import axios from '../api/axios';
import { Spinner } from "reactstrap";
// komponenta koju kreiramo
function VrstaDok() {
    // definisanje konstanti koje koristimo za fokusiranje polja
    const vrstaRef = useRef();
    const nameRef = useRef();
    const errRef = useRef();
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat

    const [vrstaDok, setVrstaDok] = useState(null)
    const [vrsta, setVrsta] = useState(null)
    const [name, setName] = useState(null)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    // error row inputs
    const [errorInputVrsta, setErrorInputVrsta] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    // error messages inputs
    const [errorMsgVrsta, setErrorMsgVrsta] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [vrstaFocus, setVrstaFocus] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);
    const [searchInput, setSearchInput] = useState([]);
    const [firstVrsta, setfirstVrsta] = useState(null);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);

    const [spinner, setSpinner] = useState(false);
    // povlacenje podataka iz lokal storidza

    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null
    const companyNaziv = typeof window !== 'undefined' ? localStorage.getItem('companyNaziv') : null
    // niz prema kome se formira pagination u mrezi(pri kreiranju 'DataTable')
    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };
    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija

    const [form, setForm] = useState({
        VRSTA: '',
        NAZIV: ''
    })
    // funkcija koja se poziva klikom na dugme 'Sacuvaj'

    const onSubmit = () => {
        // provjera obaveznih polja na dugmetu
        // ukoliko nisu popunjena obavezna polja, zaustavlja akciju i prikazuje upozorenje(poruka)
        // ukoliko su popunjena obavezna polja, upada u else i izvrsava se funkcija

        if (form.VRSTA == " " || form.VRSTA == 0 || form.NAZIV == " " || form.NAZIV == 0) {
            setModalErrorOpen(true)
        } else {

            if (form && (form.VRSTA != firstVrsta)) {
                fetch(API + '/vrstaDok/update', {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            VRSTA: '',
                            NAZIV: ''
                        })
                        getTableData(1, perPage)
                    })
                // u suprotnom ide na upis sloga
                // poziva api za upis
                // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)
            } else {
                fetch(API + '/vrstaDok/new', {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            VRSTA: '',
                            NAZIV: ''
                        })
                        //                        getCountryFirstFreeNumberSifra()
                        getTableData(1, perPage)
                    })
            }
        }
    }
    // funkcija koja se poziva klikom na dugme 'Stampa'

    const onPrint = () => {
        setSpinner(true)
        if (vrstaDok && vrstaDok.length === 0) {
            setSpinner(false)
            setModalErrorOpenData(true)
        } else {
            // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
            // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
            // poziva api za stampu, otvara izvjestaj(API + data.link)
            if (form) {
                form.NAZIV_PRED = companyNaziv
            }
            fetch(API + '/vrstaDok/print/', {
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let blobURL = URL.createObjectURL(blob);

                            let iframe = document.createElement('iframe'); //load content in an iframe to print later
                            document.body.appendChild(iframe);

                            iframe.style.display = 'none';
                            iframe.src = blobURL;
                            iframe.onload = function () {
                                setTimeout(function () {
                                    iframe.focus();
                                    iframe.contentWindow.print();
                                }, 1);
                            };
                            setSpinner(false)
                        });
                    }
                })
        }
    }
    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setCountry) i puni ukupan broj stranica(setTotal)

    const getTableData = (page, limit) => {
        fetch(API + `/vrstaDok/all/${page}/${limit}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setVrstaDok(data.result)
                setTotal(data.total)
            })
    }


    // poziva se inicijalno pri otvaranju forma i poziva api za popunjavanje tabele
    // postavlja fokus na polje sifra
    // prati promjenu state perPage 
    useEffect(() => {
        getTableData(1, perPage)
        vrstaRef.current.focus();
    }, [perPage])


    useEffect(() => {
        //        getCountryFirstFreeNumberSifra()
    }, [!form.VRSTA])

    // const getCountryFirstFreeNumberSifra = () => {
    //     fetch(API + `/country/firstFreeNumberSifra`, {
    //         method: 'POST',
    //         body: JSON.stringify(form),
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //             'Content-type': 'application/json; charset=UTF-8'
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then(data => {
    //             setForm(prev => ({ ...prev, SIFRA: Number(data) }))
    //             setfirstCode(Number(data))
    //         })
    // }


    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
    const onDelete = (VRSTA) => {
        fetch(API + '/vrstaDok/delete/' + VRSTA, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                //               getCountryFirstFreeNumberSifra()
                getTableData(1, perPage)
            })
    }
    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze

    const onEdit = (item) => {
        setForm(item)

    }

    // funckija koja se poziva prilikom promjene vrednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // validacija broja i obaveznog polja
    const helperValidateOnlyNumbers = (input) => {
        if (input.replace(/^[0-9.]+$/, '').length > 0) {
            return 'Dozvoljeni su samo brojevi!';
        } else if (input.length < 1) {
            return 'Polje je obavezno!'
        } else {
            return false
        }

    }

    // validacija praznog polja
    const helperValidateEmptyField = (input) => {
        if (input.length < 1) return 'Polje je obavezno!';
    }


    // stepenasta
    const formCheck = (event) => {

        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        switch (name) {
            case 'VRSTA': {
                setErrorMsgVrsta(helperValidateEmptyField(value))
                setErrorInputVrsta(helperValidateEmptyField(value) ? true : false)
                break;

            }
            case 'NAZIV': {
                setErrorMsgName(helperValidateEmptyField(value))
                setErrorInputName(helperValidateEmptyField(value) ? true : false)
                break;
            }

        }


        if (name === 'VRSTA') {
            setVrstaFocus(false)
        }

        if (name === 'NAZIV') {
            setNameFocus(false)
        }

    }

    useEffect(() => {
        if (vrstaRef.current && errorInputVrsta) {
            vrstaRef.current.focus();
        }
    }, [!vrstaFocus]);

    useEffect(() => {
        if (nameRef.current && errorInputName) {
            nameRef.current.focus();
        }
    }, [!nameFocus]);

    // funkcija se poziva prilikom promjene stranice u tabeli stranica 1,2,3 ...
    const handlePageChange = page => {
        getTableData(page, perPage)
    }

    // funkcija se poziva prilikom promjene broja ispisa u tabeli 10, 15, 30 ...
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }

    // definisu se polja u tabeli
    const columns = [
        {
            name: 'Vrsta',
            selector: row => row.VRSTA,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.VRSTA}</span>
                </>
            ),

        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
            width: '500px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'Action',
            width: '900px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{''}
                    <span onClick={() => onDelete(row.VRSTA)} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>
            ),
        }
    ];

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 

    const searchChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        const fetchData = async () => {
            const res = await axios.get(`${API}/vrstaDok/query/${companyCode}?q=${searchInput}`, { headers: {"Authorization" : `Bearer ${token}`} });
            setVrstaDok(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length > 2) fetchData();

    };

    // funkcija za brisanje pretrage klikom na dugme 'Ocisti'
    // osvjezava polje za pretragu
    // osvjezava mrezu

    const handleClear = () => {
        setSearchInput('');
        getTableData(1, perPage)
    }

    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container">

                <header className="title-header">
                    <h1 id="media-query-title">Vrsta dokumenata</h1>
                </header>
                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}


                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="3" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" className='dugme-stampa' title='Štampa' tabIndex="4" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                    </ul>
                </div>

            </div>
            {/* Kontejner pune sirine */}
            <Container fluid>
                <Row>
                    {/* Sirina reda */}
                    <Col lg='12'>
                        <div className='products-form'>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-6" style={{ display: 'flex', marginBottom: '0px', width: '60%' }}>
                                {/* Sirina polja */}
                                <div className="col-1">
                                    <div className={errorInputVrsta ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Vrsta:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="2"
                                            tabIndex="1"
                                            placeholder="Vrsta"
                                            name='VRSTA'
                                            // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                            value={form?.VRSTA}
                                            ref={vrstaRef}
                                            onFocus={() => setVrstaFocus(true)}
                                            // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                            onBlur={(e) => formCheck(e)}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgVrsta ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgVrsta}</span> : ''}
                                    </div>
                                </div>
                                {/* Sirina polja */}

                                <div className="col-8">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputName ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Naziv:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="40"
                                            ref={nameRef}
                                            tabIndex="2"
                                            placeholder="Naziv"
                                            name='NAZIV'
                                            // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                            value={form?.NAZIV}
                                            onFocus={() => setNameFocus(true)}
                                            // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                            onBlur={(e) => formCheck(e)}
                                            // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                            onChange={(e) => handleChange(e)} />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>

            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => searchChange(e)} value={searchInput} />
                {/* dugme 'Ocisti'( poziva funkciju handleClear za brisanje) */}
                <button onClick={() => handleClear()} className='dugme-clear'>Očisti</button>

                {/* // <img src={Pencil} className='search-icon' /> */}
            </div>
            {/* Mreza */}
            <DataTable
                columns={columns}
                data={vrstaDok ? vrstaDok : []}
                pagination
                paginationServer
                paginationTotalRows={total}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}

            />
            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}

            <div>
                <Modal isOpen={modalErrorOpen} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={modalErrorOpenData} centered  >
                     <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

        </div>

    );

}

export default VrstaDok;


