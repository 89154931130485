import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axios from '../api/axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import useAuth from '../hooks/useAuth';
import React from 'react';

const LOGIN_URL = '/auth/login';
const COMPANY_URL = '/company/all'

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { setAuth, persist, setPersist } = useAuth();
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [company, setCompany] = useState(null);
    const [selectData, setSelectData] = useState([]);
    const from = location.state?.from?.pathname || "/";


    const fetchData = () => {
        return axios.get(COMPANY_URL).then(result => {
            const res = result.data;
            setSelectData(res);
            return res
        }

        )
    }

    useEffect(() => {
        userRef.current.focus();
        fetchData();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ user, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            );

            localStorage.setItem("accessToken", JSON.stringify(response?.data?.token));
            localStorage.setItem("company", company.SIFRA);
            localStorage.setItem("companyNaziv", company.NAZIV);
            localStorage.setItem("userCode",  JSON.stringify(response?.data?.code));

            const accessToken = response?.data?.token;
            const roles = 3;
            setAuth({ user, pwd, roles, accessToken });
            setUser('');
            setPwd('');
            if (company == '') {
                setErrMsg('Preduzece je obavezno')
            }else{
                setSuccess(true);
                navigate(from, { replace: true });
            }
          

        } catch (err) {
            if (!err?.response) {
                setErrMsg('Nema odgovora od servera');
            } else if (err.response?.status === 400) {
                setErrMsg('Unesite korisnicko ime ili lozinku');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            // errRef.current.focus();
        }
    }

    const defaultProps = {
        options: selectData,
        getOptionLabel: (option) => option.NAZIV,
    };


    useEffect(() => {
    }, [company])

    return (

        <div className='login-container'>
            <h3>Infosistem</h3>
            <h6>Prijava korisnika</h6>
            <form className='form' onSubmit={handleSubmit}>
                <label htmlFor="username">Korisnicko ime:</label>
                <input
                    type="text"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                />

                <label htmlFor="password">Lozinka:</label>
                <input
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                />
                 
                <Autocomplete 
                    {...defaultProps}
                    id="controlled-demo"
                    value={company}
                    disablePortal={true}
                    onChange={(event, newValue) => {
                        setCompany(newValue);
                    }}

                    renderInput={(params) => (
                        <TextField type={'select'} {...params} required  placeholder=' Izaberite preduzece' variant="standard" />
                    )}
                />
              

                <button className='btn btn-primary'>Prijava</button>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            </form>
        </div>
    );
}

export default Login