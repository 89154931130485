import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API } from '../../constants';
import { RadioButton } from '../radioButton';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Spinner } from "reactstrap";
import {numberWithCommasColumns} from '../utilities'
import {numberWithCommas} from '../utilities';


function FinReport() {
    const [finReport, setFinReport] = useState(null);
    const [total, setTotal] = useState(0)
    const [sum, setSum] = useState(0)
    const [sumduguje, setSumDuguje] = useState(false)
    const [sumpotraz, setSumPotraz] = useState(false)
    const [perPage, setPerPage] = useState(10);
    const [repository, setRepository] = useState(null)
    const [clients, setClients] = useState(null);
    const [konto, setKonto] = useState(null);
    const [selectData, setSelectData] = useState([]);
    const [selectDataClients, setSelectDataClients] = useState([]);
    const [selectDataKonto, setSelectDataKonto] = useState([]);
    const [selectDataAccount, setSelectDataAccount] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [modalErrorOpenPrint, setModalErrorOpenPrint] = useState(false);
    //    const [taxPayers, setTaxPayers] = useState({

    //  });
    const [buttonType, setButtonType] = useState(null)


    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null
    const companyNaziv = typeof window !== 'undefined' ? localStorage.getItem('companyNaziv') : null
    let year = typeof window !== 'undefined' ? localStorage.getItem('companyYear') : null

    // dodaje datum od i datum do. O pocetka godine do danasnjeg dana new Date() ili do kraja godine new Date(currentDate.getFullYear(), 0, 0)
    let dateFrom
    let dateTo
    let currentDate = new Date()
    if (currentDate.getFullYear() === Number(year)) {
        dateFrom = new Date(currentDate.getFullYear(), 0, 1);
        dateTo = new Date()
    } else {
        let oldYear = new Date()
        oldYear = new Date(Number(oldYear.setFullYear(year)))
        dateFrom = new Date(oldYear.getFullYear(), 0, 1);
        dateTo = new Date(Number(oldYear.setFullYear(year)))
    }
    // error row inputs
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [date, setDate] = useState({
        checkDate: true,
        checkDateCancelled: false
    });

    // error messages inputs
    const [errorMsgCode, setErrorMsgCode] = useState('');
    const [errorMsgName, setErrorMsgName] = useState('');

    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };


    const [form, setForm] = useState({
        KOMTID: '',
        KONTID: '',
        DATUMOD: dateFrom,
        DATUMDO: dateTo,
        DATUM: '',
    })

    const [startDate, setStartDate] = useState(dateFrom);
    const [endDate, setEndDate] = useState(dateTo);

    const getReports = (page, limit) => {
        if (buttonType) {
            if (form) {
                form.DATUMOD = startDate
                form.DATUMDO = endDate
                form.KOMTID = clients && clients.ID ? clients.ID : null
                form.KONTID = konto && konto.ID ? konto.ID : null
                //                      form.radioDatumDok = date?.checkDate
                //                      form.radioDatumNal = date?.checkDateCancelled
                //   form.DATN = setDate.DATN
                //    form.DATD = setDate.DATD
                form.buttonType = buttonType

            }


            fetch(API + `/finReport/all/${page}/${limit}/${companyCode}`, {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    setFinReport(data.items)
                    setTotal(data.total)
                    setSum(data.sum)
                    setSumDuguje(data.sumduguje)
                    setSumPotraz(data.sumpotraz)
                })
        }
    }

    const print = () => {
        setSpinner(true)
        if (form) {
            form.NAZIV_PRED = companyNaziv
            form.DATUMOD = startDate
            form.DATUMDO = endDate
            form.KOMTID = clients && clients.ID ? clients.ID : null
            form.SIFRAK = clients && clients.SIFRA ? clients.SIFRA : null
            form.KONTID = konto && konto.ID ? konto.ID : null
            // form.checkPdvObveznici = 
            // form.DATUM = setDate.DATUM
            // form.STORNO = setReversal.STORNO
            // form.PDV = setTaxPayers.PDV
            form.buttonType = buttonType ? buttonType : null
            if (buttonType === null) {
                setModalErrorOpenPrint(true)
                // zatvaranje spinera
                setSpinner(false)
            }
        }

        fetch(API + `/finReport/print/${companyCode}`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                // if (data && data.items && data.items.length !== 0) {
                if (typeof window != 'undefined') {
                    setTimeout(() => {
                        window.open(API + data.link)
                    }, 500);

                }

                // } else {
                //     setModalErrorOpenData(true)
                // }
                // zatvaranje spinera
                setSpinner(false)
            })
    }



    const getClientsData = () => {
        fetch(API + `/client/all/${companyCode}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataClients(data)
            })
    }

    // const getTableData = () => {
    //     fetch(API + `/kontoReport/all/${companyCode}`, {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': `Bearer ${token}`,
    //             'Content-type': 'application/json; charset=UTF-8'
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then(data => {
    //             setSelectDataClients(data)
    //         })
    // }


    const getKontoData = () => {
        fetch(API + `/konto/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataKonto(data)
            })
    }



    useEffect(() => {
        getClientsData()
    }, [!selectDataClients])

    useEffect(() => {
        getKontoData()
        // ako nije popunjeno
    }, [!selectDataKonto])

    // useEffect(() => {
    //     getKontoData()
    //     // getAccountData()

    // }, [perPage])

    // console.log('buttonType', buttonType);

    useEffect(() => {
        getReports(1, perPage)
        // ako je dugme popunjeno,poziva funkciju getReports
    }, [buttonType])

    useEffect(() => {
        getReports(1, perPage)
        // ako je dugme popunjeno,poziva funkciju getReports
    }, [finReport])

    // cekiranje radio dugmadi u zavisnosti odabrane opcije
    const radioChangeHandler = (e) => {
        if (e.target.name === 'checkDate') {
            setDate({
                // odabir datuma dokumenata
                checkDate: true,
                checkDateCancelled: false
            });
        } else {
            setDate({
                checkDate: false,
                // odabir datuma storniranja
                checkDateCancelled: true
            });
        }
    };


    // const handleChange = (event) => {
    //     const target = event.target;
    //     let value = target.value;
    //     const name = event.target.name;

    //     setForm((prevState) => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    // };

    const columns = [

        {
            name: 'Nalog',
            selector: row => row.NALOG,
            width: '100px',
        },
        // {
        //     name: 'Red',
        //     selector: row => row.RED,
        //     width: '100px',
        // },
        {
            name: 'Dat.Dok',
            selector: row => moment(row.DATD).format('DD.MM.YYYY'),
            width: '120px',
        },
        {
            name: 'Dat.Nal',
            selector: row => moment(row.DATN).format('DD.MM.YYYY'),
            width: '120px',
        },
        {
            name: 'VRSTA',
            selector: row => row.VRSTA,
            width: '100px',
        },
        {
            name: 'KONTO',
            selector: row => row.KONTO,
            width: '100px',
        },
        {
            name: 'Naziv konta',
            selector: row => row.kontoNaziv,
            width: '300px',
        },

        {
            name: 'Opis',
            selector: row => row.OPIS,
            width: '220px',
        },
        {
            name: 'SIFRAK',
            selector: row => row.SIFRAK,
            right: 'boolean',
            width: '100px',
        },

        {
            name: 'Duguje',
            selector: row => row.DUGUJE,
            width: '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.DUGUJE)}</span>
                </>
            ),
        },
        {
            name: 'Potražuje',
            selector: row => row.POTRAZ,
            width: '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.POTRAZ)}</span>
                </>
            ),
        },

    ];

    const handlePageChange = page => {
        getReports(page, perPage)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }


    const defaultPropsClients = {
        options: selectDataClients,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    const defaultPropsKonto = {
        options: selectDataKonto,
        getOptionLabel: (option) => option ? `${option.KONTO} ${option.NAZ}` : '',
    };

    return (

        <div>
            <div className="title-container">

                <header className="title-header">
                    <h1 id="media-query-title">Izvještaj</h1>
                </header>


                <div >
                    <div>
                        <ul >
                            <input type="image" className='dugme-stampa' title='Štampa' tabIndex="7" src={require('../../assets/images/printer.png')} onClick={print} disabled={spinner} />

                        </ul>
                    </div>
                </div>
            </div>
            <Container fluid>
                <div className="row">
                <Col lg='1'>


                    <div className='form-field-container' style={{ display: 'contents' }}>
                        {/* red sirine */}
                        <Col lg='12'>
                            {/* definsanje froma za radio dugmad */}
                            <div className="col-sm radio-box" id='media-query-radioButton-date'>
                                <RadioButton
                                    changed={(e) => radioChangeHandler(e)}
                                    isSelected={date?.checkDate}
                                    label="Dat.dok."
                                    id="1"
                                    value={date?.checkDate}
                                    name='checkDate'
                                />
                                {/*  radio dugme za datum storniranja   */}
                                <RadioButton
                                    changed={(e) => radioChangeHandler(e)}
                                    isSelected={date?.checkDateCancelled}
                                    label="Dat.naloga"
                                    id="2"
                                    value={date?.checkDateCancelled}
                                    name='checkDateCancelled'
                                />

                            </div>


                        </Col>
                    </div>
                </Col>


                    <Col lg='5'>

                        <div class="col-12" style={{ display: 'flex' }}>
                            <div class="col-12">
                                <div class="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-finReport">Komitent:</label>
                                    <div class="col-10">
                                        <Autocomplete
                                            {...defaultPropsClients}
                                            value={clients}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setClients(newValue);
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params} className="clients-textinput" autoFocus required placeholder=' Izaberite komitenta' variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" style={{ display: 'flex' }}>
                            <div class="col-12">
                                <div class="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-finReport">Konto:</label>
                                    <div class="col-10">
                                        <Autocomplete
                                            {...defaultPropsKonto}
                                            value={konto}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setKonto(newValue);
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params} className="clients-textinput" required placeholder=' Izaberite konto' variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}> */}

                        {/* <div class="col-12" style={{ display: 'flex', }}>
                            <div class="col-12">
                                <div class="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box">Datum od:</label>
                                    <div class="col-4">
                                        <DatePicker tabIndex="5" dateFormat="dd.MM.yyyy" selected={startDate} onChange={(date) => setStartDate(date)} />
                                        <div>

                                                    <label className="col-form-label col-lg-2 label-box">Datum do:</label>
                                                    <div class="col-4">
                                                    <DatePicker tabIndex="6" dateFormat="dd.MM.yyyy" selected={endDate} onChange={(date) => setEndDate(date)} />
                                             
                                                    </div>
                                        </div>
                                    </div>
                                </div> */}

                        <div class="col-12" style={{ display: 'flex', }}>
                            <div class="col-12">

                                <div class="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-finReport">Datum od:</label>
                                    <div class="col-4" style={{ marginRight: 'auto' }}>
                                        <DatePicker tabIndex="5" id="media-query-input-finReport" dateFormat="dd.MM.yyyy" selected={startDate} onChange={(date) => setStartDate(date)} />
                                    </div>



                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-to-finReport">Datum do:</label>
                                    <div class="col-4" style={{ marginRight: 'auto' }}>
                                        <DatePicker tabIndex="6" id="media-query-input-to-finReport" dateFormat="dd.MM.yyyy" selected={endDate} onChange={(date) => setEndDate(date)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg='6'>
                        <Row>
                            <Col lg='12' >
                                <div className=" flex-lg-row justify-content-end" id='media-query-buttonType'>
                                    <div style={{ display: 'inline-grid' }} className="btn-report">
                                        <button onClick={() => {
                                            setButtonType('pregledPoNalogu')
                                            getReports(1, perPage)
                                        }} > Pregled po nalogu</button>
                                        <button onClick={() => {
                                            setButtonType('pregledPoKomitentima')
                                            getReports(1, perPage)
                                        }
                                        }>Pregled po komitentima i kontima</button>
                                        <button onClick={() => {
                                            setButtonType('pregledPoKontima')
                                            getReports(1, perPage)
                                        }
                                        }>Pregled po kontima i komitentima</button>
                                    </div>
                                    <div style={{ display: 'inline-grid' }} className="btn-report">
                                        <button onClick={() => {
                                            setButtonType('totalPoNalogu')
                                            getReports(1, perPage)
                                        }}>Total po nalogu</button>

                                        <button onClick={() => {
                                            setButtonType('totalPoKomitentima')
                                            getReports(1, perPage)
                                        }} > Total po komitentima i kontima</button>
                                        <button onClick={() => {
                                            setButtonType('totalPoKontima')
                                            getReports(1, perPage)
                                        }}>Total po kontima i komitentima</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </div>
            </Container >

            {
                < DataTable
                    columns={columns}
                    data={finReport ? finReport : []}
                    pagination
                    paginationServer
                    paginationTotalRows={total}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    responsive
                    striped
                    dense
                    pointerOnHover
                    paginationComponentOptions={defaultComponentOptions}
                />
            }
           <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">
                        <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>

                            <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '4px' }}>
                                <div className='col-form-right'>
                                    <div className="field-container-number">
                                        <label id="media-query-label-sum">Duguje</label>
                                        <input className="form-control" readOnly={true} type='text' id="media-query-input-text" value={sumduguje && sumduguje != 'NaN' ? numberWithCommas(sumduguje) : 0} />
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '7px' }}>
                                <div className='col-form-right'>
                                    <div className="field-container-number">
                                        <label id="media-query-label-sum">Potražuje</label>
                                        <input type='text' className="form-control" readOnly={true} id="media-query-input-text" value={sumpotraz && sumpotraz != 'NaN' ? numberWithCommas(sumpotraz) : 0} />
                                    </div>
                                </div>
                            </li>

                            <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '5px' }}>
                                <div className='col-form-right'>
                                    <div className="field-container-number">
                                        <label id="media-query-label-sum">Saldo</label>
                                        <input className="total" readOnly={true} type='text' id="media-query-input-text" value={sum && sum != 'NaN' ? numberWithCommas(sum) : 0} />
                                    </div>
                                </div>
                            </li>
                            
                        </div>
                    </ul>
                </div>
            {/* spiner ili progres bar ya prikaz pokretanja datog izvjestaja */}
            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

            {/* {spinner ? <Progress
                animated
                bar
                color="success"
                value="100" >
                Loading
            </Progress> : ''} */}

        </div >
    );

}

export default FinReport;


