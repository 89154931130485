// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import axios from '../api/axios';

// komponenta koju kreiramo
function Grupa() {

    // definisanje konstanti koje koristimo za fokusiranje polja
    const codeRef = useRef();
    const nameRef = useRef();

    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [grupa, setgrupa] = useState(null)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [codeFocus, setCodeFocus] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);
    const [searchInput, setSearchInput] = useState([]);
    const [firstCode, setfirstCode] = useState(null);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);

    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null
    const companyNaziv = typeof window !== 'undefined' ? localStorage.getItem('companyNaziv') : null

    // niz prema kome se formira pagination u mrezi(pri kreiranju 'DataTable')
    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        SIFRA: '',
        NAZIV: ''
    })

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {

        // provjera obaveznih polja na dugmetu
        // ukoliko nisu popunjena obavezna polja, zaustavlja akciju i prikazuje upozorenje(poruka)
        // ukoliko su popunjena obavezna polja, upada u else i izvrsava se funkcija
        if (form.SIFRA == " " || form.SIFRA == 0 || form.NAZIV == " " || form.NAZIV == 0) {
            setModalErrorOpen(true)
            nameRef.current.focus()
        } else {

            // ako je u pitanju form(objekat) i ako SIFRA iz tog objekta nije nova(ako je pod tim brojem upisan slog ), odradice se izmjena
            // poziva api za izmjenu
            // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)
            if (form && (form.SIFRA != firstCode)) {
                fetch(API + '/grupa/update', {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            SIFRA: '',
                            NAZIV: ''
                        })
                        codeRef.current.focus()
                        getTableData(1, perPage)
                    })
            }
            // u suprotnom ide na upis sloga
            // poziva api za upis
            // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)
            else {
                fetch(API + '/grupa/new', {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            SIFRA: '',
                            NAZIV: ''
                        })
                        codeRef.current.focus()
                        getTableData(1, perPage)
                    })
            }
        }
    }

    // funkcija koja se poziva klikom na dugme 'Stampa'
    const onPrint = () => {

        // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
        // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
        // poziva api za stampu, otvara izvjestaj(API + data.link)
        if (form) {
            form.NAZIV_PRED = companyNaziv
        }
        fetch(API + `/grupa/print/${companyNaziv}`, {
            method: 'POST',
            body: JSON.stringify(form),
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                console.log(data, 'DATA');
                if (typeof window != 'undefined') {
                    setTimeout(() => {
                        console.log(API + data.link, 'link');
                        window.open(API + data.link)
                    }, 200);

                }
            })
    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setgrupa) i puni ukupan broj stranica(setTotal)
    const getTableData = (page, limit) => {
        fetch(API + `/grupa/all/${page}/${limit}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setgrupa(data.result)
                setTotal(data.total)
            })
    }


    // poziva se inicijalno pri otvaranju forma i poziva api za popunjavanje tabele
    // postavlja fokus na polje sifra
    // prati promjenu state perPage 
    useEffect(() => {
        getTableData(1, perPage)
        codeRef.current.focus();
    }, [perPage])


    // prati promjenu stanja polja SIFRA
    // ako nije popunjeno polje SIFRA, aktivira se funkcija za pronalazenje prvog slobodnog broja 
    useEffect(() => {
        getgrupaFirstFreeNumberSifra()
    }, [!form.SIFRA])

    // funkcija za pronalazenje prvog slobodnog broja
    // poziva api
    // u responsu stejt setForm poziva funkciju za pronalazenje prethodne sifre(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna sifra
    // setFirstCode - stejt koji se koristi za razlikovanje izmjene i upisa 
    const getgrupaFirstFreeNumberSifra = () => {
        fetch(API + `/grupa/firstFreeNumberSifra`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, SIFRA: Number(data) }))
                setfirstCode(Number(data))
            })
    }


    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
    const onDelete = (SIFRA) => {
        fetch(API + '/grupa/delete/' + SIFRA, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                // setForm({
                //     SIFRA: '',
                //     NAZIV: ''
                // })
                getgrupaFirstFreeNumberSifra()
                codeRef.current.focus()
                getTableData(1, perPage)
            })
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze
    const onEdit = (item) => {
        nameRef.current.focus()
        setForm(item)

    }

    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // funkcija za validaciju broja i obaveznog polja
    const helperValidateOnlyNumbers = (input) => {

        // ako su unijeti karakteri umjesto brojeva i duzina im je veca od 0, vraca poruku o gresci 
        if (input.replace(/^[0-9.]+$/, '').length > 0) {
            return 'Dozvoljeni su samo brojevi!';
        }
        // ako je duzina unosa manja od 1(ako nije nista unijeto u polje) 
        else if (input.length < 1) {
            return 'Polje je obavezno!'
        }
        // u suprotnom prolazi dalje
        else {
            return false
        }

    }

    // funkcija za validaciju praznog polja tipa varchar
    const helperValidateEmptyField = (input) => {

        // ako je duzina unosa manja od 1(ako nije nista unijeto u polje) 
        if (input.length < 1) return 'Polje je obavezno!';
    }


    // funkcija za stepenastu kontrolu
    // switch-case petlja gdje je parametar name, na osnovu toga da li je vrijednost unijeta ili ne, upada funkciju za validaciju i vraca poruku o gresci 
    // ako je prosao kontrole, stavlja fokus na nulu 
    const formCheck = (event) => {

        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        switch (name) {
            case 'SIFRA': {
                setErrorMsgCode(helperValidateOnlyNumbers(value))
                setErrorInputCode(helperValidateOnlyNumbers(value) ? true : false)
                break;

            }
            case 'NAZIV': {
                setErrorMsgName(helperValidateEmptyField(value))
                setErrorInputName(helperValidateEmptyField(value) ? true : false)
                break;
            }

        }


        if (name === 'SIFRA') {
            setCodeFocus(false)
        }

        if (name === 'NAZIV') {
            setNameFocus(false)
        }

    }

    // prati promjenu stanja fokusa
    // ako je errorInputCode true( polje sifra nije popunjeno), vraca fokus na polje sifra i ne moze se proci dalje dok se ne popuni
    useEffect(() => {
        if (codeRef.current && errorInputCode) {
            codeRef.current.focus();
        }
    }, [!codeFocus]);

    // prati promjenu stanja fokusa
    // ako je errorInputName true( polje naziv nije popunjeno), vraca fokus na polje naziv i ne moze se proci dalje dok se ne popuni
    useEffect(() => {
        if (nameRef.current && errorInputName) {
            nameRef.current.focus();
        }
    }, [!nameFocus]);

    // funkcija se poziva prilikom promjene stranice u tabeli stranica 1,2,3 ...
    const handlePageChange = page => {
        getTableData(page, perPage)
    }

    // funkcija se poziva prilikom promjene broja ispisa u tabeli 10, 15, 30 ...
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }

    // definisu se polja u tabeli i popunjavaju
    const columns = [
        {
            name: 'Šifra',
            selector: row => row.SIFRA,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.SIFRA}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
            width: '600px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'Action',
            width: '900px',
            right: 'boolean',
            cell: (row) => (
                // klikom na dugme Edit, aktivira se funkcija onEdit 
                // klikom na dugme Brisanje, aktivira se funkcija onDelete
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{''}
                    <span onClick={() => onDelete(row.SIFRA)} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>
            ),
        }
    ];

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 
    const searchChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        const fetchData = async () => {
            const res = await axios.get(`${API}/grupa/query/${companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setgrupa(res.data);
        };

        // uslov za ya slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length > 2) fetchData();
    };

    // funkcija za brisanje pretrage klikom na dugme 'Ocisti'
    // osvjezava polje za pretragu
    // osvjezava mrezu
    const handleClear = () => {
        setSearchInput('');
        getTableData(1, perPage)
    }

    return (

        <div>

            {/* Kontejner za naslov  */}
            <div className="title-container">

                {/* Naziv registra */}
                <header className="title-header">
                    <h1 id="media-query-title">Grupa</h1>
                </header>

                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}
                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="3" src={require('./../assets/images/floppydisk.png')} onFocus={onSubmit} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" className='dugme-stampa' title='Štampa' tabIndex="4" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                    </ul>
                </div>

            </div>

            {/* Kontejner pune sirine */}
            <Container fluid>
                <Row>
                    {/* Stilovi koji ce se primjenjivati na svim poljima */}
                    <div className="col-6" style={{ display: 'flex', marginBottom: '0px', width: '60%' }}>

                        {/* Sirina polja */}
                        <div className="col-1">
                            <div className='col-form-right'>
                                {/* Provjera da li je polje popunjeno jer je obavezno */}
                                <div className={errorInputCode ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                    <label id="media-query-label">Šifra:</label>
                                </div>
                                <input type="number"
                                    id="media-query-input-text"
                                    maxLength="6"
                                    tabIndex="1"
                                    placeholder="Šifra"
                                    name='SIFRA'
                                    value={form?.SIFRA}
                                    ref={codeRef}
                                    //  id="number"
                                    // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                    onFocus={() => setCodeFocus(true)}
                                    // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                    onBlur={(e) => formCheck(e)}
                                    // Ako je izmjena na polju
                                    onChange={(e) => handleChange(e)} />
                                {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}
                            </div>
                        </div>

                        {/* Sirina polja */}
                        <div className="col-8">
                            {/* Provjera da li je polje popunjeno jer je obavezno */}
                            <div className={errorInputName ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                <label id="media-query-label">Naziv:</label>
                                <input type="text"
                                    id="media-query-input-text"
                                    ref={nameRef}
                                    maxLength="100"
                                    tabIndex="2"
                                    placeholder="Naziv"
                                    name='NAZIV'
                                    value={form?.NAZIV}
                                    // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                    onFocus={() => setNameFocus(true)}
                                    // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                    onBlur={(e) => formCheck(e)}
                                    // Ako je izmjena na polju
                                    onChange={(e) => handleChange(e)} />
                                {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>


            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => searchChange(e)} value={searchInput} />
                {/* dugme 'Ocisti'( poziva funkciju handleClear za brisanje) */}
                <button onClick={() => handleClear()} className='dugme-clear'>Očisti</button>
            </div>

            {/* Mreza */}

            <DataTable
                columns={columns}
                data={grupa ? grupa : []}
                pagination
                paginationServer
                paginationTotalRows={total}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}

            />


            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}
            <div>
                <Modal isOpen={modalErrorOpen} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        </div>

    );

}

export default Grupa;


