import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import React from 'react';

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null

    if ((auth && allowedRoles.includes(auth.roles)) || token) {
        // console.log("ulazi 1");
        return <Outlet />
    }else{
        if (auth && auth.accessToken) {
            // console.log("ulazi 2");
            return  <Navigate to="/unauthorized" state={{ from: location }} replace />
        }else{
            // console.log("ulazi 3");
            return <Navigate to="/login" state={{ from: location }} replace />
        }
    }


    // return (
    //     auth?.allowedRoles?.includes(auth.roles)
    //         ? <Outlet />
    //         : auth?.accessToken 
    //             ? <Navigate to="/unauthorized" state={{ from: location }} replace />
    //             : <Navigate to="/login" state={{ from: location }} replace />
    // );
}

export default RequireAuth;