// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import axios from '../api/axios';
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import { useWindowSize} from '../components/useWindowSize';
import { NumericFormat } from 'react-number-format';

// komponenta koju kreiramo
function Note() {

    const windowSize = useWindowSize();
    console.log(windowSize, 'windowSize')

    // definisanje konstanti koje koristimo za fokusiranje polja
    const codeRef = useRef();
    const nameRef = useRef();

    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [windowS, setWindowSize] = useState(null);
    const [total, setTotal] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState([]);

    const [note, setNote] = useState(null);

    // error messages inputs
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);
    const [pending, setPending] = React.useState(false);

    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null;

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        SIFRA: '',
        NAZIV: '',
        OPIS1: '',
        OPIS2: '',
        OPIS3: '',
        stavkaPronadjena: false
    })

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {

        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;

        // ide na upis sloga
        // poziva api za upis
        // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)           
        fetch(API + '/note/new', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    SIFRA: '',
                    NAZIV: '',
                    OPIS1: '',
                    OPIS2: '',
                    OPIS3: '',
                    stavkaPronadjena: false
                })
                codeRef.current.focus()
                getTableData()
            })
    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setIme) i puni ukupan broj stranica(setTotal)
    const getTableData = () => {
        fetch(API + `/note/all`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setNote(data.result)
                setPending(false)
            })
    }

    // prilikom pocetne inicijalizacije postavlja prvi slobodan broj
    useEffect(() => {
        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            getNoteFirstFreeNumberSifra()
            getTableData()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    // funkcija za pronalazenje prvog slobodnog broja
    // poziva api
    // u responsu stejt setForm poziva funkciju za pronalazenje prethodne sifre(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna sifra
    // setFirstCode - stejt koji se koristi za razlikovanje izmjene i upisa 
    const getNoteFirstFreeNumberSifra = () => {
        fetch(API + `/note/firstFreeNumberSifra`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, SIFRA: Number(data[0].SIFRA) }))
            })
    }

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
    const onDelete = (ID) => {
               // provjera ovlascenja za brisanje 
               if(form.ovlfakt == 4) return setModalErrorAuthorizationOpen(true)

        fetch(API + '/note/delete/' + ID, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    SIFRA: '',
                    NAZIV: '',
                    OPIS1: '',
                    OPIS2: '',
                    OPIS3: '',
                    stavkaPronadjena: false
                })
                codeRef.current.focus()
                getTableData()
            })
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze
    const onEdit = (item) => {
        item.stavkaPronadjena = true
        setForm(item)

        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            nameRef.current.focus()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };

    }

    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkCodeRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgCode('Šifra je obavezna!')
            setErrorInputCode(true)
            codeRef.current.focus();
            return false
        } else {
            setErrorMsgCode(null)
            setErrorInputCode(false)
            return true
        }
    }

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkNameRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgName('Naziv je obavezan!')
            setErrorInputName(true)
            nameRef.current.focus();
            return false
        } else {
            setErrorMsgName(null)
            setErrorInputName(false)
            return true
        }
    }

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'SIFRA') {
            return true
        }

        if (!checkCodeRequired(form.SIFRA)) {
            return false
        }

        if (name === 'NAZIV') {
            return true
        }

        if (!checkNameRequired(form.NAZIV)) {
            return false
        }
        return true
    }

    // definisu se polja u tabeli i popunjavaju
    const columns = [
        {
            name: 'Šifra',
            selector: row => row.SIFRA,
            width:  '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.SIFRA}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
             // sirina polja po rezolucijama
            width: 
              windowSize.width > 1920 ? '600px'
            : windowSize.width > 1680 ? '580px'
            : windowSize.width > 1600 ? '500px'
            : windowSize.width > 1440 ? '500px'
            : windowSize.width > 1280 ? '200px'
            : windowSize.width > 1024 ? '350px'
            : '250px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'Opis 1',
            selector: row => row.OPIS1,
             // sirina polja po rezolucijama
            width: 
              windowSize.width > 1920 ? '920px'
            : windowSize.width > 1680 ? '820px'
            : windowSize.width > 1600 ? '650px'
            : windowSize.width > 1440 ? '550px'
            : windowSize.width > 1280 ? '700px'
            : windowSize.width > 1024 ? '700px'
            : '550px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.OPIS1}</span>
                </>
            ),
        },
        {
            name: 'Action',
            cell: (row) => (
                < div className='tableCells nav flex-column  flex-lg-row'>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{''}
                    <span onClick={() => onDelete(row.ID)} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                    </div>
            ),
        }
    ];

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 
    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value
        setSearchInput(searchInput);
        const fetchData = async () => {
            const res = await axios.get(`${API}/note/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setNote(res.data);
        };

        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length >= 1) fetchData();

    };
    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container">
                {/* Naziv registra */}

                <header className="title-header">
                    <h1 id="media-query-title">Napomena</h1>
                </header>
                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}

                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="6" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>
                    </ul>
                </div>
            </div>

            {/* Kontejner pune sirine */}
            <Container fluid>
                <Row>
                    {/* Stilovi koji ce se primjenjivati na svim poljima */}
                    <div style={{ display: 'flex', marginLeft: '30px', marginBottom: '-5px', width: '60%' }}>
                        {/* Sirina polja */}
                        <div className="col-1" style={{ marginLeft: '-10px' }}>
                            <div className='col-form-right'>
                                {/* Provjera da li je polje popunjeno jer je obavezno */}
                                <div className={errorInputCode ? 'my-input' : 'field-container-number'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                                    <label id="media-query-label">Šifra:</label>
                                    <NumericFormat
                                        type="number"
                                        getInputRef={codeRef}
                                        maxLength="10"
                                        decimalScale={0}
                                        id="media-query-input-text"
                                        tabIndex="1"
                                        autoFocus={true}
                                        placeholder="Šifra"
                                        name='SIFRA'
                                        value={form?.SIFRA}
                                        onFocus={() => {
                                            getNoteFirstFreeNumberSifra()
                                            unosIspravanDo("SIFRA")
                                        }}
                                        // Ako je izmjena na polju
                                        onChange={(e) => handleChange(e)} />
                                    {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                    {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}
                                </div>
                            </div>
                        </div>

                        {/* Sirina polja */}
                        <div className="col-8">
                            {/* Provjera da li je polje popunjeno jer je obavezno */}
                            <div className={errorInputName ? 'my-input' : 'field-container'} style={{ marginRight: '3px', paddingBottom: '3px' }} >
                                <label id="media-query-label">Naziv:</label>
                                <input type="text"
                                    id="media-query-input-text"
                                    maxLength="100"
                                    ref={nameRef}
                                    tabIndex="2"
                                    placeholder="Naziv"
                                    name='NAZIV'
                                    value={form ? form.NAZIV : ''}
                                   onFocus={() => {
                                        unosIspravanDo("NAZIV")
                                    }}
                                    // Ako je izmjena na polju
                                    onChange={(e) => handleChange(e)} />
                                {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}
                            </div>
                        </div>
                    </div>
                </Row>

                <Row>
                    <div className="col-12">
                        {/* Stilovi i klase za polje za pretragu;  */}
                        <div style={{ display: 'flex', alignItems: 'baseline', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                            <label style={{ marginLeft: '25px', alignItems: 'center', marginRight: '10px', color: '#00009D' }} id="media-query-input-text">Opis1</label>
                            <input type="text" id="media-query-input-text" maxLength="500" tabIndex="3" className='field-container' placeholder="opis1" name='OPIS1' value={form ? form.OPIS1 : ''} 
                             onFocus={() => {
                                unosIspravanDo("OPIS1")
                            }}
                            onChange={(e) => handleChange(e)} />
                        </div>
                        <div className="col-12" style={{ display: 'flex', alignItems: 'baseline', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                            <label style={{ marginLeft: '25px', alignItems: 'center', marginRight: '10px', color: '#00009D' }} id="media-query-input-text">Opis2</label>
                            <input type="text" id="media-query-input-text" maxLength="500" tabIndex="4" placeholder="opis2" name='OPIS2' value={form ? form.OPIS2 : ''} 
                               onFocus={() => {
                                unosIspravanDo("OPIS2")
                            }}
                            onChange={(e) => handleChange(e)} />
                        </div>

                        <div className="col-12" style={{ display: 'flex', alignItems: 'baseline', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                            <label style={{ marginLeft: '25px', alignItems: 'center', marginRight: '10px', color: '#00009D'}} id="media-query-input-text">Opis3</label>
                            <input type="text" id="media-query-input-text" maxLength="100" tabIndex="5" placeholder="opis3" name='OPIS3' value={form ? form.OPIS3 : ''} 
                              onFocus={() => {
                                unosIspravanDo("OPIS3")
                            }}
                            onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                </Row>
            </Container>

            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' id="media-query-input-text" placeholder='Pretraga...' 
                 onChange={(e) => {
                    // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                    if (searchInput.length >= 0) {
                        searchChange(e)
                    } else {
                        getTableData()
                    }
                }} value={searchInput} />
            </div>

            <div className='dataTable'>
            {/* Mreza */}
            <DataTable
                columns={columns}
                data={note ? note : []}
                pagination
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
                progressPending={pending}
            />
            </div>

                {/* render za poruku */}
                <div>
                <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Nemate ovlašćenje za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter>
                         <div>
                            <button onClick={() => setModalErrorAuthorizationOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}
            <div>
                <Modal isOpen={Boolean(modalErrorOpen)} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );
}

export default Note;


