import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API } from '../../constants';
import { RadioButton } from '../radioButton';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Spinner } from "reactstrap";
import { numberWithCommasColumns } from '../utilities'
import { numberWithCommas } from '../utilities';


function PrikazPoVrstiKnjizenja() {
    const [prikazPoVrstiKnjizenja, setPrikazPoVrstiKnjizenja] = useState(null);
    const [total, setTotal] = useState(0)
    const [sum, setSum] = useState(0)
    const [sumduguje, setSumDuguje] = useState(false)
    const [sumpotraz, setSumPotraz] = useState(false)
    const [perPage, setPerPage] = useState(10);
    const [clients, setClients] = useState(null);
    const [konto, setKonto] = useState(null);
    const [vrsta, setVrsta] = useState(null);
    const [selectDataVrsta, setSelectDataVrsta] = useState([]);
    const [selectDataClients, setSelectDataClients] = useState([]);
    const [selectDataKonto, setSelectDataKonto] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [modalErrorOpenPrint, setModalErrorOpenPrint] = useState(false);
    const [buttonType, setButtonType] = useState(null);
    const [buttonTypeSin, setButtonTypeSin] = useState(null)
    //  const [checkDate, setCheckDate] = useState(null);
    //  const [checkDateCancelled, setCheckDateCanceled] = useState(null)
    const [buttonOnClick, setButtonOnClick] = useState(false);


    const [date, setDate] = useState({
        checkDatDok: true,
        checkDatNalog: false
    });


    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null
    const companyNaziv = typeof window !== 'undefined' ? localStorage.getItem('companyNaziv') : null
    let year = typeof window !== 'undefined' ? localStorage.getItem('companyYear') : null

    // dodaje datum od i datum do. O pocetka godine do danasnjeg dana new Date() ili do kraja godine new Date(currentDate.getFullYear(), 0, 0)
    let dateFrom
    let dateTo
    let report = '';

    let currentDate = new Date()
    if (currentDate.getFullYear() === Number(year)) {
        dateFrom = new Date(currentDate.getFullYear(), 0, 1);
        dateTo = new Date()
    } else {
        let oldYear = new Date()
        oldYear = new Date(Number(oldYear.setFullYear(year)))
        dateFrom = new Date(oldYear.getFullYear(), 0, 1);
        dateTo = new Date(Number(oldYear.setFullYear(year)))
    }
    // error row inputs

    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };


    const [form, setForm] = useState({
        KOMTID: '',
        KONTID: '',
        DATUMOD: dateFrom,
        DATUMDO: dateTo,
        DATUM: '',
    })

    const [startDate, setStartDate] = useState(dateFrom);
    const [endDate, setEndDate] = useState(dateTo);

    const getReports = (page, limit) => {
        if (buttonType || buttonTypeSin) {
            if (form) {
                form.DATUMOD = startDate
                form.DATUMDO = endDate
                form.KOMTID = clients && clients.ID ? clients.ID : null
                form.KONTID = konto && konto.ID ? konto.ID : null
                form.VRSTA = vrsta && vrsta.VRSTAD ? vrsta.VRSTAD : null
                form.buttonType = buttonType
                form.buttonTypeSin = buttonTypeSin
                form.checkDatDok = date.checkDatDok
            }

            fetch(API + `/prikazPoVrstiKnjizenja/all/${page}/${limit}/${companyCode}`, {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    setPrikazPoVrstiKnjizenja(data.items)
                    setTotal(data.total)
                    setSum(data.sum)
                    setSumDuguje(data.sumduguje)
                    setSumPotraz(data.sumpotraz)
                })
        }
    }

    const print = () => {
        setSpinner(true)
        if (form) {
            form.NAZIV_PRED = companyNaziv
            form.DATUMOD = startDate
            form.DATUMDO = endDate
            form.KOMTID = clients && clients.ID ? clients.ID : null
            form.KONTID = konto && konto.ID ? konto.ID : null
            form.KONTO = konto && konto.KONTO ? konto.KONTO : null
            form.KONTONAZ = konto && konto.NAZ ? konto.NAZ : null
            form.VRSTA = vrsta && vrsta.VRSTAD ? vrsta.VRSTAD : null
            form.buttonType = buttonType ? buttonType : null
            form.buttonTypeSin = buttonTypeSin ? buttonTypeSin : null
            form.checkDatDok = date.checkDatDok

            if (buttonType === null) {
                setModalErrorOpenPrint(true)
                // zatvaranje spinera
                setSpinner(false)
            }
        }

        // fetch(API + `/prikazPoVrstiKnjizenja/print/${companyCode}`, {
        //     method: 'POST',
        //     body: JSON.stringify(form),
        //     headers: {
        //         'Authorization': `Bearer ${token}`,
        //         'Content-type': 'application/json; charset=UTF-8'
        //     },
        // })
        //     .then((response) => response.json())
        //     .then(data => {

        //         console.log(API, 'API', data, 'data')
        //         // if (data && data.items && data.items.length !== 0) {
        //         if (typeof window != 'undefined') {
        //             setTimeout(() => {
        //                 window.open(API + data.link)
        //             }, 500);

        //         }

        //         // } else {
        //         //     setModalErrorOpenData(true)
        //         // }
        //         // zatvaranje spinera
        //         setSpinner(false)
        //     })

        //    fetch(API + `/` + buttonType + `/print`, {


           // fetch za sql
           // u bodiju saljemo filter(form)
        fetch(API + `/prikazPoVrstiKnjizenja/print/${companyCode}`, {
            method: 'POST',
            body: JSON.stringify({ form }),
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {

                   // fetch za crtanje izvjestaja
                   // u bodiju saljemo sql koji je vec pokupio i form
                fetch(API + `/` + buttonType + `/print/${companyCode}`, {
                    method: 'POST',
                    body: JSON.stringify({ sql: data.sql, form: form }),
                    responseType: 'blob',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => response.json())
                    .then(data => {
                        console.log(data, 'data')
                        window.open(API + data.link)
                        setSpinner(false)
// treba prebaciti fetch na dugme
 //                       let link = API + data.link;
  //                      res.status(200).send({ link })
                        // console.log(report, 'report')
                        //   console.log(data, 'data')
                        //   console.log(API, 'API', data.link, 'data.link')

                // let link = '/uploads/' + name
                // res.status(200).send({ link })
                   })


             //   fetch(API + `/pregledPoVrstiIKomitentu/print/${companyCode}`, {

        //             fetch(API + `/prikazPoVrstiKnjizenja/print/${companyCode}`, {
        //             method: 'POST',
        //             body: JSON.stringify({ podaci: data.items }),
        //             responseType: 'blob',
        //             headers: {
        //                 'Authorization': `Bearer ${token}`,
        //                 'Access- Control - Allow - Origin': 'http://127.0.0.1:3005',
        //                 'Access - Control - Allow - Methods': 'POST',
        //                 //'Access- Control - Allow - Headers': 'Content - Type',
        //                 'Content-type': 'application/json; charset=UTF-8'
        //             },
        //         }) 
        //         .then((response) => response.json())
        // .then(data => {
        //     window.open(API + data.link)
        // });
});
    }



const getClientsData = () => {
    fetch(API + `/client/all/${companyCode}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8'
        },
    })
        .then((response) => response.json())
        .then(data => {
            setSelectDataClients(data)
        })
}

const getKontoData = () => {
    fetch(API + `/konto/all`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8'
        },
    })
        .then((response) => response.json())
        .then(data => {
            setSelectDataKonto(data)
        })
}

const getVrstaData = () => {
    fetch(API + `/dokument/all`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8'
        },
    })
        .then((response) => response.json())
        .then(data => {
            setSelectDataVrsta(data)
        })
}



useEffect(() => {
    getClientsData()
}, [!selectDataClients])

useEffect(() => {
    getKontoData()
    // ako nije popunjeno
}, [!selectDataKonto])

useEffect(() => {
    getVrstaData()
    // ako nije popunjeno
}, [!selectDataVrsta])

useEffect(() => {
    getReports(1, perPage)
    // osvjezava mrezu svaki put kad promijenimo neko od datih polja
}, [form.VRSTA || form.KOMTID || form.KONTID || form.DATUMOD || form.DATUMDO])

useEffect(() => {
    getReports(1, perPage)
    // ako je dugme popunjeno,poziva funkciju getReports
}, [buttonOnClick])


useEffect(() => {
    getReports(1, perPage)
    //klikom na dugme za izvjestaj, prikazuje mrezu i osvjezava svaki put kad se klikne na dugme
}, [buttonType || buttonTypeSin && prikazPoVrstiKnjizenja])

// cekiranje radio dugmadi u zavisnosti odabrane opcije
const radioChangeHandler = (e) => {
    if (e.target.name === 'checkDatDok') {
        setDate({
            // odabir datuma dokumenata
            checkDatDok: true,
            checkDatNalog: false
        });
    } else {
        setDate({
            checkDatDok: false,
            // odabir datuma storniranja
            checkDatNalog: true
        });
    }
};

const columns = [

    {
        name: 'Vrsta',
        selector: row => row.VRSTA,
        width: '100px',
    },
    {
        name: 'Nalog',
        selector: row => row.NALOG,
        width: '100px',
    },
    {
        name: 'Red',
        selector: row => row.RED,
        width: '100px',
    },
    {
        name: 'Dat.Nal',
        selector: row => moment(row.DATN).format('DD.MM.YYYY'),
        width: '120px',
    },
    {
        name: 'Dat.Dok',
        selector: row => moment(row.DATD).format('DD.MM.YYYY'),
        width: '120px',
    },
    {
        name: 'Valuta',
        selector: row => moment(row.VALUTA).format('DD.MM.YYYY'),
        width: '120px',
    },
    {
        name: 'Šifra',
        selector: row => row.komtSifra,
        width: '100px',
    },
    {
        name: 'KONTO',
        selector: row => row.KONTO,
        width: '100px',
    },
    {
        name: 'Opis',
        selector: row => row.OPIS,
        width: '300px',
    },
    {
        name: 'Duguje',
        selector: row => row.DUGUJE,
        width: '200px',
        right: 'boolean',
        cell: (row) => (
            <>
                <span id='media-query-rows'>{numberWithCommasColumns(row.DUGUJE)}</span>
            </>
        ),
    },
    {
        name: 'Potražuje',
        selector: row => row.POTRAZ,
        width: '200px',
        right: 'boolean',
        cell: (row) => (
            <>
                <span id='media-query-rows'>{numberWithCommasColumns(row.POTRAZ)}</span>
            </>
        ),
    },
    {
        name: 'Saldo',
        selector: row => row.SALDO,
        width: '200px',
        right: 'boolean',
        cell: (row) => (
            <>
                <span id='media-query-rows'>{numberWithCommasColumns(row.SALDO)}</span>
            </>
        ),
    },

];

const columnsSin = [
    {
        name: 'Vrsta',
        selector: row => row.VRSTA,
        width: '100px',
    },
    {
        name: 'Naziv knjiženja',
        selector: row => row.NAZIV_KNJIZENJA,
        width: '350px',
    },
    {
        name: 'Šifra',
        selector: row => row.komtSifra,
        width: '100px',
    },
    {
        name: 'Naziv komitenta',
        selector: row => row.NAZIV_KOMITENTA,
        width: '350px',
    },
    {
        name: 'Duguje',
        selector: row => row.DUGUJE,
        width: '200px',
        right: 'boolean',
        cell: (row) => (
            <>
                <span id='media-query-rows'>{numberWithCommasColumns(row.DUGUJE)}</span>
            </>
        ),
    },
    {
        name: 'Potražuje',
        selector: row => row.POTRAZ,
        width: '200px',
        right: 'boolean',
        cell: (row) => (
            <>
                <span id='media-query-rows'>{numberWithCommasColumns(row.POTRAZ)}</span>
            </>
        ),
    },
    {
        name: 'Saldo',
        selector: row => row.SALDO,
        width: '200px',
        right: 'boolean',
        cell: (row) => (
            <>
                <span id='media-query-rows'>{numberWithCommasColumns(row.SALDO)}</span>
            </>
        ),
    },
]


const handlePageChange = page => {
    getReports(page, perPage)
}

const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
}


const defaultPropsClients = {
    options: selectDataClients,
    getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
};

const defaultPropsKonto = {
    options: selectDataKonto,
    getOptionLabel: (option) => option ? `${option.KONTO} ${option.NAZ}` : '',
};

const defaultPropsVrsta = {
    options: selectDataVrsta,
    getOptionLabel: (option) => option ? `${option.VRSTAD} ${option.OPISD}` : '',
};

return (

    <div>
        <div className="title-container">

            <header className="title-header">
                <h1 id="media-query-title">Prikaz po vrsti knjiženja</h1>
            </header>


            <div >
                <div>
                    <ul >
                        <input type="image" className='dugme-stampa' title='Štampa' tabIndex="7" src={require('../../assets/images/printer.png')} onClick={print} disabled={spinner} />

                    </ul>
                </div>
            </div>
        </div>
        <Container fluid>
            <div className="row">
                <Col lg='1'>


                    <div className='form-field-container' style={{ display: 'contents' }}>
                        {/* red sirine */}
                        <Col lg='12'>
                            {/* definsanje froma za radio dugmad */}
                            <div className="col-sm radio-box" id='media-query-radioButton-date'>
                                <RadioButton
                                    changed={(e) => radioChangeHandler(e)}
                                    isSelected={date?.checkDatDok}
                                    label="Dat.dok."
                                    id="1"
                                    value={date?.checkDatDok}
                                    name="checkDatDok"
                                />
                                {/*  radio dugme za datum storniranja   */}
                                <RadioButton
                                    changed={(e) => radioChangeHandler(e)}
                                    isSelected={date?.checkDatNalog}
                                    label="Dat.naloga"
                                    id="2"
                                    value={date?.checkDatNalog}
                                    name="checkDatNalog"
                                />

                            </div>


                        </Col>
                    </div>
                </Col>


                <Col lg='5'>

                    <div class="col-12" style={{ display: 'flex' }}>
                        <div class="col-12">
                            <div class="form-group row label-box-div">
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-finReport">Vrsta:</label>
                                <div class="col-10">
                                    <Autocomplete
                                        {...defaultPropsVrsta}
                                        value={vrsta}
                                        disablePortal={true}
                                        onChange={(event, newValue) => {
                                            setForm(prev => ({ ...prev, VRSTA: newValue && newValue != null ? newValue.VRSTAD : null }))
                                            setVrsta(newValue);
                                        }}

                                        renderInput={(params) => (
                                            <TextField {...params} className="clients-textinput" autoFocus required placeholder=' Izaberite vrstu' variant="standard" />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" style={{ display: 'flex' }}>
                        <div class="col-12">
                            <div class="form-group row label-box-div">
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-finReport">Komitent:</label>
                                <div class="col-10">
                                    <Autocomplete
                                        {...defaultPropsClients}
                                        value={clients}
                                        disablePortal={true}
                                        onChange={(event, newValue) => {
                                            setClients(newValue);
                                        }}

                                        renderInput={(params) => (
                                            <TextField {...params} className="clients-textinput" required placeholder=' Izaberite komitenta' variant="standard" />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" style={{ display: 'flex' }}>
                        <div class="col-12">
                            <div class="form-group row label-box-div">
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-finReport">Konto:</label>
                                <div class="col-10">
                                    <Autocomplete
                                        {...defaultPropsKonto}
                                        value={konto}
                                        disablePortal={true}
                                        onChange={(event, newValue) => {
                                            setKonto(newValue);
                                        }}

                                        renderInput={(params) => (
                                            <TextField {...params} className="clients-textinput" required placeholder=' Izaberite konto' variant="standard" />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" style={{ display: 'flex', }}>
                        <div class="col-12">

                            <div class="form-group row label-box-div">
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-finReport">Datum od:</label>
                                <div class="col-4" style={{ marginRight: 'auto' }}>
                                    <DatePicker tabIndex="5" id="media-query-input-finReport" dateFormat="dd.MM.yyyy" selected={startDate} onChange={(date) => setStartDate(date)} />
                                </div>



                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-to-finReport">Datum do:</label>
                                <div class="col-4" style={{ marginRight: 'auto' }}>
                                    <DatePicker tabIndex="6" id="media-query-input-to-finReport" dateFormat="dd.MM.yyyy" selected={endDate} onChange={(date) => setEndDate(date)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg='6'>
                    <Row>
                        <Col lg='12' >
                            <div className=" flex-lg-row justify-content-end" id='media-query-buttonType'>
                                <div style={{ display: 'inline-grid' }} className="btn-report">
                                    <button onClick={() => {
                                        setButtonType(null)
                                        setButtonTypeSin(null)
                                        setButtonType('pregledPoVrsti')
                                        getReports(1, perPage)
                                        setButtonOnClick(!buttonOnClick)
                                    }} > Pregled po vrsti knjiženja</button>
                                    <button onClick={() => {
                                        setButtonType(null)
                                        setButtonTypeSin(null)
                                        setButtonType('pregledPoVrstiIKomitentu')
                                        getReports(1, perPage)
                                        setButtonOnClick(!buttonOnClick)
                                    }} >Pregled po vrsti i komitentu</button>
                                </div>
                                <div style={{ display: 'inline-grid' }} className="btn-report">
                                    <button onClick={() => {
                                        setButtonType(null)
                                        setButtonTypeSin(null)
                                        setButtonTypeSin('totalPoVrsti')
                                        getReports(1, perPage)
                                        setButtonOnClick(!buttonOnClick)
                                    }
                                    }>Total po vrsti knjiženja</button>
                                    <button onClick={() => {
                                        setButtonType(null)
                                        setButtonTypeSin(null)
                                        setButtonTypeSin('totalPoVrstiIKomitentu')
                                        getReports(1, perPage)
                                        setButtonOnClick(!buttonOnClick)
                                    }
                                    }>Total po vrsti i komitentu</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>

            </div>
        </Container >

        {
            < DataTable
                columns={buttonType ? columns : columnsSin}
                data={prikazPoVrstiKnjizenja ? prikazPoVrstiKnjizenja : []}
                pagination
                paginationServer
                paginationTotalRows={total}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                responsive
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
            />
        }
        <div className="naslov-ikonice">
            <ul className="nav flex-column flex-lg-row justify-content-end">
                <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>

                    <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '4px' }}>
                        <div className='col-form-right'>
                            <div className="field-container-number">
                                <label id="media-query-label-sum">Duguje</label>
                                <input className="form-control" readOnly={true} type='text' id="media-query-input-text" value={sumduguje && sumduguje != 'NaN' ? numberWithCommas(sumduguje) : 0} />
                            </div>
                        </div>
                    </li>

                    <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '7px' }}>
                        <div className='col-form-right'>
                            <div className="field-container-number">
                                <label id="media-query-label-sum">Potražuje</label>
                                <input type='text' className="form-control" readOnly={true} id="media-query-input-text" value={sumpotraz && sumpotraz != 'NaN' ? numberWithCommas(sumpotraz) : 0} />
                            </div>
                        </div>
                    </li>

                    <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '5px' }}>
                        <div className='col-form-right'>
                            <div className="field-container-number">
                                <label id="media-query-label-sum">Saldo</label>
                                <input className="total" readOnly={true} type='text' id="media-query-input-text" value={sum && sum != 'NaN' ? numberWithCommas(sum) : 0} />
                            </div>
                        </div>
                    </li>

                </div>
            </ul>
        </div>
        {/* spiner ili progres bar ya prikaz pokretanja datog izvjestaja */}
        <div>
            {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
        </div>

    </div >
);

}

export default PrikazPoVrstiKnjizenja;


