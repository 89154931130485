import React, { useEffect, useState } from 'react';
import { Container, Row, Col,  Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API } from '../../constants';
import { RadioButton } from '../radioButton';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Spinner } from "reactstrap";
import {numberWithCommasColumns} from '../utilities'
import {numberWithCommas} from '../utilities';


function FinBrutoBilans1() {
    const [finBrutoBilans1, setFinBrutoBilans1] = useState(null);
    const [total, setTotal] = useState(0)
    const [sumduguje, setSumDuguje] = useState(0)
    const [sumpotraz, setSumPotraz] = useState(0)
    const [sum, setSum] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [repository, setRepository] = useState(null)
    const [clients, setClients] = useState(null);
    const [konto, setKonto] = useState(null);
    const [selectData, setSelectData] = useState([]);
    const [selectDataClients, setSelectDataClients] = useState([]);
    const [selectDataKonto, setSelectDataKonto] = useState([]);
    const [selectDataAccount, setSelectDataAccount] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [modalErrorOpenPrint, setModalErrorOpenPrint] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [buttonOnClick, setButtonOnClick] = useState(false);
    const [buttonType, setButtonType] = useState(null);
    const [buttonTypeSin, setButtonTypeSin] = useState(null)

    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null
    const companyNaziv = typeof window !== 'undefined' ? localStorage.getItem('companyNaziv') : null
    let year = typeof window !== 'undefined' ? localStorage.getItem('companyYear') : null

    // dodaje datum od i datum do. O pocetka godine do danasnjeg dana new Date() ili do kraja godine new Date(currentDate.getFullYear(), 0, 0)
    let dateFrom
    let dateTo
    let currentDate = new Date()
    if (currentDate.getFullYear() === Number(year)) {
        dateFrom = new Date(currentDate.getFullYear(), 0, 1);
        dateTo = new Date()
    } else {
        let oldYear = new Date()
        oldYear = new Date(Number(oldYear.setFullYear(year)))
        dateFrom = new Date(oldYear.getFullYear(), 0, 1);
        dateTo = new Date(Number(oldYear.setFullYear(year)))
    }
    // error row inputs
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [date, setDate] = useState({
        checkDate: true,
        checkDateCancelled: false
    });

    // error messages inputs
    const [errorMsgCode, setErrorMsgCode] = useState('');
    const [errorMsgName, setErrorMsgName] = useState('');

    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };


    const [form, setForm] = useState({
        //    KOMTID: '',
        KONTID: '',
        DATUMOD: dateFrom,
        DATUMDO: dateTo,
        DATUM: '',
    })

    const [startDate, setStartDate] = useState(dateFrom);
    const [endDate, setEndDate] = useState(dateTo);

    const getReports = (page, limit) => {
        if (buttonType || buttonTypeSin) {
            if (form) {
                form.DATUMOD = startDate
                form.DATUMDO = endDate
                //              form.KOMTID = clients && clients.ID ? clients.ID : null
                form.KONTID = konto && konto.ID ? konto.ID : null
                //                      form.radioDatumDok = date?.checkDate
                //                      form.radioDatumNal = date?.checkDateCancelled
                //   form.DATN = setDate.DATN
                //    form.DATD = setDate.DATD
                form.buttonType = buttonType ? buttonType : null
                form.buttonTypeSin = buttonTypeSin ? buttonTypeSin : null

            }


            fetch(API + `/finBrutoBilans1/all/${page}/${limit}/${companyCode}`, {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    if (data && data.items && data.items.length !== 0) {
                    setFinBrutoBilans1(data.items)
                    setTotal(data.total)
                    setSum(data.sum)
                    setSumDuguje(data.sumduguje)
                    setSumPotraz(data.sumpotraz)
                     } else {
                    setModalErrorOpenData(true)
                }
                })
        }
    }

    const print = () => {
        setSpinner(true)
        if (form) {
            form.NAZIV_PRED = companyNaziv
            form.DATUMOD = startDate
            form.DATUMDO = endDate
            form.KONTID = konto && konto.ID ? konto.ID : null
            form.buttonType = buttonType ? buttonType : null
            form.buttonTypeSin = buttonTypeSin ? buttonTypeSin : null

        }
        // if (buttonType && buttonType === null || buttonTypeSin && buttonTypeSin === null && finBrutoBilans.length === 0) {
        //     setModalErrorOpenPrint(true)
        //     // zatvaranje spinera
        //     setSpinner(false)
        //     console.log('upada')
        // }

        fetch(API + `/finBrutoBilans1/print/${companyCode}`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                // if (data && data.items && data.items.length !== 0) {
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            window.open(API + data.link)
                        }, 500);

                    }
                // } else {
                //     setModalErrorOpenData(true)
                // }
                // zatvaranje spinera
                setSpinner(false)
            })
    }


    const getKontoData = () => {
        fetch(API + `/konto/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataKonto(data)
            })
    }

    useEffect(() => {
        getKontoData()
        // ako nije popunjeno
    }, [!selectDataKonto])

    useEffect(() => {
        getReports(1, perPage)
        // osvjezava mrezu svaki put kad promijenimo neko od datih polja
    }, [konto || form.DATUMOD || form.DATUMDO && finBrutoBilans1])

    useEffect(() => {
        getReports(1, perPage)
        //klikom na dugme za izvjestaj, prikazuje mrezu i osvjezava svaki put kad se klikne na dugme
    }, [buttonType || buttonTypeSin])

    useEffect(() => {
        getReports(1, perPage)
        // ako je dugme popunjeno,poziva funkciju getReports
    }, [buttonOnClick])


    // cekiranje radio dugmadi u zavisnosti odabrane opcije
    const radioChangeHandler = (e) => {
        if (e.target.name === 'checkDate') {
            setDate({
                // odabir datuma dokumenata
                checkDate: true,
                checkDateCancelled: false
            });
        } else {
            setDate({
                checkDate: false,
                // odabir datuma storniranja
                checkDateCancelled: true
            });
        }
    };


    // const handleChange = (event) => {
    //     const target = event.target;
    //     let value = target.value;
    //     const name = event.target.name;

    //     setForm((prevState) => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    // };

    const columns = [
        {
            name: 'VRSTA',
            selector: row => row.VRSTA,
            width: '150px',
        },
        {
            name: 'KONTO',
            selector: row => row.KONTO,
            width: '150px',
        },
        {
            name: 'KONTO_NAZIV',
            selector: row => row.KONTO_NAZIV,
            width: '650px',
        },
        {
            name: 'Duguje',
            selector: row => row.SUMDUGUJE,
            width: '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.SUMDUGUJE)}</span>
                </>
            ),
        },
        {
            name: 'Potražuje',
            selector: row => row.SUMPOTRAZ,
            width: '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.SUMPOTRAZ)}</span>
                </>
            ),
        },

        {
            name: 'Ukupno',
            selector: row => row.SUMUKUPNO,
            width: '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.SUMUKUPNO)}</span>
                </>
            ),
        },
    ];

    const columnsSin = [
        // {
        //     name: 'VRSTA',
        //     selector: row => row.VRSTA,
        //     width: '150px',
        // },
        {
            name: 'KONTO',
            selector: row => row.KONTO,
            width: '100px',
        },
        {
            name: 'KONTO_NAZIV',
            selector: row => row.NAZIV,
            width: '400px',
        },
        {
            name: 'POC.DUGUJE',
            selector: row => row.POCETNO_DUGUJE,
            width: '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.POCETNO_DUGUJE)}</span>
                </>
            ),
        },
        {
            name: 'POC.POTRAZ',
            selector: row => row.POCETNO_POTRAZ,
            width: '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.POCETNO_POTRAZ)}</span>
                </>
            ),
        },
        {
            name: 'PROM.DUGUJE',
            selector: row => row.PROMET_DUGUJE,
            width: '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.PROMET_DUGUJE)}</span>
                </>
            ),
        },
        {
            name: 'PROM.POTRAZ',
            selector: row => row.PROMET_POTRAZ,
            width: '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.PROMET_POTRAZ)}</span>
                </>
            ),
        },

        {
            name: 'SALDO',
            selector: row => row.SALDO,
            width: '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{numberWithCommasColumns(row.SALDO)}</span>
                </>
            ),
        },
    ];

    const handlePageChange = page => {
        getReports(page, perPage)
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }


    // const defaultPropsClients = {
    //     options: selectDataClients,
    //     getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    // };

    const defaultPropsKonto = {
        options: selectDataKonto,
        getOptionLabel: (option) => option ? `${option.KONTO} ${option.NAZ}` : '',
    };

    return (

        <div>
            <div className="title-container">

                <header className="title-header">
                    <h1 id="media-query-title">Zaključni list</h1>
                </header>


                <div >
                    <div>
                        <ul >
                            <input type="image" className='dugme-stampa' title='Štampa' tabIndex="7" src={require('../../assets/images/printer.png')} onClick={print} disabled={spinner} />

                        </ul>
                    </div>
                </div>
            </div>
            <Container fluid>
                <div className="row">
                    <Col lg='1'>


                        <div className='form-field-container' style={{ display: 'contents' }}>
                            {/* red sirine */}
                            <Col lg='12'>
                                {/* definsanje froma za radio dugmad */}
                                <div className="col-sm radio-box" id='media-query-radioButton-date'>
                                    <RadioButton
                                        changed={(e) => radioChangeHandler(e)}
                                        isSelected={date?.checkDate}
                                        label="Dat.dok."
                                        id="1"
                                        value={date?.checkDate}
                                        name='checkDate'
                                    />
                                    {/*  radio dugme za datum storniranja   */}
                                    <RadioButton
                                        changed={(e) => radioChangeHandler(e)}
                                        isSelected={date?.checkDateCancelled}
                                        label="Dat.naloga"
                                        id="2"
                                        value={date?.checkDateCancelled}
                                        name='checkDateCancelled'
                                    />

                                </div>


                            </Col>
                        </div>
                    </Col>


                    <Col lg='5'>

                        <div class="col-12" style={{ display: 'flex' }}>
                            <div class="col-12">
                                <div class="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-finBrutoBilans">Konto:</label>
                                    <div class="col-10">
                                        <Autocomplete
                                            {...defaultPropsKonto}
                                            value={konto}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setKonto(newValue);
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params} className="clients-textinput" required placeholder=' Izaberite konto' variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}> */}

                        {/* <div class="col-12" style={{ display: 'flex', }}>
                            <div class="col-12">
                                <div class="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box">Datum od:</label>
                                    <div class="col-4">
                                        <DatePicker tabIndex="5" dateFormat="dd.MM.yyyy" selected={startDate} onChange={(date) => setStartDate(date)} />
                                        <div>

                                                    <label className="col-form-label col-lg-2 label-box">Datum do:</label>
                                                    <div class="col-4">
                                                    <DatePicker tabIndex="6" dateFormat="dd.MM.yyyy" selected={endDate} onChange={(date) => setEndDate(date)} />
                                             
                                                    </div>
                                        </div>
                                    </div>
                                </div> */}

                        <div class="col-12" style={{ display: 'flex', }}>
                            <div class="col-12">

                                <div class="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-finBrutoBilans">Datum od:</label>
                                    <div class="col-4" style={{ marginRight: 'auto' }}>
                                        <DatePicker tabIndex="5" id="media-query-input-finBrutoBilans" dateFormat="dd.MM.yyyy" selected={startDate} onChange={(date) => setStartDate(date)} />
                                    </div>



                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-to-finBrutoBilans">Datum do:</label>
                                    <div class="col-4" style={{ marginRight: 'auto' }}>
                                        <DatePicker tabIndex="6" id="media-query-input-to-finBrutoBilans" dateFormat="dd.MM.yyyy" selected={endDate} onChange={(date) => setEndDate(date)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg='6'>
                        <Row>
                            <Col lg='12' >
                                <div className=" flex-lg-row justify-content-end" id='media-query-buttonType'>
                                    <div style={{ display: 'inline-grid' }} className="btn-report">
                                        <button onClick={() => {
                                                  setButtonType(null)
                                                  setButtonTypeSin(null)
                                            setButtonType('pregledPoKontimaBilans1')
                                           getReports(1, perPage)
                                           setButtonOnClick(!buttonOnClick)
                                        }
                                        }>Pregled  bruto bilans1</button>
                                    </div>
                                    <div style={{ display: 'inline-grid' }} className="btn-report">
                                        <button onClick={() => {
                                                  setButtonType(null)
                                                  setButtonTypeSin(null)
                                            setButtonTypeSin('totalPoKontimaBilansSintetika1')
                                            getReports(1, perPage)
                                            setButtonOnClick(!buttonOnClick)
                                        }}>Zaključni list ( izvjestaj )</button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </div>
            </Container >

            {
                < DataTable
                    columns={buttonType ? columns : columnsSin}
                    data={finBrutoBilans1 ? finBrutoBilans1 : []}
                    pagination
                    paginationServer
                    paginationTotalRows={total}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    responsive
                    striped
                    dense
                    pointerOnHover
                    paginationComponentOptions={defaultComponentOptions}
                />
            }

            <div>
                <Modal isOpen={modalErrorOpenPrint} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Izaberite izvještaj koji želite štampati!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenPrint(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={modalErrorOpenData} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div className="naslov-ikonice">
                <ul className="nav flex-column flex-lg-row justify-content-end">
                    <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>

                        <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '4px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Duguje</label>
                                    <input className="form-control" readOnly={true} type='text' id="media-query-input-text" value={sumduguje && sumduguje != 'NaN' ? numberWithCommas(sumduguje) : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '7px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Potražuje</label>
                                    <input type='text' className="form-control" readOnly={true} id="media-query-input-text" value={sumpotraz && sumpotraz != 'NaN' ? numberWithCommas(sumpotraz) : 0} />
                                </div>
                            </div>
                        </li>

                        <li className="nav-item toolbaritem" style={{ width: '21%', marginRight: '5px' }}>
                            <div className='col-form-right'>
                                <div className="field-container-number">
                                    <label id="media-query-label-sum">Saldo</label>
                                    <input className="total" readOnly={true} type='text' id="media-query-input-text" value={sum && sum != 'NaN' ? numberWithCommas(sum) : 0} />
                                </div>
                            </div>
                        </li>
                    </div>
                </ul>
            </div>
            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

        </div >
    );

}

export default FinBrutoBilans1;


