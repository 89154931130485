// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import axios from '../api/axios';
import { Spinner } from "reactstrap";
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import { NumericFormat } from 'react-number-format';
// komponenta koju kreiramo
function Konto() {
  // definisanje konstanti koje koristimo za fokusiranje polja
  const kontoRef = useRef();
  const nameRef = useRef();
  const kufkifRef = useRef();

  const [konto, setKonto] = useState(null)
  const [total, setTotal] = useState(0)
  const [perPage, setPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState([]);
  const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null

  const [errorInputKonto, setErrorInputKonto] = useState(false);
  const [errorInputName, setErrorInputName] = useState(false);
  const [errorMsgKonto, setErrorMsgKonto] = useState(false);
  const [errorMsgName, setErrorMsgName] = useState(false);
  const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const [form, setForm] = useState({
    KONTO: '',
    NAZ: '',
    KUFKIF: '',
    ENTITET: '',
    BROJKUF: '',
    OZNAKA: '',
    stavkaPronadjena: false,
  })
  // funkcija za povlacenje podataka o preduzecu
  getCompanyData(form)

  const onSubmit = () => {

    // uslov: ako nisu unesena obavezna polja, zaustavlja upis
    if (!unosIspravanDo('UPIS')) return;

    form.SIFKOR = form.userCode
    form.SIFRAP = form.companyCode

    fetch(API + '/konto/new', {
      method: 'POST',
      body: JSON.stringify(form),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then((response) => {
        response.json()
        setForm({
          KONTO: '',
          NAZ: '',
          KUFKIF: '',
          ENTITET: '',
          BROJKUF: '',
          OZNAKA: '',
          stavkaPronadjena: false
        })
        kontoRef.current.focus()
        getTableData(1, perPage)
      })
  }

  const getTableData = (page, limit) => {
    fetch(API + `/konto/all/${page}/${limit}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then((response) => response.json())
      .then(data => {
        setKonto(data.result)
        setTotal(data.total)
      })
  }

  const onDelete = (ID) => {
    fetch(API + '/konto/delete/' + ID, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then((response) => {
        response.json()
        setForm({
          KONTO: '',
          NAZ: '',
          KUFKIF: '',
          ENTITET: '',
          BROJKUF: '',
          OZNAKA: '',
          stavkaPronadjena: false
        })
        kontoRef.current.focus()
        getTableData(1, perPage)
      })
  }

  // funkcija koja se poziva klikom na dugme Edit
  // postavlja fokus na polje NAZIV 
  // popunjava polja podacima iz mreze
  const onEdit = (item) => {
    item.stavkaPronadjena = true
    setForm(item)
    // zaustavljanje fokusa(pauza za fokus dok ne odradi upis) 
    const timeout = setTimeout(() => {
      nameRef.current.focus()
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }

  // funkcija koja se poziva prilikom promjene vrijednosti u inputu
  const handleChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = event.target.name;

    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));

  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran konto, izbaci poruku, setuje crveni okvir i fokusira polje  
  // u suprotnom prolazi dalje
  const checkKontoRequired = (value) => {
    //    console.log(value, 'value')
    if (!value || value == 0) {
      setErrorMsgKonto('Konto je obavezan!')
      setErrorInputKonto(true)
      kontoRef.current.focus();
      return false
    } else {
      setErrorMsgKonto(null)
      setErrorInputKonto(false)
      return true
    }
  }

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran dokument, izbaci poruku, setuje crveni okvir i fokusira polje  
  // u suprotnom prolazi dalje
  const checkNameRequired = (value) => {
    if (!value || value == 0) {
      setErrorMsgName('Naziv je obavezan!')
      setErrorInputName(true)
      nameRef.current.focus();
      return false
    } else {
      setErrorMsgName(null)
      setErrorInputName(false)
      return true
    }
  }

  // funkcija za kontrolu obaveznih polja
  function unosIspravanDo(name) {
    if (name === 'KONTO') {
      return true
    }

    if (!checkKontoRequired(form.KONTO)) {
      return false
    }

    if (name === 'NAZ') {
      return true
    }

    if (!checkNameRequired(form.NAZ)) {
      return false
    }

    if (name === 'KUFKIF') {
      return true
    }

    return true
  }


  const columns = [
    {
      name: 'KONTO',
      selector: row => row.KONTO,
      width: '100px',
      cell: (row) => (
        <>
          <span id='media-query-rows'>{row.KONTO}</span>
        </>
      ),
    },
    {
      name: 'NAZ',
      selector: row => row.NAZ,
      cell: (row) => (
        <>
          <span id='media-query-rows'>{row.NAZ}</span>
        </>
      ),
    },
    {
      name: 'BROJKUF',
      selector: row => row.BROJKUF,
      right: 'boolean',
      cell: (row) => (
        <>
          <span id='media-query-rows'>{row.BROJKUF}</span>
        </>
      ),
    },
    {
      name: 'KUFKIF',
      selector: row => row.KUFKIF,
      cell: (row) => (
        <>
          <span id='media-query-rows'>{row.KUFKIF}</span>
        </>
      ),
    },
    {
      name: 'ENTITET',
      selector: row => row.ENTITET,
      cell: (row) => (
        <>
          <span id='media-query-rows'>{row.ENTITET}</span>
        </>
      ),
    },
    {
      name: 'OZNAKA',
      selector: row => row.OZNAKA,
      cell: (row) => (
        <>
          <span id='media-query-rows'>{row.OZNAKA}</span>
        </>
      ),
    },
    {
      name: 'Action',
      cell: (row) => (
        <>
          <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav naslov-ikonice flex-column flex-lg-row justify-content-end'>Edit</span>{'     '}
          <span onClick={() => onDelete(row.ID)} id='media-query-table-buttons' className='btn btn-danger naslov-ikonice nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
        </>

      ),

    }
  ];

  const onPrint = () => {
    setSpinner(true)

    // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
    // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
    // poziva api za stampu, otvara izvjestaj(API + data.link)
    if (form) {
      form.NAZIV_PRED = form.companyName
    }
    fetch(API + '/konto/print', {
      method: 'POST',
      body: JSON.stringify(form),
      responseType: 'blob',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then((response) => response.json())
      .then(data => {
        if (typeof window != 'undefined') {
          setTimeout(() => {
            window.open(API + data.link)
          }, 200);
        }
        setSpinner(false)
      })
  }

  // funkcija se poziva prilikom promjene stranice u tabeli stranica 1,2,3 ...
  const handlePageChange = page => {
    getTableData(page, perPage)
  }

  // funkcija se poziva prilikom promjene broja ispisa u tabeli 10, 15, 30 ...
  const handlePerRowsChange = async (newPerPage, page) => {
    if (perPage != newPerPage) {
      setPerPage(newPerPage)
      getTableData(1, newPerPage)
      kontoRef.current.focus()
    }
  }

  // funkcija za pretragu iz mreze
  // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
  // u responsu vraca podatke koje dobio iz upita 
  const searchChange = (e) => {
    e.preventDefault();
    const searchInput = e.target.value
    setSearchInput(searchInput);
    const fetchData = async () => {
      const res = await axios.get(`${API}/konto/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
      setKonto(res.data);
    };
    if (searchInput.length === 0 || searchInput.length >= 1) fetchData();

  };

  return (

    <div>
      <div className="title-container">

        <header className="title-header">
          <h1 id="media-query-title">Konta</h1>
        </header>


        <div className="naslov-ikonice">
          <ul className="nav flex-column flex-lg-row justify-content-end">

            <li className="nav-item toolbaritem">
              <input type="image" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="7" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
            </li>

            <li className="nav-item toolbaritem">
              <input type="image" className='dugme-stampa' title='Štampa' tabIndex="8" src={require('./../assets/images/printer.png')} onClick={onPrint} />
            </li>

          </ul>
        </div>
      </div>
      <Container fluid>
        <Row>
          <div className='products-form'>
            <Row>
              <div className="col-6" style={{ display: 'flex', marginBottom: '0px', width: '60%' }}>
                <div className="col-1">
                  <div className={errorInputKonto ? 'my-input' : 'field-container'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                    <label id="media-query-label">Konto</label>
                    <input type="text"
                      id="media-query-input-text"
                      maxLength="8"
                      placeholder="Konto"
                      tabIndex="1"
                      name='KONTO'
                      value={form?.KONTO}
                      autoFocus={true}
                      ref={kontoRef}
                      onFocus={() => {
                        unosIspravanDo("KONTO")
                      }}
                      //  onBlur={(e) => formCheck(e)}
                      onChange={(e) => handleChange(e)}
                      onClick={() => {
                        // ako je odradjen dvoklik na stavku u mrezi, cisti polja  
                        setForm(prev => ({
                          ...prev, KONTO: '',
                          NAZ: '',
                          KUFKIF: '',
                          ENTITET: '',
                          BROJKUF: '',
                          OZNAKA: '', stavkaPronadjena: false
                        }))
                      }} />
                    {errorMsgKonto ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgKonto}</span> : ''}
                  </div>
                </div>

                <div className="col-6">
                  <div className={errorInputName ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                    <label id="media-query-label">Naziv</label>
                    <input type="text"
                      id="media-query-input-text"
                      maxLength="50"
                      placeholder="naziv"
                      ref={nameRef}
                      tabIndex="2"
                      name='NAZ'
                      value={form ? form.NAZ : ''}
                      onFocus={() => unosIspravanDo("NAZ")}
                      //  onBlur={(e) => formCheck(e)}
                      onChange={(e) => handleChange(e)} />
                    {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}

                  </div>
                </div>

                <div className="col-1">
                  <div className='field-container' style={{ marginRight: '3px' }}>
                    <label id="media-query-label">Kuf/kif</label>
                    <input type="text" id="media-query-input-text" maxLength="2" placeholder="kufkif" tabIndex="3" name='KUFKIF' value={form ? form.KUFKIF : ''} onFocus={() => unosIspravanDo("KUFKIF")} onChange={(e) => handleChange(e)} />
                  </div>
                </div>

                <div className="col-1">
                  <div className='field-container' style={{ marginRight: '3px' }}>
                    <label id="media-query-label">Entitet</label>
                    <input type="text" id="media-query-input-text" maxLength="3" placeholder="entitet" tabIndex="4" name='ENTITET' value={form ? form.ENTITET : ''} onChange={(e) => handleChange(e)} />
                  </div>
                </div>

                <div className="col-2">
                  <div className='col-form-right'>
                    <div className='field-container-number' style={{ marginRight: '3px' }}>
                      <label id="media-query-label">Br. Kuf/kif</label>
                      <NumericFormat
                        type="number"
                        getInputRef={kufkifRef}
                        maxLength="2"
                        decimalScale={0}
                        id="media-query-input-text"
                        placeholder="Broj kufa/kifa"
                        tabIndex="5"
                        name='BROJKUF'
                        value={form ? form.BROJKUF : ''}
                        onChange={(e) => handleChange(e)} />
                    </div>
                  </div>
                  {/* <button onClick={onSubmit} style={{ marginTop: '29px' }} className='button-dark btn-md' type='submit'>Submit</button> */}
                </div>

                <div className="col-1">
                  <div className='field-container' style={{ marginRight: '3px' }}>
                    <label id="media-query-label">K.obavezan</label>
                    <select value={form?.OZNAKA}
                      id="media-query-input-text"
                      name='OZNAKA'
                      className='select-style'
                      placeholder="komit. obavezan"
                      onChange={(e) => handleChange(e)}
                      tabIndex="6">
                      <option value={''}>{''}</option>
                      <option value={'N'}>{'Ne'}</option>
                      <option value={'D'}>{'Da'}</option>
                    </select>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Row>
      </Container>

      <div style={{ display: 'flex' }} className='search'>
        <input type='text' id="media-query-input-text" placeholder='Pretraga...'
          onChange={(e) => {
            // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
            if (searchInput.length >= 0) {
              searchChange(e)
            } else {
              getTableData(1, perPage)
            }
          }}
          value={searchInput} />

        {/* // <img src={Pencil} className='search-icon' /> */}
      </div>

      <DataTable
        columns={columns}
        data={konto ? konto : []}
        pagination
        paginationServer
        paginationTotalRows={total}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        responsive
        striped
        dense
        pointerOnHover
        paginationComponentOptions={defaultComponentOptions}
      />

      <div>
        <Modal isOpen={Boolean(modalErrorOpenData)} centered  >
          <ModalHeader>Nema podataka!</ModalHeader>
          <ModalFooter>
            <div>
              {/* Dugme za zatvaranje modalnog forma */}
              <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
      </div>

    </div>
  );

}

export default Konto;


