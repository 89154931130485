// importovanje komponenti
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import exportIcon from '../assets/export.svg';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from '../api/axios';
// komponenta koju kreiramo

function Poslovna() {
    // definisanje konstanti koje koristimo za fokusiranje polja
    const codeRef = useRef();
    const nameRef = useRef();
    const clientsRef = useRef()
    const placesRef = useRef()
    const commercialistRef = useRef()
    const valutaRef = useRef()
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [poslovna, setPoslovna] = useState(null)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [clients, setClients] = useState(null);
    const [places, setPlaces] = useState(null);
    const [valuta, setValuta] = useState(null);
    const [commercialist, setCommercialist] = useState(null);
    const [selectData, setSelectData] = useState([]);
    const [selectDataKomt, setSelectDataKomt] = useState([]);
    const [selectDataPlaces, setSelectDataPlaces] = useState([]);
    const [selectDataValuta, setSelectDataValuta] = useState([]);
    const [searchInput, setSearchInput] = useState([]);
    // error row inputs
    const [errorInputClient, setErrorInputClient] = useState(false);
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorInputPlaces, setErrorInputPlaces] = useState(false);
    // error messages inputs
    const [errorMsgClient, setErrorMsgClient] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgPlaces, setErrorMsgPlaces] = useState(false);


    const [codeFocus, setCodeFocus] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [editClient, setEditClient] = useState(false);
    const [placesFocus, setPlacesFocus] = useState(false);
    const [clientsFocus, setClientsFocus] = useState(false);
    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    //  const poslovnaCode = typeof window !== 'undefined' ? localStorage.getItem('poslovna') : null
    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null
    const companyNaziv = typeof window !== 'undefined' ? localStorage.getItem('companyNaziv') : null
    // niz prema kome se formira pagination u mrezi(pri kreiranju 'DataTable')
    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };
    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija

    const [form, setForm] = useState({
        KOMTID: 0,
        SIFRA: 0,
        NAZIV: '',
        MESTO: 0,
        ADRESA: '',
        TEL: '',
        IDENTBROJ: '',
        PUTNIK: 0,
        VALUTA: 0,

    })

    const [firstCode, setfirstCode] = useState(null)
    // funkcija koja se poziva klikom na dugme 'Sacuvaj'

    const onSubmit = () => {
        // provjera obaveznih polja na dugmetu
        // ukoliko nisu popunjena obavezna polja, zaustavlja akciju i prikazuje upozorenje(poruka)
        // ukoliko su popunjena obavezna polja, upada u else i izvrsava se funkcija

        if (form.KOMTID == " " || form.KOMTID == 0 || form.SIFRA == " " || form.SIFRA == 0 || form.NAZIV == " " || form.NAZIV == 0 || form.MESTO == " " || form.MESTO == 0) {
            setModalErrorOpen(true)
            nameRef.current.focus();
        } else {
            // if (form && form.PDV.trim() == '') {
            //     form.PDV = 'N'
            // }
            if (form && (form.ID && editClient)) {
                console.log(form.ID);
                fetch(API + `/poslovna/update`, {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            KOMTID: 0,
                            SIFRA: 0,
                            NAZIV: '',
                            MESTO: 0,
                            ADRESA: '',
                            TEL: '',
                            IDENTBROJ: '',
                            PUTNIK: 0,
                            VALUTA: 0,
                        })
                        setClients(null)
                        setPlaces(null)
                        setCommercialist(null)
                        setValuta(null)
                        codeRef.current.focus()
                        getTableData(1, perPage)
                    })

                console.log('prom');
            }
            // u suprotnom ide na upis sloga
            // poziva api za upis
            // osvezavanje polja, fokus na polje SIFRAP, poziva funkciju getTableData(za upis u mrezu)
            else {
                fetch(API + `/poslovna/new`, {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            KOMTID: 0,
                            SIFRA: 0,
                            NAZIV: '',
                            MESTO: 0,
                            ADRESA: '',
                            TEL: '',
                            IDENTBROJ: '',
                            PUTNIK: 0,
                            VALUTA: 0,

                        })
                        setClients(null)
                        setPlaces(null)
                        setCommercialist(null)
                        setValuta(null)
                        codeRef.current.focus()
                        getTableData(1, perPage)
                    })
                console.log('upada');
            }
        }
    }
    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setIme) i puni ukupan broj stranica(setTotal)

    const getTableData = (page, limit) => {
        fetch(API + `/poslovna/all/${page}/${limit}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                console.log(data)
                setPoslovna(data.result)
                setTotal(data.total)
                getPoslovnaFirstFreeNumberSIFRA()


            })
    }



    const getCommercialistData = () => {
        fetch(API + `/commercialist/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectData(data)
            })
    }

    const getKomtData = () => {
        fetch(API + `/client/all/${companyCode}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataKomt(data)
            })
    }

    const getPlacesData = () => {
        fetch(API + `/places/all/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataPlaces(data)
            })
    }

    const getValutaData = () => {
        fetch(API + `/valuta/all/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataValuta(data)
            })
    }

    // poziva se inicijalno pri otvaranju forma i poziva api za popunjavanje tabele
    // postavlja fokus na polje SIFRAP
    // prati promjenu state perPage 

    useEffect(() => {
        getTableData(1, perPage)
        codeRef.current.focus();
    }, [perPage])

    useEffect(() => {

    }, [poslovna])
    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje SIFRAP, osvjezava mrezu  
    const onDelete = (ID) => {
        fetch(API + '/poslovna/delete/' + ID, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    KOMTID: 0,
                    SIFRA: 0,
                    NAZIV: '',
                    MESTO: 0,
                    ADRESA: '',
                    TEL: '',
                    IDENTBROJ: '',
                    PUTNIK: 0,
                    VALUTA: 0,
                })
                setClients(null)
                setPlaces(null)
                setCommercialist(null)
                setValuta(null)
                getPoslovnaFirstFreeNumberSIFRA()
                codeRef.current.focus()
                getTableData(1, perPage)
            })
    }
    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze

    const onEdit = (item) => {
        nameRef.current.focus()
        setClients(null)
        setPlaces(null)
        setCommercialist(null)
        setValuta(null)
        selectDataKomt.map((client) => {
            if (client.ID == item.KOMTID) {
                setClients(client)
            }
        })
        selectDataPlaces.map((places) => {
            if (places.SIFRA == item.MESTO) {
                setPlaces(places)
            }
        })
        selectDataValuta.map((valuta) => {
            if (valuta.VALUTA == item.VALUTA) {
                setValuta(valuta)
            }
        })

        selectData.map((commercialist) => {
            if (commercialist.SIFRA == item.PUTNIK) {
                setCommercialist(commercialist)
            }
        })
        setForm(item)
        setEditClient(true)

    }
    // funkcija koja se poziva klikom na dugme 'Stampa'

    const onPrint = () => {
        // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
        // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
        // poziva api za stampu, otvara izvjestaj(API + data.link)
        if (form) {
            form.NAZIV_PRED = companyNaziv
        }
        fetch(API + `/poslovna/print/${companyNaziv}`, {
            method: 'POST',
            body: JSON.stringify(form),
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                console.log(data, 'DATA');
                if (typeof window != 'undefined') {
                    setTimeout(() => {
                        console.log(API + data.link, 'link');
                        window.open(API + data.link)
                    }, 500);

                }
            })
    }
    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // validacija broja i obaveznog polja
    const helperValidateOnlyNumbers = (input) => {
        if (input.replace(/^[0-9.]+$/, '').length > 0) {
            return 'Dozvoljeni su samo brojevi!';
        } else if (input.length < 1) {
            return 'Polje je obavezno!'
        } else {
            return false
        }

    }

    // poziva se inicijalno pri otvaranju forma i poziva api za popunjavanje tabele
    // postavlja fokus na polje 

    useEffect(() => {
        getPoslovnaFirstFreeNumberSIFRA()
    }, [!form.SIFRA])

    // funkcija za pronalazenje prvog slobodnog broja
    // poziva api
    // u responsu stejt setForm poziva funkciju za pronalazenje prethodne sifre(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna SIFRAP
    // setFirstCode - stejt koji se koristi za razlikovanje izmjene i upisa 

    const getPoslovnaFirstFreeNumberSIFRA = () => {
        fetch(API + `/poslovna/firstFreeNumberSifra`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, SIFRA: Number(data) }))
                // setfirstCode(Number(data))
            })
    }


    // validacija praznog polja
    const helperValidateEmptyField = (input) => {
        if (input.length < 1) return 'Polje je obavezno!';
    }

    // stepenasta
    const formCheck = (event) => {

        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        switch (name) {
            case 'SIFRA': {
                setErrorMsgCode(helperValidateEmptyField(value))
                setErrorInputCode(helperValidateEmptyField(value) ? true : false)
                break;
            }

            case 'NAZIV': {
                setErrorMsgName(helperValidateEmptyField(value))
                setErrorInputName(helperValidateEmptyField(value) ? true : false)
                break;
            }
            case 'KOMITENT': {
                setErrorMsgClient(helperValidateOnlyNumbers(value))
                setErrorInputClient(helperValidateOnlyNumbers(value) ? true : false)
                break;

            }

            case 'MJESTO': {
                setErrorMsgPlaces(helperValidateEmptyField(value))
                setErrorInputPlaces(helperValidateEmptyField(value) ? true : false)
                break;
            }


        }
        if (name === 'SIFRA') {
            setCodeFocus(false)
        }

        if (name === 'NAZIV') {
            setNameFocus(false)
        }
        if (name === 'KOMITENT') {
            setClientsFocus(false)
        }

        if (name === 'MESTO') {
            setPlacesFocus(false)
        }


    }



    useEffect(() => {
        if (codeRef.current && errorInputCode) {
            codeRef.current.focus();
        }
    }, [!codeFocus]);

    useEffect(() => {
        if (nameRef.current && errorInputName) {
            nameRef.current.focus();
        }
    }, [!nameFocus]);

    useEffect(() => {
        if (placesRef.current && errorInputPlaces) {
            placesRef.current.focus();
        }
    }, [!placesFocus]);

    useEffect(() => {
        if (clientsRef.current && errorInputClient) {
            clientsRef.current.focus();
        }
    }, [!clientsFocus]);

    useEffect(() => {
        getCommercialistData()
    }, [!selectData])

    useEffect(() => {
        getKomtData()
    }, [!selectDataKomt])
    useEffect(() => {
        getPlacesData()
    }, [!selectDataPlaces])
    useEffect(() => {
        getValutaData()
    }, [!selectDataValuta])


    // definisu se polja u tabeli i popunjavaju

    const columns = [
        {
            name: 'Šifra',
            selector: row => row.SIFRA,
            right: 'boolean',
            width: '80px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.SIFRA}</span>
                </>
            ),
        },
        {
            name: 'Komitent',
            selector: row => row.komtSifra,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.komtSifra}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'Mjesto',
            selector: row => row.mestoNaziv,
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.mestoNaziv}</span>
                </>
            ),
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{'     '}
                    <span onClick={() => onDelete(row.ID)} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>

            ),

        }
    ];

    // funkcija se poziva prilikom promjene stranice u tabeli stranica 1,2,3 ...
    const handlePageChange = page => {
        getTableData(page, perPage)
    }
    // funkcija se poziva prilikom promjene broja redova ispisa u tabeli 10, 15, 30 ...
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }


    // propsovi za auto komplit  PUTNIKA
    const defaultProps = {
        options: selectData,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };
    // propsovi za uvoznika 

    // propsovi za auto komplit  KOMT
    const defaultPropsClient = {
        options: selectDataKomt,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // MESTO

    const defaultPropsPlaces = {
        options: selectDataPlaces,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // valuta

    const defaultPropsValuta = {
        options: selectDataValuta,
        getOptionLabel: (option) => option ? `${option.VALUTA}` : '',
    };
    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 

    const searchChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        const fetchData = async () => {
            const res = await axios.get(`${API}/poslovna/query/${companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setPoslovna(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length > 2) fetchData();

    };
    // funkcija za brisanje pretrage klikom na dugme 'Ocisti'
    // osvjezava polje za pretragu
    // osvjezava mrezu

    const handleClear = () => {
        setSearchInput('');
        getTableData(1, perPage)
    }

    return (

        <div>
            {/* Kontejner za naslov  */}

            <div className="title-container">
                {/* Naziv registra */}
                <header className="title-header">
                    <h1 id="media-query-title">Poslovna</h1>
                </header>


                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li class="nav-item toolbaritem">
                            <input type="image" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="14" src={require('./../assets/images/floppydisk.png')} onFocus={onSubmit} />
                        </li>

                        <li class="nav-item toolbaritem">
                            <input type="image" className='dugme-stampa' title='Štampa' tabIndex="15" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                    </ul>
                </div>

            </div>
            {/* Kontejner pune sirine */}
            <Container fluid >
                <Row>
                    <div className='products-form'>
                        <Row>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className={errorInputCode ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Šifra:</label>
                                            <input type="number"
                                                id="media-query-input-text"
                                                tabIndex="2"
                                                autoFocus={true}
                                                placeholder="šifra"
                                                name='SIFRA'
                                                // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                                value={form ? form.SIFRA : ''}
                                                ref={codeRef}
                                                onFocus={() => setCodeFocus(true)}
                                                // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                                onBlur={(e) => formCheck(e)}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)} />
                                            {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                            {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}

                                        </div>
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-5">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Komitent:</label>
                                        {/* Ako je Autocomplete  poziva na odabri MESTO*/}
                                        <Autocomplete
                                            {...defaultPropsClient}
                                            value={clients}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, KOMTID: newValue && newValue != null ? newValue.ID : null }))
                                                setClients(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField id='media-query' {...params} className="clients-textinput" name='KOMTID' onFocus={() => setClientsFocus(true)} ref={clientsRef} tabIndex="1" onBlur={(e) => formCheck(e)} placeholder=' komitent' variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>

                                {/* Sirina polja */}

                                <div className="col-6">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputName ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Naziv:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="70"
                                            tabIndex="3"
                                            autoComplete='off'
                                            placeholder="naziv"
                                            name='NAZIV'
                                            value={form ? form.NAZIV : ''}
                                            ref={nameRef}
                                            // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                            onFocus={() => setNameFocus(true)}
                                            // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                            onBlur={(e) => formCheck(e)}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}

                                    </div>
                                </div>
                            </div>
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px', marginRight: 'auto', marginLeft: '0px' }}>

                                {/* Sirina polja */}

                                <div className="col-5">
                                    <div className={errorInputPlaces ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Mjesto:</label>
                                        <Autocomplete
                                            {...defaultPropsPlaces}
                                            value={places}
                                            onChange={(event, newValue) => {
                                                setPlaces(newValue);
                                                setForm(prev => ({ ...prev, MESTO: newValue && newValue != null ? newValue.SIFRA : null }))
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    name='MESTO'
                                                    onFocus={() => setPlacesFocus(true)}
                                                    ref={placesRef}

                                                    tabIndex={4}
                                                    autoComplete="on"
                                                    onBlur={(e) => formCheck(e)}
                                                    placeholder='Mjesto'
                                                    variant="standard" />
                                            )}
                                        />
                                        {errorMsgPlaces ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgPlaces}</span> : ''}
                                    </div>
                                </div>

                                <div className="col-7">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Adresa:</label>
                                        <input type="text" id="media-query-input-text" maxLength="70" tabIndex="5" placeholder="ADRESA" name='ADRESA' value={form ? form.ADRESA : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>

                        </Row>

                        <Row>
                            {/* Sirina reda */}
                            <div className="col-12" style={{ display: 'flex' }}>

                                <div className="col-3">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Telefon:</label>
                                        <input type="text" id="media-query-input-text" maxLength="70" tabIndex="6" placeholder="TELEFON" name='TEL' value={form ? form.TEL : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Putnik:</label>
                                        {/* Ako je Autocomplete  poziva na odabri mjesta*/}
                                        <Autocomplete
                                            {...defaultProps}
                                            value={commercialist}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, PUTNIK: newValue && newValue != null ? newValue.SIFRA : null }))
                                                setCommercialist(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField id='media-query' {...params} className="clients-textinput" name='PUTNIK' ref={commercialistRef} tabIndex="7" onBlur={(e) => formCheck(e)} placeholder=' putnik' variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-1">
                                    {/* Provjera  polja i stilova */}
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">JIB:</label>
                                        {/* Provjera unosa pola i duzine  */}
                                        <input type="text" id="media-query-input-text" maxLength="20" tabIndex="8" placeholder="JIB" name='IDENTBROJ' value={form ? form.IDENTBROJ : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className='field-container' style={{ marginRight: '3px' }}>
                                            <label id="media-query-label">Valuta:</label>
                                            <input type="number"
                                                id="media-query-input-text"
                                                maxLength="70"
                                                tabIndex="6"
                                                placeholder="valuta"
                                                name='VALUTA'
                                                value={form ? form.VALUTA : ''}
                                                onChange={(e) => handleChange(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Row>
                    </div>
                </Row >
            </Container >



            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => searchChange(e)} value={searchInput} />
                {/* dugme 'Ocisti'( poziva funkciju handleClear za brisanje) */}
                <button onClick={() => handleClear()} className='dugme-clear'>Očisti</button>
            </div>

            {/* Mreza */}
            <DataTable
                columns={columns}
                data={poslovna ? poslovna : []}
                pagination
                paginationServer
                paginationTotalRows={total}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
            />
            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}

            <div>
                <Modal isOpen={modalErrorOpen} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

        </div >
    );

}

export default Poslovna;


