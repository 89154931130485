import { Outlet } from "react-router-dom";
import React, { useState } from 'react';

import Sidebar from './sidebar'
import Navbar from "./navbar";

const Layout = ({ children }) => {
  const [inactive, setInactive] = useState(false);
  const [start, setStart] = useState(false);
  

  return (
    <>
      <div>
        <Sidebar
          onCollapse={(inactive) => {
          //  console.log(inactive);
            setInactive(inactive);
          }}
        />
      </div>
      <main>
        <div className={inactive ? 'page-content' : 'page-content-sidebar'}>
          {children}
          <Outlet/>

        </div>

      </main>
    </>
  )
}

export default Layout