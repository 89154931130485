// importovanje komponenti
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import exportIcon from '../assets/export.svg';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from '../api/axios';
// komponenta koju kreiramo

function Artikal() {
    // definisanje konstanti koje koristimo za fokusiranje polja
    const codeRef = useRef();
    const nameRef = useRef();
    const placeRef = useRef();
    const yearRef = useRef();
    const entityRef = useRef();
    const clientsRef = useRef()
    const taxRef = useRef()
    const grupaRef = useRef()
    const brendRef = useRef()
    const jmRef = useRef()
    const barKodRef = useRef()
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [artikal, setartikal] = useState(null)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [place, setPlace] = useState(null);
    const [clients, setClients] = useState(null);
    const [tax, setTax] = useState(null);
    const [grupa, setGrupa] = useState(null);
    const [brend, setBrend] = useState(null);
    const [selectData, setSelectData] = useState([]);
    const [selectDataKomt, setSelectDataKomt] = useState([]);
    const [selectDataPorez, setSelectDataPorez] = useState([]);
    const [selectDataGrupa, setSelectDataGrupa] = useState([]);
    const [searchInput, setSearchInput] = useState([]);
    // error row inputs
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorInputPlace, setErrorInputPlace] = useState(false);
    const [errorInputYear, setErrorInputYear] = useState(false);
    const [errorInputEntity, setErrorInputEntity] = useState(false);
    const [errorInputTax, setErrorInputTax] = useState(false);
    const [errorInputJM, setErrorInputJM] = useState(false);
    const [ErrorInputGrupa, setErrorInputGrupa] = useState(false);
    // error messages inputs
    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [errorMsgPlace, setErrorMsgPlace] = useState(false);
    const [errorMsgYear, setErrorMsgYear] = useState(false);
    const [errorMsgEntity, setErrorMsgEntity] = useState(false);
    const [errorMsgTax, setErrorMsgTax] = useState(false);
    const [errorMsgJM, setErrorMsgJM] = useState(false);
    const [errorMsgGrupa, setErrorMsgGrupa] = useState(false);


    const [codeFocus, setCodeFocus] = useState(false);
    const [nameFocus, setNameFocus] = useState(false);
    const [placeFocus, setPlaceFocus] = useState(false);
    const [yearFocus, setYearFocus] = useState(false);
    const [entityFocus, setEntityFocus] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [editClient, setEditClient] = useState(false);
    const [taxFocus, setTaxFocus] = useState(false);
    const [jmFocus, setJmFocus] = useState(false);
    const [grupaFocus, setGrupaFocus] = useState(false);
    const [brendFocus, setBrendFocus] = useState(false);
    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    //  const artikalCode = typeof window !== 'undefined' ? localStorage.getItem('artikal') : null
    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null
    const artikalNaziv = typeof window !== 'undefined' ? localStorage.getItem('artikalNaziv') : null
    // niz prema kome se formira pagination u mrezi(pri kreiranju 'DataTable')
    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };
    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija

    const [form, setForm] = useState({
        SIFRAP: companyCode,
        SIFRA: '',
        NAZIV: '',
        JM: '',
        KOMPAK: '',
        OZNAKA: '',
        UVOZNIKID: '',
        POREZID: '',
        BAR_KOD: '',
        GRUPAID: '',
        BREND: '',
        KATBROJ: '',
        FAB_BROJ: '',
        AKCIZA: '',

    })

    const [firstCode, setfirstCode] = useState(null)
    // funkcija koja se poziva klikom na dugme 'Sacuvaj'

    const onSubmit = () => {
        // provjera obaveznih polja na dugmetu
        // ukoliko nisu popunjena obavezna polja, zaustavlja akciju i prikazuje upozorenje(poruka)
        // ukoliko su popunjena obavezna polja, upada u else i izvrsava se funkcija

        if (form.SIFRA == " " || form.SIFRA == 0 || form.NAZIV == " " || form.NAZIV == 0 || form.JM == " " || form.JM == 0 || form.POREZID == " " || form.POREZID == 0) {
            setModalErrorOpen(true)
            nameRef.current.focus();
        } else {
            // if (form && form.PDV.trim() == '') {
            //     form.PDV = 'N'
            // }
            if (form && (form.ID && editClient)) {

                fetch(API + `/artikal/update/${companyCode}`, {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            SIFRAP: companyCode,
                            SIFRA: '',
                            NAZIV: '',
                            JM: '',
                            KOMPAK: '',
                            OZNAKA: '',
                            UVOZNIKID: '',
                            POREZID: '',
                            BAR_KOD: '',
                            GRUPAID: '',
                            BREND: '',
                            KATBROJ: '',
                            FAB_BROJ: '',
                            AKCIZA: ''
                        })
                        setClients(null)
                        setTax(null)
                        setGrupa(null)
                        setBrend(null)
                        codeRef.current.focus()
                        getTableData(1, perPage)
                    })


            }
            // u suprotnom ide na upis sloga
            // poziva api za upis
            // osvezavanje polja, fokus na polje SIFRAP, poziva funkciju getTableData(za upis u mrezu)
            else {
                fetch(API + `/artikal/new/${companyCode}`, {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            SIFRAP: companyCode,
                            SIFRA: '',
                            NAZIV: '',
                            JM: '',
                            KOMPAK: '',
                            OZNAKA: '',
                            UVOZNIKID: '',
                            POREZID: '',
                            BAR_KOD: '',
                            GRUPAID: '',
                            BREND: '',
                            KATBROJ: '',
                            FAB_BROJ: '',
                            AKCIZA: '',

                        })
                        setClients(null)
                        setTax(null)
                        setGrupa(null)
                        setBrend(null)
                        codeRef.current.focus()
                        getTableData(1, perPage)
                    })
            }
        }
    }
    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setIme) i puni ukupan broj stranica(setTotal)

    const getTableData = (page, limit) => {
        fetch(API + `/artikal/all/${page}/${limit}/${companyCode}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setartikal(data.result)
                setTotal(data.total)
                getArtikalFirstFreeNumberSIFRA()

            })
    }



    const getBrendData = () => {
        fetch(API + `/brend/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectData(data)
            })
    }

    const getKomtData = () => {
        fetch(API + `/client/all/${companyCode}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataKomt(data)
            })
    }

    const getPorezData = () => {
        fetch(API + `/tax/all/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataPorez(data)
            })
    }

    const getGrupaData = () => {
        fetch(API + `/grupa/all/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataGrupa(data)
            })
    }

    // poziva se inicijalno pri otvaranju forma i poziva api za popunjavanje tabele
    // postavlja fokus na polje SIFRAP
    // prati promjenu state perPage 

    useEffect(() => {
        getTableData(1, perPage)
        codeRef.current.focus();
    }, [perPage])

    useEffect(() => {

    }, [artikal])
    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje SIFRAP, osvjezava mrezu  
    const onDelete = (ID) => {
        fetch(API + '/artikal/delete/' + ID, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    SIFRAP: companyCode,
                    SIFRA: '',
                    NAZIV: '',
                    JM: '',
                    KOMPAK: '',
                    OZNAKA: '',
                    UVOZNIKID: '',
                    POREZID: '',
                    BAR_KOD: '',
                    GRUPAID: '',
                    BREND: '',
                    KATBROJ: '',
                    FAB_BROJ: '',
                    AKCIZA: ''
                })
                setClients(null)
                setTax(null)
                setGrupa(null)
                setBrend(null)
                getArtikalFirstFreeNumberSIFRA()
                codeRef.current.focus()
                getTableData(1, perPage)
            })
    }
    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze

    const onEdit = (item) => {
        nameRef.current.focus()
        setClients(null)
        setTax(null)
        setGrupa(null)
        setBrend(null)
        selectDataKomt.map((client) => {
            if (client.ID == item.UVOZNIKID) {
                setClients(client)
            }
        })
        selectDataPorez.map((tax) => {
            if (tax.SIFRA == item.POREZID) {
                setTax(tax)
            }
        })
        selectDataGrupa.map((grupa) => {
            if (grupa.ID == item.GRUPAID) {
                setGrupa(grupa)
            }
        })

        selectData.map((brend) => {
            if (brend.SIFRA == item.BREND) {
                setBrend(brend)
            }
        })
        setForm(item)
        setEditClient(true)

    }
    // funkcija koja se poziva klikom na dugme 'Stampa'

    const onPrint = () => {
        // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
        // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
        // poziva api za stampu, otvara izvjestaj(API + data.link)
        if (form) {
            form.NAZIV_PRED = artikalNaziv
        }
        fetch(API + `/artikal/print/${artikalNaziv}`, {
            method: 'POST',
            body: JSON.stringify(form),
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                if (typeof window != 'undefined') {
                    setTimeout(() => {
                        window.open(API + data.link)
                    }, 500);

                }
            })
    }
    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // validacija broja i obaveznog polja
    const helperValidateOnlyNumbers = (input) => {
        if (input.replace(/^[0-9.]+$/, '').length > 0) {
            return 'Dozvoljeni su samo brojevi!';
        } else if (input.length < 1) {
            return 'Polje je obavezno!'
        } else {
            return false
        }

    }

    // poziva se inicijalno pri otvaranju forma i poziva api za popunjavanje tabele
    // postavlja fokus na polje 

    // useEffect(() => {
    //     getArtikalFirstFreeNumberSIFRA()
    // }, [!form.SIFRA])

    // funkcija za pronalazenje prvog slobodnog broja
    // poziva api
    // u responsu stejt setForm poziva funkciju za pronalazenje prethodne sifre(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna SIFRAP
    // setFirstCode - stejt koji se koristi za razlikovanje izmjene i upisa 
    const getArtikalFirstFreeNumberSIFRA = () => {
        fetch(API + `/artikal/firstFreeNumberSifra`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setForm(prev => ({ ...prev, SIFRA: Number(data) }))
                // setfirstCode(Number(data))
            })
    }

    // validacija praznog polja
    const helperValidateEmptyField = (input) => {
        if (input.length < 1) return 'Polje je obavezno!';
    }

    const checkCodeRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgCode('Šifra je obavezna!')
            setErrorInputCode(true)
            codeRef.current.focus();
        } else {
            setErrorMsgCode(null)
            setErrorInputCode(false)
        }
    }
    // funkcija za validaciju broja i obaveznog polja

    const checkNameRequired = (value) => {
        if (!value || value.length == 0) {
            setErrorMsgName('Naziv je obavezan!')
            setErrorInputName(true)
            nameRef.current.focus();
            setNameFocus(true)
        } else {
            setErrorMsgName(null)
            setErrorInputName(false)
        }
    }

    const checkJMRequired = (value) => {
        if (!value || value.length == 0) {
            setErrorMsgJM('Jedinica mjere je obavezna!')
            setErrorInputJM(true)
            jmRef.current.focus();
        } else {
            setErrorMsgJM(null)
            setErrorInputJM(false)
        }
    }

    const checkTaxRequired = (value) => {
        if (!value || value.length == 0)  {
            setErrorMsgTax('Porez mora biti izabran!')
            setErrorInputTax(true)
            taxRef.current.focus();
           // setTaxFocus(true)
        } else {
            setErrorMsgTax(null)
            setErrorInputTax(false)
        }
    }

    // stepenasta
    const formCheck = (event) => {

        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        switch (name) {
            case 'SIFRA': {
                checkCodeRequired(value)
                break;

            }
            case 'NAZIV': {
                checkCodeRequired(form.SIFRA)
                checkNameRequired(value)
                break;
            }
            case 'JM': {
                checkJMRequired(value)
                break;
            }
            case 'POREZID': {
                checkTaxRequired(form.value)
                break;

            }

            case 'BAR_KOD': {
                checkTaxRequired(form.POREZID)
                break;

            }

        }
        if (name === 'SIFRA') {
            setCodeFocus(false)
        }

        if (name === 'NAZIV') {
            setNameFocus(false)
        }
        if (name === 'JM') {
            setJmFocus(false)
        }

        if (name === 'POREZID') {   
            setTaxFocus(false)
        }

        // if (name === 'BAR_KOD') {   
           
        // }

    }



    // useEffect(() => {
    //     if (codeRef.current && errorInputCode) {
    //         codeRef.current.focus();
    //     }
    // }, [!codeFocus]);

    // useEffect(() => {
    //     if (nameRef.current && errorInputName) {
    //         nameRef.current.focus();
    //     }
    // }, [!nameFocus]);

    // useEffect(() => {
    //     if (jmRef.current && errorInputJM) {
    //         jmRef.current.focus();
    //     }
    // }, [!jmFocus]);

    // useEffect(() => {
    //     if (taxRef.current && errorInputTax) {
    //         taxRef.current.focus();
    //     }
    // }, [!taxFocus]);

    useEffect(() => {
        getBrendData()
    }, [!selectData])

    useEffect(() => {
        getKomtData()
    }, [!selectDataKomt])

    useEffect(() => {
        getPorezData()
    }, [!tax])

    useEffect(() => {
        getGrupaData()
    }, [!selectDataGrupa])


    // definisu se polja u tabeli i popunjavaju

    const columns = [
        {
            name: 'Šifra',
            selector: row => row.SIFRA,
            width: '100px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.SIFRA}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
            width: '500px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'JM',
            selector: row => row.JM,
            width: '100px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.JM}</span>
                </>
            ),
        },
        {
            name: 'Kom/Pak',
            selector: row => row.KOMPAK,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.KOMPAK}</span>
                </>
            ),
        },
        {
            name: 'Fabricki broj',
            selector: row => row.FAB_BROJ,
            width: '300px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.FAB_BROJ}</span>
                </>
            ),
        },
        {
            name: 'OZNAKA',
            selector: row => row.OZNAKA,
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OZNAKA}</span>
                </>
            ),
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{'     '}
                    <span onClick={() => onDelete(row.ID)} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>

            ),

        }
    ];

    // funkcija se poziva prilikom promjene stranice u tabeli stranica 1,2,3 ...
    const handlePageChange = page => {
        getTableData(page, perPage)
    }
    // funkcija se poziva prilikom promjene broja redova ispisa u tabeli 10, 15, 30 ...
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }


    // propsovi za auto komplit  brend
    const defaultProps = {
        options: selectData,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };
    // propsovi za uvoznika 

    // propsovi za auto komplit  uvoznik
    const defaultPropsKomt = {
        options: selectDataKomt,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // porez

    const defaultPropsPorez = {
        options: selectDataPorez,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // grupa

    const defaultPropsGrupa = {
        options: selectDataGrupa,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };
    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 

    const searchChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
        const fetchData = async () => {
            const res = await axios.get(`${API}/artikal/query/${companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setartikal(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length > 2) fetchData();

    };
    // funkcija za brisanje pretrage klikom na dugme 'Ocisti'
    // osvjezava polje za pretragu
    // osvjezava mrezu

    const handleClear = () => {
        setSearchInput('');
        getTableData(1, perPage)
    }

    return (

        <div>
            {/* Kontejner za naslov  */}

            <div className="title-container">
                {/* Naziv registra */}
                <header className="title-header">
                    <h1 id="media-query-title">Artikal</h1>
                </header>


                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li class="nav-item toolbaritem">
                            <input type="image" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="14" src={require('./../assets/images/floppydisk.png')} onFocus={onSubmit} />
                        </li>

                        <li class="nav-item toolbaritem">
                            <input type="image" className='dugme-stampa' title='Štampa' tabIndex="15" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                    </ul>
                </div>

            </div>
            {/* Kontejner pune sirine */}
            <Container fluid >
                <Row>
                    <div className='products-form'>
                        <Row>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                {/* Sirina polja */}
                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className={errorInputCode ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Šifra:</label>
                                            <input type="number"
                                                id="media-query-input-text"
                                                tabIndex="1"
                                                autoFocus={true}
                                                placeholder="šifra"
                                                name='SIFRA'
                                                // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                                value={form ? form.SIFRA : ''}
                                                ref={codeRef}
                                                onFocus={() => setCodeFocus(true)}
                                                // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                                onBlur={(e) => formCheck(e)}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)} />
                                            {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                            {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}

                                        </div>
                                    </div>
                                </div>
                                {/* Sirina polja */}

                                <div className="col-10">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputName ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Naziv:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="70"
                                            tabIndex="2"
                                            autoComplete='off'
                                            placeholder="naziv"
                                            name='NAZIV'
                                            value={form ? form.NAZIV : ''}
                                            ref={nameRef}
                                            // Postavlja stejt za fokus na true jer je potrebno zbog stepenaste kontrole
                                            onFocus={() => setNameFocus(true)}
                                            // Prilikom prelaska na drugo polje, aktivira se stepenasta kontrola
                                            onBlur={(e) => formCheck(e)}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}

                                    </div>
                                </div>

                                {/* Sirina polja */}

                                <div className="col-1">
                                    <div className={errorInputJM ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">JM:</label>
                                        <input type="text"
                                            id="media-query-input-text"
                                            maxLength="70"
                                            tabIndex="3"
                                            placeholder="JM"
                                            name='JM'
                                            value={form ? form.JM : ''}
                                            ref={jmRef}
                                            onFocus={() => setJmFocus(true)}
                                            onBlur={(e) => formCheck(e)}
                                            onChange={(e) => handleChange(e)} />
                                        {errorMsgJM ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgJM}</span> : ''}

                                    </div>
                                </div>
                            </div>

                            <div className="col-6" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '0px', marginRight: 'auto', marginLeft: '0px' }}>

                                <div className="col-2">
                                    <div className='col-form-right'>
                                        <div className='field-container' style={{ marginRight: '3px' }}>
                                            <label id="media-query-label">Kom/Pak:</label>
                                            <input type="text"
                                                id="media-query-input-text"
                                                maxLength="70"
                                                tabIndex="4"
                                                placeholder="KOMPAK"
                                                name='KOMPAK'
                                                value={form ? form.KOMPAK : ''}
                                                onChange={(e) => handleChange(e)} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Oznaka:</label>
                                        <input type="text" id="media-query-input-text" maxLength="70" tabIndex="5" placeholder="OZNAKA" name='OZNAKA' value={form ? form.OZNAKA : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-7">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Uvoznik</label>
                                        {/* Ako je Autocomplete  poziva na odabri mjesta*/}
                                        <Autocomplete
                                            {...defaultPropsKomt}
                                            value={clients}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, UVOZNIKID: newValue && newValue != null ? newValue.ID : null }))
                                                setClients(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField id='media-query' {...params} className="clients-textinput" name='UVOZNIK' ref={clientsRef} tabIndex="6" onBlur={(e) => formCheck(e)} placeholder=' izaberite uvoznika' variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="col-2">
                                    <div className={errorInputTax ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Porez</label>
                                        <Autocomplete
                                            {...defaultPropsPorez}
                                            value={tax}
                                            onChange={(event, newValue) => {
                                                setTax(newValue);
                                                setForm(prev => ({ ...prev, POREZID: newValue && newValue != null ? newValue.ID : null }))
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    name='POREZID'
                                                    onFocus={() => setTaxFocus(true)}
                                                    ref={taxRef}

                                                    tabIndex={7}
                                                    autoComplete="on"
                                                    onBlur={(e) => formCheck(e)}
                                                    placeholder='Porez'
                                                    variant="standard" />
                                            )}
                                        />
                                        {errorMsgTax ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgTax}</span> : ''}
                                    </div>
                                </div>

                            </div>

                        </Row>



                        <Row>
                            {/* Sirina reda */}
                            <div className="col-12" style={{ display: 'flex' }}>
                                {/* Sirina polja */}

                                <div className="col-1">
                                    {/* Provjera  polja i stilova */}
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Bar Kod:</label>
                                        {/* Provjera unosa pola i duzine  */}
                                        <input type="text" id="media-query-input-text" maxLength="20" tabIndex="8" placeholder="bar kod" name='BAR_KOD' value={form ? form.BAR_KOD : ''} onBlur={(e) => formCheck(e)} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Fabricki broj:</label>
                                        <input type="text" id="media-query-input-text" maxLength="70" tabIndex="9" placeholder="fabricki broj" name='FAB_BROJ' value={form ? form.FAB_BROJ : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Grupa</label>
                                        {/* Ako je Autocomplete  poziva na odabri mjesta*/}
                                        <Autocomplete
                                            {...defaultPropsGrupa}
                                            value={grupa}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, GRUPAID: newValue && newValue != null ? newValue.ID : null }))
                                                setGrupa(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField id='media-query' {...params} className="clients-textinput" name='GRUPA' onFocus={() => setGrupaFocus(true)} ref={grupaRef} tabIndex="10" onBlur={(e) => formCheck(e)} placeholder=' grupa' variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>


                                <div className="col-2">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Brend</label>
                                        {/* Ako je Autocomplete  poziva na odabri mjesta*/}
                                        <Autocomplete
                                            {...defaultProps}
                                            value={brend}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, BREND: newValue && newValue != null ? newValue.SIFRA : null }))
                                                setBrend(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField id='media-query' {...params} className="clients-textinput" name='BREND' onFocus={() => setBrendFocus(true)} ref={brendRef} tabIndex="11" onBlur={(e) => formCheck(e)} placeholder=' izaberite brend' variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>


                                <div className="col-1">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Kat. broj:</label>
                                        <input type="text" id="media-query-input-text" maxLength="20" tabIndex="12" placeholder="KATBROJ" name='KATBROJ' value={form ? form.KATBROJ : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-1">
                                    <div className='col-form-right'>
                                        <div className='field-container' style={{ marginRight: '3px' }}>
                                            <label id="media-query-label">Akciza:</label>
                                            <input type="text" id="media-query-input-text" maxLength="20" tabIndex="13" placeholder="AKCIZA" name='AKCIZA' value={form ? form.AKCIZA : ''} onChange={(e) => handleChange(e)} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Row>

                    </div>
                </Row >
            </Container >



            {/* Stilovi i klase za polje za pretragu;  */}
            < div style={{ display: 'flex' }
            } className='search' >
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                < input type='text' id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => searchChange(e)} value={searchInput} />
                {/* dugme 'Ocisti'( poziva funkciju handleClear za brisanje) */}
                < button onClick={() => handleClear()} className='dugme-clear' > Očisti</button >
            </div >

            {/* Mreza */}
            < DataTable
                columns={columns}
                data={artikal ? artikal : []}
                pagination
                paginationServer
                paginationTotalRows={total}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
            />
            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}

            < div >
                <Modal isOpen={modalErrorOpen} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div >

        </div >
    );

}

export default Artikal;


