// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import axios from '../api/axios';
import { NumericFormat } from 'react-number-format';
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
// komponenta koju kreiramo

function User() {
    // definisanje konstanti koje koristimo za fokusiranje polja
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [user, setUser] = useState(null);
    const [searchInput, setSearchInput] = useState([]);

    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorInputOvlascenje, setErrorInputOvlascenje] = useState(false);
    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [errorMsgOvlascenje, setErrorMsgOvlascenje] = useState(false);

    const [pending, setPending] = React.useState(false);

    const sifraRef = useRef();
    const nazivRef = useRef();
    const imeRef = useRef();
    const roditeljRef = useRef();
    const jmbRef = useRef();
    const zanimanjeRef = useRef();
    const emailRef = useRef();
    const robnoRef = useRef();
    const faktRef = useRef();
    const fikRef = useRef();
    const blagRef = useRef();
    const ovlascenjeRef = useRef();


    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null;

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        JAVNA: '',
        NAZIV: '',
        SIFRA: '',
        IME: '',
        RODITELJ: '',
        JMB: '',
        OPIS: '',
        EMAIL: '',
        OVLASCENJE: '',
        OVLROBNO: '',
        OVLFAKT: '',
        OVLFIK: '',
        OVLBLAGAJNA: '',
        stavkaPronadjena: false
    })

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {

        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;

        // poziva api za upis
        // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getReports(za upis u mrezu)

        //    form.JAVNA = form.userCode
        fetch(API + '/user/new', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    NAZIV: '',
                    SIFRA: '',
                    IME: '',
                    RODITELJ: '',
                    JMB: '',
                    OPIS: '',
                    EMAIL: '',
                    OVLASCENJE: '',
                    OVLROBNO: '',
                    OVLFAKT: '',
                    OVLFIK: '',
                    OVLBLAGAJNA: '',
                    stavkaPronadjena: false
                })
                nazivRef.current.focus()
                getReports()
            })
    }


    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezu
    const getReports = () => {
        fetch(API + `/user/all`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setUser(data.result)
                setPending(false)
            })
    }

    // funkcija za pronalazenje prvog slobodnog broja
    // poziva api
    // u responsu stejt setForm poziva funkciju za pronalazenje prethodne javne(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna javna
    const getClientFirstFreeNumberJavna = () => {
        fetch(API + `/user/firstFreeNumberJavna`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                form.JAVNA = Number(data[0].JAVNA)
            })
    }

    // prilikom pocetne inicijalizacije postavlja prvi slobodan broj
    useEffect(() => {
        // zaustavljanje fokusa(pauza za fokus dok ne odradi upis) 
        const timeout = setTimeout(() => {
            getReports()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    // prilikom pocetne inicijalizacije postavlja prvi slobodan broj
    useEffect(() => {
        // zaustavljanje fokusa(pauza za fokus dok ne odradi upis) 
        const timeout = setTimeout(() => {
            getClientFirstFreeNumberJavna()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);


    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
    const onDelete = (JAVNA) => {
        fetch(API + '/user/delete/' + JAVNA, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    NAZIV: '',
                    SIFRA: '',
                    IME: '',
                    RODITELJ: '',
                    JMB: '',
                    OPIS: '',
                    EMAIL: '',
                    OVLASCENJE: '',
                    OVLROBNO: '',
                    OVLFAKT: '',
                    OVLFIK: '',
                    OVLBLAGAJNA: '',
                    stavkaPronadjena: false
                })
                sifraRef.current.focus()
                getReports()
            })
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze
    const onEdit = (item) => {
        item.stavkaPronadjena = true
        setForm(item)

        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            nazivRef.current.focus()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }

    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;
        // postavlja sa funkcijom  setForm((prevState) mijejenja predhodno stanje sa novom vrijednoscu
        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };


    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkCodeRequired = (value) => {
        if (!value || value === 0) {
            setErrorMsgCode('Lozinka je obavezna!')
            setErrorInputCode(true)
            sifraRef.current.focus();
            return false
        } else {
            setErrorMsgCode(null)
            setErrorInputCode(false)
            return true
        }
    }

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkNameRequired = (value) => {
        if (!value || value === 0) {
            setErrorMsgName('Korisničko ime je obavezno!')
            setErrorInputName(true)
            nazivRef.current.focus();
            return false
        } else {
            setErrorMsgName(null)
            setErrorInputName(false)
            return true
        }
    }

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkAuthorizationRequired = (value) => {
        if (!value || value === 0) {
            setErrorMsgOvlascenje('Unesite ovlašćenje(1, 2, 3 ili 4)!')
            setErrorInputOvlascenje(true)
            ovlascenjeRef.current.focus();
            return false
        } else {
            setErrorMsgOvlascenje(null)
            setErrorInputOvlascenje(false)
            return true
        }
    }

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'NAZIV') {
            return true
        }

        if (!checkNameRequired(form.NAZIV)) {
            return false
        }

        if (name === 'SIFRA') {
            return true
        }

        if (!checkCodeRequired(form.SIFRA)) {
            return false
        }

        if (name === 'IME') {
            return true
        }

        if (name === 'RODITELJ') {
            return true
        }

        if (name === 'OPIS') {
            return true
        }

        if (name === 'EMAIL') {
            return true
        }

        if (name === 'JMB') {
            return true
        }

        if (name === 'OVLASCENJE') {
            return true
        }

        if (!checkAuthorizationRequired(form.OVLASCENJE)) {
            return false
        }

        if (name === 'OVLROBNO') {
            return true
        }

        if (name === 'OVLFAKT') {
            return true
        }

        if (name === 'OVLFIK') {
            return true
        }

        if (name === 'OVLBLAGAJNA') {
            return true
        }

        return true
    }

    // definisu se polja u tabeli i popunjavaju
    const columns = [
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
            width: '300px',
            left: 'boolean',

            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'Robno ovlašćenje',
            selector: row => row.OVLROBNO,
            width: '200px',
            right: 'boolean',

            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OVLROBNO}</span>
                </>
            ),
        },
        {
            name: 'Fakturisanje ovlašćenje',
            selector: row => row.OVLFAKT,
            width: '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OVLFAKT}</span>
                </>
            ),
        },
        {
            name: 'Finansije ovlašćenje',
            selector: row => row.OVLFIK,
            right: 'boolean',
            width: '200px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OVLFIK}</span>
                </>
            ),
        },

        {
            name: 'Blagajna ovlašćenje',
            selector: row => row.OVLBLAGAJNA,
            width: '200px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OVLBLAGAJNA}</span>
                </>
            ),
        },

        {
            name: 'Action',
            width: '400px',
            right: 'boolean',
            cell: (row) => (
                // klikom na dugme Edit, aktivira se funkcija onEdit 
                // klikom na dugme Brisanje, aktivira se funkcija onDelete
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{''}
                    <span onClick={() => onDelete(row.JAVNA)} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>
            ),
        }
    ];

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 
    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value
        setSearchInput(searchInput);
        const fetchData = async () => {
            const res = await axios.get(`${API}/user/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setUser(res.data);
        };

        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length >= 1) {
            fetchData();
        }
    };



    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container-report">
                {/* Naziv forma */}
                <header className="title-header">
                    <h2 id="media-query-title">Administracija korisnika</h2>
                </header>

                <div >
                    <div>
                        {/* Klasa za ikonice, stampa*/}
                        <ul>
                            <input type="image" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="13" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </ul>
                    </div>
                </div>
            </div>

            <Container fluid>
                <div className="row">
                    <Col lg='3'>
                        <div className='form-field-container' style={{ display: 'contents' }}>
                            {/* red sirine */}
                            <Col lg='12'>
                            <div className="col-sm radio-box" id='media-query-radioButton-date'>
                                    <ul className=" flex-column flex-lg-row justify-content-end">

                                        <li className="nav-item toolbaritem col-form-label-ovlascenja ">
                                            VRSTE OVLAŠĆENJA:
                                        </li>
                                        <li className="nav-item toolbaritem col-form-label-ovlascenja ">
                                            1 - ADMINISTRATOR - Sva softverska prava
                                        </li>

                                        <li className="nav-item toolbaritem col-form-label-ovlascenja ">
                                            2 - SAMO ČITANJE - Pristup podacima samo za čitanje
                                        </li>

                                        <li className="nav-item toolbaritem col-form-label-ovlascenja ">
                                            4 - SUPER ADMINISTRATOR - Neograničen pristup
                                        </li>

                                    </ul>
                               

                                    <ul className=" flex-column flex-lg-row justify-content-end">

                                        <li className="nav-item toolbaritem col-form-label-ovlascenja ">
                                            Ovlašćenja za aplikacije:
                                        </li>
                                        <li className="nav-item toolbaritem col-form-label-ovlascenja ">
                                            1 - Zabrana otvaranja aplikacije
                                        </li>

                                        <li className="nav-item toolbaritem col-form-label-ovlascenja ">
                                            2 - Samo čitanje
                                        </li>

                                        <li className="nav-item toolbaritem col-form-label-ovlascenja">
                                            3 - Izvještaji i registri
                                        </li>

                                        <li className="nav-item toolbaritem col-form-label-ovlascenja ">
                                            4 - Zabrana brisanja
                                        </li>

                                    </ul>
                                    </div>
                            </Col>
                        </div>
                    </Col>

                    <Col lg='6'>

                        {/* <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}> */}
                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">
                                <div className={errorInputName ? 'form-group row my-input-columns' : 'form-group row required label-box-div'} >
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Korisničko ime:</label>
                                    <div className="col-10" style={{ marginRight: 'auto' }}>
                                        <input type="text"
                                            id="media-query-input-text"
                                            ref={nazivRef}
                                            maxLength="20"
                                            tabIndex="1"
                                            autoFocus
                                            placeholder="Korisničko ime"
                                            name='NAZIV'
                                            //   autoFocus={true}
                                            value={form?.NAZIV}
                                            onFocus={() => {
                                                unosIspravanDo("NAZIV")
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                        {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">
                                <div className='form-group row label-box-div'>
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Lozinka:</label>
                                    <div className="col-10" style={{ marginRight: 'auto' }}>
                                        <div className={errorInputCode ? 'my-input' : 'field-container'}>
                                            <input type="password"
                                                ref={sifraRef}
                                                id="media-query-input-text"
                                                maxLength="20"
                                                tabIndex="2"
                                                placeholder="Lozinka"
                                                name='SIFRA'
                                                value={form?.SIFRA}
                                                onFocus={() => {
                                                    unosIspravanDo("SIFRA")
                                                }}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)}
                                            />
                                            {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Ime i prezime:</label>
                                    <div className="col-10" style={{ marginRight: 'auto' }}>
                                        <input type="text"
                                            id="media-query-input-text"
                                            ref={imeRef}
                                            maxLength="30"
                                            tabIndex="3"
                                            placeholder="Ime i prezime"
                                            name='IME'
                                            value={form?.IME}
                                            onFocus={() => {
                                                unosIspravanDo("IME")
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Roditelj:</label>
                                    <div className="col-10" style={{ marginRight: 'auto' }}>
                                        <input type="text"
                                            id="media-query-input-text"
                                            ref={roditeljRef}
                                            maxLength="20"
                                            tabIndex="4"
                                            placeholder="Roditelj"
                                            name='RODITELJ'
                                            value={form?.RODITELJ}
                                            onFocus={() => {
                                                unosIspravanDo("RODITELJ")
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">JMB:</label>
                                    <div className="col-10" style={{ marginRight: 'auto' }}>
                                        <input type="text"
                                            id="media-query-input-text"
                                            ref={jmbRef}
                                            maxLength="15"
                                            tabIndex="5"
                                            placeholder="JMB"
                                            name='JMB'
                                            value={form?.JMB}
                                            onFocus={() => {
                                                unosIspravanDo("JMB")
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Rad.mj./Zanimanje:</label>
                                    <div className="col-10" style={{ marginRight: 'auto' }}>
                                        <input type="text"
                                            id="media-query-input-text"
                                            ref={zanimanjeRef}
                                            maxLength="100"
                                            tabIndex="5"
                                            placeholder="Rad.mj./Zanimanje"
                                            name='OPIS'
                                            value={form?.OPIS}
                                            onFocus={() => {
                                                unosIspravanDo("OPIS")
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Email:</label>
                                    <div className="col-10" style={{ marginRight: 'auto' }}>
                                        <input type="text"
                                            id="media-query-input-text"
                                            ref={emailRef}
                                            maxLength="100"
                                            tabIndex="6"
                                            placeholder="Email"
                                            name='EMAIL'
                                            value={form?.EMAIL}
                                            onFocus={() => {
                                                unosIspravanDo("EMAIL")
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">

                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">JMB:</label>
                                    <div className="col-6" style={{ marginRight: 'auto' }}>
                                        <div className='field-container'>
                                            <input type="text"
                                                id="media-query-input-text"
                                                ref={jmbRef}
                                                maxLength="15"
                                                tabIndex="7"
                                                placeholder="JMB"
                                                name='JMB'
                                                value={form?.JMB}
                                                onFocus={() => {
                                                    unosIspravanDo("JMB")
                                                }}
                                                // Ako je izmjena na polju
                                                onChange={(e) => handleChange(e)} />
                                        </div>
                                    </div>

                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-to">Ovlašćenje:</label>
                                    <div className="col-2" style={{ marginRight: 'auto' }}>
                                        <div className={errorInputOvlascenje ? 'my-input' : 'field-container'}>
                                            <NumericFormat
                                                type="number"
                                                getInputRef={ovlascenjeRef}
                                                // broj decimala
                                                decimalScale={0}
                                                maxLength="1"
                                                tabIndex="8"
                                                id="media-query-input-to"
                                                onFocus={() => {
                                                    unosIspravanDo("OVLASCENJE")
                                                }}
                                                placeholder="ovlašćenje"
                                                value={form?.OVLASCENJE}
                                                name='OVLASCENJE'
                                                onChange={(e) => handleChange(e)} />
                                            {errorMsgOvlascenje ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgOvlascenje}</span> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col lg='3'>
                        <Col lg='12' >
                            <div className="col-12" style={{ display: 'flex', }}>
                                <div className="col-12">

                                    <div className="form-group row label-box-div">
                                        <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Robno:</label>
                                        <div className="col-3" style={{ marginRight: 'auto' }}>
                                            <div className={'field-container'}>
                                                <NumericFormat
                                                    type="number"
                                                    getInputRef={robnoRef}
                                                    id="media-query-input-text"
                                                    maxLength="1"
                                                    tabIndex="9"
                                                    decimalScale={0}
                                                    placeholder="Robno"
                                                    name='OVLROBNO'
                                                    value={form?.OVLROBNO}
                                                    onFocus={() => {
                                                        unosIspravanDo("OVLROBNO")
                                                    }}
                                                    // Ako je izmjena na polju
                                                    onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>

                                        <label className="col-form-label col-lg-2 label-box" id="media-query-label-to">Fakt.:</label>
                                        <div className="col-3" style={{ marginRight: 'auto' }}>
                                            <div className={'field-container'}>
                                                <NumericFormat
                                                    type="number"
                                                    getInputRef={faktRef}
                                                    id="media-query-input-text"
                                                    maxLength="1"
                                                    tabIndex="10"
                                                    decimalScale={0}
                                                    placeholder="Fakt"
                                                    name='OVLFAKT'
                                                    value={form?.OVLFAKT}
                                                    onFocus={() => {
                                                        unosIspravanDo("OVLFAKT")
                                                    }}
                                                    // Ako je izmjena na polju
                                                    onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row label-box-div">
                                        <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Fik:</label>
                                        <div className="col-3" style={{ marginRight: 'auto' }}>
                                            <div className={'field-container'}>
                                                <NumericFormat
                                                    type="number"
                                                    getInputRef={fikRef}
                                                    id="media-query-input-text"
                                                    maxLength="1"
                                                    tabIndex="11"
                                                    decimalScale={0}
                                                    placeholder="Fik"
                                                    name='OVLFIK'
                                                    value={form?.OVLFIK}
                                                    onFocus={() => {
                                                        unosIspravanDo("OVLFIK")
                                                    }}
                                                    // Ako je izmjena na polju
                                                    onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>

                                        <label className="col-form-label col-lg-2 label-box" id="media-query-label-to">Blag.:</label>
                                        <div className="col-3" style={{ marginRight: 'auto' }}>
                                            <div className={'field-container'}>
                                                <NumericFormat
                                                    type="number"
                                                    getInputRef={blagRef}
                                                    id="media-query-input-text"
                                                    maxLength="1"
                                                    tabIndex="12"
                                                    decimalScale={0}
                                                    placeholder="Blag"
                                                    name='OVLBLAGAJNA'
                                                    value={form?.OVLBLAGAJNA}
                                                    onFocus={() => {
                                                        unosIspravanDo("OVLBLAGAJNA")
                                                    }}
                                                    // Ako je izmjena na polju
                                                    onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Col>
                </div>
            </Container >

            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' id="media-query-input-text" placeholder='Pretraga...'
                    onChange={(e) => {
                        // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                        if (searchInput.length >= 0) {
                            searchChange(e)
                        } else {
                            getReports()
                        }
                    }}
                    value={searchInput} />
            </div>

            <div id='media-query-datatable'>
                {
                    < DataTable
                        columns={columns}
                        data={user ? user : []}
                        pagination
                        responsive
                        striped
                        dense
                        pointerOnHover
                        paginationComponentOptions={defaultComponentOptions}
                        progressPending={pending}
                    />
                }
            </div>
        </div >
    );

}

export default User;





