// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API } from '../../constants';
import { RadioButton } from '../../components/radioButton';
import { numberWithCommasColumns } from '../../components/utilities';
import { numberWithCommas } from '../../components/utilities';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Spinner } from "reactstrap";
// komponenta koju kreiramo

function BlagajnaReport() {
    // definisanje konstanti koje koristimo za fokusiranje polja
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const yearRef = useRef();
    const [blagajnaReport, setBlagajnaReport] = useState(null)
    const [total, setTotal] = useState(0)
    const [sum, setSum] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [repository, setRepository] = useState(null)
    const [clients, setClients] = useState(null);
    const [service, setService] = useState(null);
    const [selectData, setSelectData] = useState([]);
    const [selectDataClients, setSelectDataClients] = useState([]);
    const [selectDataService, setSelectDataService] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [buttonOnClick, setButtonOnClick] = useState(false);
    const [selectDataVr, setSelectDataVr] = useState([]);
    const [selectDataOrg, setSelectDataOrg] = useState([]);
    const [selectDataBlagajna, setSelectDataBlagajna] = useState([]);
    const [selectDataMjesto, setSelectDataMjesto] = useState([]);
    const [selectDataValuta, setSelectDataValuta] = useState([]);
    const [valuta, setValuta] = useState(null);
    const [org, setOrg] = useState(null);
    const [mjesto, setMjesto] = useState(null);
    const [vrsta, setVrsta] = useState(null);
    const [sifrao, setSifrao] = useState(null)



    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [modalErrorOpenPrint, setModalErrorOpenPrint] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);

    const [date, setDate] = useState({
        checkDate: true,
        checkDateCancelled: false
    });
    const [reversal, setReversal] = useState({
        checkAll: true,
        checkCancelled: false,
        checkNotCancelled: false
    });
    const [taxPayers, setTaxPayers] = useState({
        checkAllTaxPlayers: true,
        checkTaxPlayers: false,
        checkNotTaxPlayers: false

    });
    const [buttonType, setButtonType] = useState(null);
    const [buttonTypeTotal, setButtonTypeTotal] = useState(null)

    // povlacenje podataka iz lokal storidza

    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null
    const companyNaziv = typeof window !== 'undefined' ? localStorage.getItem('companyNaziv') : null
    let year = typeof window !== 'undefined' ? localStorage.getItem('companyYear') : null

    let dateFrom
    let dateTo
    let currentDate = new Date()
    if (currentDate.getFullYear() === Number(year)) {
        dateFrom = new Date(currentDate.getFullYear(), 0, 1);
        dateTo = new Date()
    } else {
        let oldYear = new Date()
        oldYear = new Date(Number(oldYear.setFullYear(year)))
        dateFrom = new Date(oldYear.getFullYear(), 0, 1);
        dateTo = new Date(Number(oldYear.setFullYear(year)))
    }
    // error row inputs
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);

    // error messages inputs
    const [errorMsgCode, setErrorMsgCode] = useState('');
    const [errorMsgName, setErrorMsgName] = useState('');
    // niz prema kome se formira pagination u mrezi(pri kreiranju 'DataTable')
    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };



    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        SKLAID: '',
        GODINA: year,
        VRSTA: '',
        VALUTA_NAZIV: 'BAM',
        VAL: '1',
        ORGJEDID: '',
        MJESTOTROSKAID: '',
        OPIS: '',
        DATUMOD: dateFrom,
        DATUMDO: dateTo,

    })

    const [startDate, setStartDate] = useState(dateFrom);
    const [endDate, setEndDate] = useState(dateTo);

    const getReports = (page, limit) => {
        if (buttonType || buttonTypeTotal) {
            // provjera obaveznih polja na dugmetu
            // punjenje
            if (form) {
                form.DATUMOD = startDate
                form.DATUMDO = endDate
                form.ORGJEDID = org && org.ID ? org.ID : null
                form.VRSTA = vrsta && vrsta.VRSTAD ? vrsta.VRSTAD : null
                form.OPIS = sifrao && sifrao.SIFRA ? sifrao.SIFRA : null
                form.MJESTOTROSKAID = mjesto && mjesto.ID ? mjesto.ID : null
                form.VALUTA_NAZIV = valuta && valuta.VALUTA ? valuta.VALUTA : null
                form.VAL = form.VAL && form.VAL ? form.VAL : null
                form.buttonType = buttonType
                form.buttonTypeTotal = buttonTypeTotal
            }

            fetch(API + `/blagajnaReport/all/${page}/${limit}/${companyCode}`, { // pozivanje apija sa servera
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    if (data && data.items && data.items.length !== 0) {
                        setBlagajnaReport(data.items)
                        setTotal(data.total)
                        setSum(data.sum)
                        setDate(data.date)
                        setReversal(data.reversal)
                        setTaxPayers(data.taxPayers)
                    } else {
                        setModalErrorOpenData(true)
                    }

                })
        }
    }

    // pokretanje  dugmeta za stampu

    const print = () => {
        // pokretanje spinera kojim se vidi da izvjestaj pokrenut
        setSpinner(true)
        if (form) {
            form.NAZIV_PRED = companyNaziv
            form.DATUMOD = startDate
            form.DATUMDO = endDate
            form.ORGJEDID = org && org.ID ? org.ID : null
            form.VRSTA = vrsta && vrsta.VRSTAD ? vrsta.VRSTAD : null
            form.OPIS = sifrao && sifrao.SIFRA ? sifrao.SIFRA : null
            form.MJESTOTROSKAID = mjesto && mjesto.ID ? mjesto.ID : null
            form.VALUTA_NAZIV = valuta && valuta.VALUTA ? valuta.VALUTA : null
            form.VAL = form.VAL && form.VAL ? form.VAL : null
            form.buttonType = buttonType
            form.buttonTypeTotal = buttonTypeTotal
        }
        // povjera da li izbaran izvjestaj, ako nije izabran
        // if (buttonType == null) {
        //     setModalErrorOpenPrint(true)
        //     // zatvaranje spinera
        //     setSpinner(false)
        // }
   
        // ako je izabran izvjestaj "buttonType"
         if (buttonType  == 'pregled' ) {
            // poziva prvi API za stampu, otvara izvjestaj (API + data.link)
            fetch(API + `/blagajnaReport/print/${companyCode}`, {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
            .then((response) => response.json())
            .then(data => {
                if (typeof window !== 'undefined') {
                    setTimeout(() => {
                        window.open(API + data.link)
                    }, 500);
                }
                setSpinner(false)
            })
        }
        // ako je izabran izvjestaj "buttonTypeTotal"
          if (buttonTypeTotal == 'dnevnik') {
            // poziva drugi API za stampu, otvara izvjestaj (API + "/print/dnevnik")
            fetch(API + `/blagajnaReport/print/dnevnik/${companyCode}`, {// poziva se api za print sa servera
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let blobURL = URL.createObjectURL(blob);
                            let iframe = document.createElement('iframe'); //load content in an iframe to print later
                            document.body.appendChild(iframe);
                            iframe.style.display = 'none';
                            iframe.src = blobURL;
                            iframe.onload = function () {
                                setTimeout(function () {
                                    iframe.focus();
                                    iframe.contentWindow.print();
                                }, 1);
                            };
                            setSpinner(false)

                        });

                    }

                })

        } if (buttonTypeTotal == 'dnevnikNaDan') {
            // poziva drugi API za stampu, otvara izvjestaj (API + "/print/dnevnik")
            fetch(API + `/blagajnaReport/print/dnevnikNaDan/${companyCode}`, {// poziva se api za print sa servera
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => {
                    if (response.ok) { // checks if the response is with status 200 (successful)
                        return response.blob().then(blob => {
                            let blobURL = URL.createObjectURL(blob);
                            let iframe = document.createElement('iframe'); //load content in an iframe to print later
                            document.body.appendChild(iframe);
                            iframe.style.display = 'none';
                            iframe.src = blobURL;
                            iframe.onload = function () {
                                setTimeout(function () {
                                    iframe.focus();
                                    iframe.contentWindow.print();
                                }, 1);
                            };
                            setSpinner(false)

                        });

                    }

                })

        }
    }
    


// prati promjene na zadatim autocomplete poljima
    useEffect(() => {
        getOpisbData()
    }, [!selectDataBlagajna])


    useEffect(() => {
        getValutaData()
    }, [!selectDataValuta])
    useEffect(() => {
        getMjestoData()
    }, [!selectDataMjesto])
    useEffect(() => {
        getVrstaData()
    }, [!selectDataVr])

    useEffect(() => {
        getOrgData()
    }, [!selectDataOrg])
    // prati promjenu stanja za izvjestaje
    useEffect(() => {
        getReports(1, perPage)
        // ako je dugme popunjeno,poziva funkciju getReports
    }, [buttonOnClick])

    useEffect(() => {
        getReports(1, perPage)
        // ako je dugme popunjeno,poziva funkciju getReports
    }, [buttonType || buttonTypeTotal])

    useEffect(() => {
        getReports(1, perPage)
        // osvjezava mrezu svaki put kad promijenimo neko od datih polja
    }, [org || vrsta || sifrao || mjesto || form.DATUMOD || form.DATUMDO])




    // api za dobijanje podataka za valutu 

    const getValutaData = () => {
        fetch(API + `/valuta/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataValuta(data)
            })
    }

    const getVrstaData = () => {
        fetch(API + `/vrstaKnjizenja/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataVr(data)
            })
    }
    // api za dobijanje podataka za mjesto 

    const getMjestoData = () => {
        fetch(API + `/mjestoTroska/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataMjesto(data)
            })
    }

    // api za dobijanje podataka za organizacionu jedinicu 

    const getOrgData = () => {
        fetch(API + `/organizationalUnit/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataOrg(data)
            })
    }



    // api za dobijanje podataka za opis 

    const getOpisbData = () => {
        fetch(API + `/blagajnaOpis/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectDataBlagajna(data)
            })
    }



    // valuta

    const defaultPropsValuta = {
        options: selectDataValuta,
        getOptionLabel: (option) => option ? `${option.VALUTA}` : '',
    };

    // vrsta

    const defaultPropsVrsta = {
        options: selectDataVr,
        getOptionLabel: (option) => option ? `${option.VRSTAD} ${option.OPISD}` : '',
    };
    // mjesto
    const defaultPropsMjesto = {
        options: selectDataMjesto,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // sifra opisa
    const defaultPropsSifrao = {
        options: selectDataBlagajna,
        getOptionLabel: (option) => option ? ` ${option.SIFRA} ${option.NAZIV}` : '',
    };

    const defaultPropsOrg = {
        options: selectDataOrg,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };

    // useEffect(() => {
    //     getReports(1, perPage)
    //     // ako je dugme popunjeno,poziva funkciju getReports
    // }, [invoiceReport])

    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;
        // postavlja sa funkcijom  setForm((prevState) mijejenja predhodno stanje sa novom vrijednoscu
        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };
    // cekiranje radio dugmadi u zavisnosti odabrane opcije
    const radioChangeHandler = (e) => {
        if (e.target.name === 'checkDate') {
            setDate({
                // odabir datuma dokumenata
                checkDate: true,
                checkDateCancelled: false
            });
        } else {
            setDate({
                checkDate: false,
                // odabir datuma storniranja
                checkDateCancelled: true
            });
        }
    };



    // definisu se polja u tabeli i popunjavaju (2 tablete jedna za dnevnik druga za pregled)
    const dnevnik = [
        {
            name: 'Rbr',
            selector: row => row.RED,
            width: '130px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.RED}</span>
                </>
            ),
        },
        {
            name: 'Temeljnica',
            selector: row => row.VRSTA,
            width: '130px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.VRSTA}</span>
                </>
            ),
        },


        {
            name: 'Opis',
            selector: row => row.OPIS,
            width: '570px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OPIS}</span>
                </>
            ),
        },
        {
            name: 'Ulaz',
            selector: row => row.DUGUJE,
            width: '120px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.DUGUJE}</span>
                </>
            ),
        },
        {
            name: 'Izlaz',
            selector: row => row.POTRAZ,
            width: '170px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.POTRAZ}</span>
                </>
            ),
        },
        {
            name: 'Racun br',
            selector: row => row.RACUN,
            width: '160px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.RACUN}</span>
                </>
            ),
        },


    ];


// mreza za pregled

    const pregled = [
        {
            name: 'Vrsta',
            selector: row => row.VRSTA,
            width: '130px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.VRSTA}</span>
                </>
            ),
        },
        {
            name: 'Nalog',
            selector: row => row.NALOG,
            width: '130px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.NALOG}</span>
                </>
            ),
        },


        {
            name: 'Rbr',
            selector: row => row.RED,
            width: '570px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.RED}</span>
                </>
            ),
        },
        {
            name: 'Opis',
            selector: row => row.OPIS,
            width: '150px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OPIS}</span>
                </>
            ),
        },
        {
            name: 'Duguje',
            selector: row => row.DUGUJE,
            width: '170px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.DUGUJE}</span>
                </>
            ),
        },
        {
            name: 'Potrazuje',
            selector: row => row.POTRAZ,
            width: '160px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.POTRAZ}</span>
                </>
            ),
        },

        {
            name: 'Saldo',
            selector: row => row.IZNOS,
            width: '160px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.IZNOS}</span>
                </>
            ),
        },

    ];

    // funkcija se poziva prilikom promjene stranice u tabeli stranica 1,2,3 ...
    const handlePageChange = page => {
        getReports(page, perPage)
    }
    // funkcija se poziva prilikom promjene broja ispisa u tabeli 10, 15, 30 ...
    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }
    // propsovi za auto komplit  - mjesto


    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container-report">
                {/* Naziv forma */}

                <header className="title-header">
                    <h2 id="media-query-title">Izvještaj Blagajne</h2>
                </header>


                <div >
                    <div>
                        {/* Klasa za ikonice, stampa*/}
                        <ul>
                            <input type="image" className='dugme-stampa' title='Štampa' tabIndex="15" src={require('../../assets/images/printer.png')} onClick={print} disabled={spinner} />
                        </ul>
                    </div>
                </div>
            </div>
            <Container fluid>
                <div className="row">
                    <Col lg='3'>

                        {/* <div className='products-form'> */}

                        <Row>
                            <div style={{ display: 'contents' }}>
                                {/* Existing code */}
                                <div className="col-sm radio-box" id='media-query-radioButton-date'>
                                    <RadioButton
                                        changed={(e) => radioChangeHandler(e)}
                                        isSelected={date?.checkDate}
                                        label="Po  datumu dokumenta"
                                        id="1"
                                        value={date?.checkDate}
                                        name='checkDate'
                                    />

                                    <RadioButton
                                        changed={(e) => radioChangeHandler(e)}
                                        isSelected={date?.checkDateCancelled}
                                        label="Po broju dokumenta"
                                        id="2"
                                        value={date?.checkDateCancelled}
                                        name='checkDateCancelled'
                                    />
                                </div>

                                {/* Checkbox */}
                                {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                    <input type="checkbox" id="myCheckbox" className="checkbox" />
                                    <label htmlFor="myCheckbox" className="checkbox-label">Valuta</label>
                                </div> */}
                            </div>
                        </Row>


                    </Col>

                    <Col lg='5'>

                        <div className="col-12" style={{ display: 'flex' }}>
                            <div className="col-12">
                                <div className="form-group row required label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Org. :</label>
                                    <div className="col-10">
                                        <Autocomplete
                                            {...defaultPropsOrg}
                                            value={org}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setOrg(newValue);
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    tabIndex="1"
                                                    autoFocus
                                                    required
                                                    placeholder=' Izaberite organizacionu'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12" style={{ display: 'flex' }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Vrsta:</label>
                                    <div className="col-10">
                                        {/* autokomplit za komitenta */}
                                        <Autocomplete
                                            {...defaultPropsVrsta}
                                            value={vrsta}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setVrsta(newValue);
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    tabIndex="1"
                                                    required
                                                    placeholder=' Izaberite vrstu knjizenja'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12" style={{ display: 'flex' }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Opis:</label>
                                    <div className="col-10">
                                        <Autocomplete
                                            {...defaultPropsSifrao}
                                            value={sifrao}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setSifrao(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    tabIndex="1"
                                                    
                                                    required
                                                    placeholder=' Izaberite opis'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12" style={{ display: 'flex' }}>
                            <div className="col-12">
                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Mjesto:</label>
                                    <div className="col-10">
                                        <Autocomplete
                                            {...defaultPropsMjesto}
                                            value={mjesto}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setMjesto(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    tabIndex="1"
                                                  
                                                    required
                                                    placeholder=' Izaberite mjesto troska'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>




                        {/* <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}> */}
                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12" >

                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Valuta:</label>
                                    <div className="col-2" style={{ marginRight: 'auto' }}>
                                        <Autocomplete
                                            {...defaultPropsValuta}
                                            value={valuta}
                                            onChange={(event, newValue) => {
                                                setValuta(newValue);
                                                setForm(prev => ({ ...prev, VALUTA_NAZIV: newValue && newValue != null ? newValue.VALUTA : null, VAL: newValue && newValue != null ? newValue.KURS : null, }))
                                            }}

                                            renderInput={(params) => (
                                                <TextField {...params}
                                                    className="clients-textinput"
                                                    tabIndex="1"
                                                    required
                                                    placeholder='valuta'
                                                    variant="standard" />
                                            )}
                                        />
                                    </div>



                                    <label className="col-form-label col-lg-6 label-box" id="media-query-label-to">Kurs:</label>
                                    <div className="col-2" style={{ marginRight: 'auto' }}>
                                        <input style={{ color: 'grey' }} type="number" id="media-query-input-text" readOnly={true} maxLength="70" tabIndex="6" placeholder="0.00" name='KURS' value={form ? form.VAL : ''} onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-12" style={{ display: 'flex', }}>
                            <div className="col-12">

                                <div className="form-group row label-box-div">
                                    <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Dat. od:</label>
                                    <div className="col-2" style={{ marginRight: 'auto' }}>
                                        <DatePicker
                                            tabIndex="7"
                                            id="media-query-input"
                                            dateFormat="dd.MM.yyyy"
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)} />
                                    </div>



                                    <label className="col-form-label col-lg-6 label-box" id="media-query-label-to">Dat. do:</label>
                                    <div className="col-2" style={{ marginRight: 'auto' }}>
                                        <DatePicker
                                            tabIndex="8"
                                            id="media-query-input-to"
                                            dateFormat="dd.MM.yyyy"
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Col>
                    <Col lg='4'>
                        <Row>
                            <Col lg='12' >
                                <div className=" flex-lg-row justify-content-end" id='media-query-buttonType'>
                                    <div style={{ display: 'inline-grid' }} className="btn-report">
                                        <button tabIndex={9} onClick={() => {
                                            if (startDate && endDate) {
                                                setButtonTypeTotal('dnevnik')
                                                setButtonType(null)
                                                setButtonOnClick(!buttonOnClick)
                                                getReports(1, perPage)
                                                setModalErrorOpen(false)
                                            } else {
                                                setModalErrorOpen(true)
                                            }

                                        }} > Dnevnik</button>
                                        <button tabIndex={10} onClick={() => {
                                            if (startDate && endDate) {
                                                setButtonTypeTotal('dnevnikNaDan')
                                                setButtonType(null)
                                                setButtonOnClick(!buttonOnClick)
                                                getReports(1, perPage)
                                                setModalErrorOpen(false)
                                            } else {
                                                setModalErrorOpen(true)
                                            }
                                        }
                                        }>Dnevnik na dan</button>

                                    </div>
                                    <div style={{ display: 'inline-grid' }} className="btn-report">
                                        <button tabIndex={12} onClick={() => {
                                            if (startDate && endDate) {
                                                setButtonType('pregled')
                                                setButtonTypeTotal(null)
                                                getReports(1, perPage)
                                                setButtonOnClick(!buttonOnClick)
                                                setModalErrorOpen(false)
                                            } else {
                                                setModalErrorOpen(true)
                                            }
                                        }}>Pregled Blagajne</button>


                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </div>
            </Container >

            <div id='media-query-datatable'>
                {
                    < DataTable
                        columns={buttonType ? pregled : dnevnik}
                        data={blagajnaReport ? blagajnaReport : []}
                        pagination
                        paginationServer
                        paginationTotalRows={total}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handlePerRowsChange}
                        responsive
                        striped
                        dense
                        pointerOnHover
                        paginationComponentOptions={defaultComponentOptions}
                    />
                }
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <label className="col-form-label col-lg-2 label-box" id="media-query-label-sum">Ukupno:</label>
                <input style={{ marginLeft: '10px', maxWidth: '10%', textAlign: 'right', paddingRight: '5px' }} type='text' id="media-query-input-text" value={sum && sum != 'NaN' ? numberWithCommas(sum) : 0} />
            </div>

            <div>
                <Modal isOpen={modalErrorOpen} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja(skladište, godinu, datum od i datum do)!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalErrorOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}

            <div>
                <Modal isOpen={modalErrorOpenPrint} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Izaberite izvještaj koji želite štampati!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenPrint(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={modalErrorOpenData} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* spiner ili progres bar ya prikaz pokretanja datog izvjestaja */}
            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

            {/* {spinner ? <Progress
                animated
                bar
                color="success"
                value="100" >
                Loading
            </Progress> : ''} */}
        </div >
    );

}

export default BlagajnaReport;





