import React from 'react';
import * as RiIcons from 'react-icons/ri';
import SVG from 'react-inlinesvg';
import homeIcon from '../assets/home.svg';
import listIcon from '../assets/list.svg';
import bookIcon from '../assets/book.svg';
import cubeIcon from '../assets/cube.svg';
import calculatorIcon from '../assets/calculator.svg';
import paperIcon from '../assets/paper.svg';
import settingsIcon from '../assets/settings.svg';
import supportIcon from '../assets/support.svg';
import pencilIcon from '../assets/pencil1.svg';
import { ovlascenje, ovlfik, ovlrobno, ovlfakt, ovlblagajna } from '../components/utilities';

//const [visible, setVisible] = useState(false)
export const SidebarData = [
  {
    title: 'Početna',
    path: '/',
    icon: <SVG src={homeIcon} />,
    visible: true,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  // {
  //   title: 'Robno',
  //   path: '/',
  //   icon: <SVG src={bookIcon} />,
  //   visible: ovlrobno != 1,
  //   cName: 'nav-text',
  //   subNav: [
  //     {
  //       title: 'Promet',
  //           path: '/',
  //           icon: <SVG src={listIcon} />,
  //           visible: true,
  //           cName: 'nav-text',
  //       subNavItems: [
  //         {
  //           title: 'Predračun',
  //           path: '/predracun',
  //           icon: <SVG src={listIcon} />,
  //           visible: true,
  //           cName: 'nav-text'
  //         },
  //       ]
  //     },
  //   ]
  // },
  // {
  //   title: 'Registri',
  //   path: '/',
  //   icon: <SVG src={bookIcon} />,
  //   visible: ovlascenje != 2 && ovlfakt != 2 && ovlfakt != 1,
  //   cName: 'nav-text',
  //   subNav: [
  //     // {
  //     //   title: 'Ambalaža',
  //     //   path: '/ambalaza',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     {
  //       title: 'Država',
  //       path: '/country',
  //       visible: true,
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     // {
  //     //   title: 'Konto',
  //     //   path: '/konto',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     {
  //       title: 'Regija',
  //       path: '/region',
  //       visible: true,
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     {
  //       title: 'Komitent',
  //       path: '/client',
  //       visible: true,
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     // {
  //     //   title: 'Artikal',
  //     //   path: '/artikal',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     // {
  //     //   title: 'Brend',
  //     //   path: '/brend',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     // {
  //     //   title: 'Opis za art.',
  //     //   path: '/opza',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     // {
  //     //   title: 'Podgrupa',
  //     //   path: '/podgrupa',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     // {
  //     //   title: 'Poslovna',
  //     //   path: '/poslovna',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     // {
  //     //   title: 'Smjena',
  //     //   path: '/smjena',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     // {
  //     //   title: 'Trošak',
  //     //   path: '/trosak',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     // {
  //     //   title: 'Valuta',
  //     //   path: '/valuta',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     // {
  //     //   title: 'Vozila',
  //     //   path: '/vozila',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     // {
  //     //   title: 'Grupa',
  //     //   path: '/grupa',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     {
  //       title: 'Preduzeće',
  //       path: '/company',
  //       //  visible: ovlfakt != 2,
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     {
  //       title: 'Usluge',
  //       path: '/serviceCharge',
  //       visible: true,
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     // {
  //     //   title: 'Usluga za kom.',
  //     //   path: '/serviceChargeForClients',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     {
  //       title: 'Mjesta',
  //       path: '/places',
  //       visible: true,
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     {
  //       title: 'Putnik',
  //       path: '/commercialist',
  //       visible: true,
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text',
  //     },
  //     {
  //       title: 'Porez',
  //       path: '/tax',
  //       visible: true,
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     {
  //       title: 'Napomena',
  //       path: '/note',
  //       visible: true,
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     {
  //       title: 'Skladište',
  //       path: '/warehouse',
  //       visible: true,
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     // {
  //     //   title: 'Vrsta dok.',
  //     //   path: '/vrstaDok',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     // {
  //     //   title: 'Vrsta knj.',
  //     //   path: '/blagdok',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //     // {
  //     //   title: 'Lager',
  //     //   path: '/lager',
  //     //   icon: <SVG src={listIcon} />,
  //     //   cName: 'nav-text'
  //     // },
  //   ]
  // },
  {
    title: 'Fakturisanje',
    path: '/',
    icon: <SVG src={bookIcon} />,
    visible: ovlfakt != 1,
    cName: 'nav-text',
    subNav: [
      {
        title: 'Registri',
        path: '/',
        icon: <SVG src={pencilIcon} />,
        visible: ovlascenje != 2 && ovlfakt != 2,
        cName: 'nav-text',
        subSubNav: [
          {
            title: 'Država',
            path: '/country',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Regija',
            path: '/region',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Komitent',
            path: '/client',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Preduzeće',
            path: '/company',
            //  visible: ovlfakt != 2,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Usluge',
            path: '/serviceCharge',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Mjesta',
            path: '/places',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Putnik',
            path: '/commercialist',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text',
          },
          {
            title: 'Porez',
            path: '/tax',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Napomena',
            path: '/note',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          },
          {
            title: 'Skladište',
            path: '/warehouse',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          }
        ],
      },
      {
        title: 'Promet',
        path: '/',
        icon: <SVG src={cubeIcon} />,
        visible: ovlascenje != 2 && ovlfakt != 2 && ovlfakt != 3,
        cName: 'nav-text',
        subSubNav: [
          {
            title: 'Faktura',
            path: '/invoices',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text'
          }
        ]
      },
      {
        title: 'Izvještaji',
        path: '/',
        icon: <SVG src={calculatorIcon} />,
        visible: true,
        cName: 'nav-text',
        subSubNav: [
          {
            title: 'Izvještaj',
            path: '/invoiceReport',
            visible: true,
            icon: <SVG src={listIcon} />,
            cName: 'nav-text',
          }
        ]
      },
    ],
  },
  // {
  //   title: 'Finansije',
  //   path: '/',
  //   icon: <SVG src={bookIcon} />,
  //   visible: ovlfik != 1,
  //   cName: 'nav-text',
  //   subNav: [
  //     {
  //       title: 'Nalog',
  //       path: '/finNalog',
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     {
  //       title: 'Dokument',
  //       path: '/dokument',
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     {
  //       title: 'Izvještaji',
  //       path: '/finreport',
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     {
  //       title: 'BrutoBilans',
  //       path: '/finBrutoBilans',
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     {
  //       title: 'Zaključni list',
  //       path: '/finBrutoBilans1',
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     {
  //       title: 'Prikaz po vrsti k.',
  //       path: '/prikazPoVrstiKnjizenja',
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //   ]
  // } ,
  // {
  //   title: 'Blagajna',
  //   path: '/',
  //   icon: <SVG src={bookIcon} />,
  //   visible: ovlblagajna != 1,
  //   cName: 'nav-text',
  //   subNav: [
  //     {
  //       title: 'Blagajna',
  //       path: '/blagajna',
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text'
  //     },
  //     {
  //       title: 'Izvještaj',
  //       path: '/blagajnaReport',
  //       icon: <SVG src={listIcon} />,
  //       cName: 'nav-text',
  //     }
  //   ]
  // },
  // {
  //   title: 'Pregled',
  //   path: '/pregledPoVrstiIKomitentu',
  //   icon: <SVG src={settingsIcon} />,
  //   visible: true,
  //   cName: 'nav-text'
  // },
  {
    title: 'Servis',
    path: '/',
    icon: <SVG src={settingsIcon} />,
    visible: true,
    cName: 'nav-text'
  },
  {
    title: 'Podrška',
    path: '/',
    icon: <SVG src={supportIcon} />,
    visible: true,
    cName: 'nav-text'
  },
];
