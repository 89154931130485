// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from '../api/axios';
import { Spinner } from "reactstrap";
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import { NumericFormat } from 'react-number-format';
import { useWindowSize } from '../components/useWindowSize';

// komponenta koju kreiramo
function Places() {

  const windowSize = useWindowSize();
  // definisanje konstanti koje koristimo za fokusiranje polja
  const codeRef = useRef();
  const nameRef = useRef();
  const regionRef = useRef();
  const countryRef = useRef();
  const postRef = useRef();

  // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
  const [places, setPlaces] = useState(null)
  const [perPage, setPerPage] = useState(10);
  const [region, setRegion] = useState(null);
  const [country, setCountry] = useState(null);
  const [selectDataRegion, setSelectDataRegion] = useState([]);
  const [selectDataCountry, setSelectDataCountry] = useState([]);
  const [searchInput, setSearchInput] = useState([]);
  const [modalErrorOpen, setModalErrorOpen] = useState(false);
  const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
  const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);

  // povlacenje podataka iz lokal storidza
  const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null

  const [errorInputCode, setErrorInputCode] = useState(false);
  const [errorInputName, setErrorInputName] = useState(false);
  const [errorMsgCode, setErrorMsgCode] = useState(false);
  const [errorMsgName, setErrorMsgName] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [pending, setPending] = React.useState(false);

  // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
  const [form, setForm] = useState({
    SIFRA: '',
    NAZIV: '',
    POSTANSKIBROJ: '',
    REGIJA: '',
    DRZAVA: ''
  })

  // funkcija za povlacenje podataka o preduzecu
  getCompanyData(form)

  // funkcija koja se poziva klikom na dugme 'Sacuvaj'
  const onSubmit = () => {

    // uslov: ako nisu unesena obavezna polja, zaustavlja upis
    if (!unosIspravanDo('UPIS')) return;

    fetch(API + '/places/new', {
      method: 'POST',
      body: JSON.stringify(form),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then((response) => {
        response.json()
        setForm({
          SIFRA: '',
          NAZIV: '',
          POSTANSKIBROJ: '',
          REGIJA: '',
          DRZAVA: '',
          stavkaPronadjena: false
        })
        setRegion(null)
        setCountry(null)
        codeRef.current.focus();
        getTableData()
      })
  }

  // funkcija koja se poziva pri prvoj inicijalizaciji
  // api za mrezu
  // u responsu puni mrezi(pomocu funkcije setCountry) i puni ukupan broj stranica(setTotal)
  const getTableData = () => {
    fetch(API + `/places/all`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then((response) => response.json())
      .then(data => {
        setPlaces(data.result)
        setPending(false)
      })
  }

  // poziva se inicijalno pri otvaranju forma i poziva api za popunjavanje tabele
  // postavlja fokus na polje mjesto
  useEffect(() => {
    getRegionData()
    getCountryData()
  }, [!region && !country])

  // prilikom pocetne inicijalizacije postavlja prvi slobodan broj
  useEffect(() => {
    const timeout = setTimeout(() => {
      getPlacesFirstFreeNumberSifra()
      getTableData()
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // funkcija za citanje regija iz baze
  const getRegionData = () => {
    fetch(API + `/region/all`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then((response) => response.json())
      .then(data => {
        setSelectDataRegion(data)
      })
  }

  // funkcija za citanje drzava iz baze
  const getCountryData = () => {
    fetch(API + `/country/all`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then((response) => response.json())
      .then(data => {
        setSelectDataCountry(data)
      })
  }

  // funkcija za pronalazenje prvog slobodnog broja
  // poziva api
  // u responsu stejt setForm poziva funkciju za pronalazenje prethodne sifre(iz data) i na osnovu nje odredjuje koja je sljedeca prva slobodna sifra
  const getPlacesFirstFreeNumberSifra = () => {
    fetch(API + `/places/firstFreeNumberSifra`, {
      method: 'POST',
      body: JSON.stringify(form),
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then((response) => response.json())
      .then(data => {
        setForm(prev => ({ ...prev, SIFRA: Number(data[0].SIFRA) }))
      })
  }

  // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
  //  api za brisanje
  // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
  const onDelete = (SIFRA) => {
    // provjera ovlascenja za brisanje 
    if (form.ovlfakt == 4) return setModalErrorAuthorizationOpen(true)

    fetch(API + '/places/delete/' + SIFRA, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then((response) => {
        response.json()
        setForm({
          SIFRA: '',
          NAZIV: '',
          POSTANSKIBROJ: '',
          REGIJA: '',
          DRZAVA: '',
          stavkaPronadjena: false
        })
        codeRef.current.focus()
        getTableData()
      })
  }

  // funkcija koja se poziva klikom na dugme Edit
  // postavlja fokus na polje NAZIV 
  // popunjava polja podacima iz mreze
  const onEdit = (item) => {
    item.stavkaPronadjena = true
    setRegion(null)
    setCountry(null)

    selectDataRegion.map((region) => {
      if (region.SIFRA == item.REGIJA) {
        setRegion(region)
      }
    })

    selectDataCountry.map((country) => {
      if (country.SIFRA == item.DRZAVA) {
        setCountry(country)
      }
    })

    setForm(item)
    // zaustavljanje fokusa(pauza za fokus dok ne odradi upis) 
    const timeout = setTimeout(() => {
      nameRef.current.focus()
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }

  // funkcija koja se poziva prilikom promjene vrijednosti u inputu
  const handleChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = event.target.name;

    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));

  };

  // funkcija koja se poziva klikom na dugme 'Stampa'
  const onPrint = () => {
    setSpinner(true)
    if (places && places.length === 0) {
      setSpinner(false)
      setModalErrorOpenData(true)
    } else {
      // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
      // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
      // poziva api za stampu, otvara izvjestaj(API + data.link)
      if (form) {
        form.NAZIV_PRED = form.companyName
      }
      fetch(API + `/places/print`, {
        method: 'POST',
        body: JSON.stringify(form),
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-type': 'application/json; charset=UTF-8'
        },
      })
        .then((response) => response.json())
        .then(data => {
          if (typeof window != 'undefined') {
            setTimeout(() => {
              window.open(API + data.link)
            }, 200);
          }
          setSpinner(false)
        })
    }
  }

  // propsovi za auto komplit  - regija
  const defaultPropsRegion = {
    options: selectDataRegion,
    getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
  };

  // propsovi za auto komplit  - drzava
  const defaultPropsCountry = {
    options: selectDataCountry,
    getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
  };

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran dokument, izbaci poruku, setuje crveni okvir i fokusira polje  
  // u suprotnom prolazi dalje
  const checkCodeRequired = (value) => {
    if (!value || value == 0) {
      setErrorMsgCode('Šifra je obavezna!')
      setErrorInputCode(true)
      codeRef.current.focus();
      return false
    } else {
      setErrorMsgCode(null)
      setErrorInputCode(false)
      return true
    }
  }

  // uslov: ako je upisana neka vrijednost, a nije odabrana nijedna od ponudjenih opcija(nije kliknuto) ili ako nije upisana ni vrijednost niti je odabran dokument, izbaci poruku, setuje crveni okvir i fokusira polje  
  // u suprotnom prolazi dalje
  const checkNameRequired = (value) => {
    if (!value || value == 0) {
      setErrorMsgName('Naziv je obavezan!')
      setErrorInputName(true)
      nameRef.current.focus();
      return false
    } else {
      setErrorMsgName(null)
      setErrorInputName(false)
      return true
    }
  }

  // funkcija za kontrolu obaveznih polja
  function unosIspravanDo(name) {
    if (name === 'SIFRA') {
      return true
    }

    if (!checkCodeRequired(form.SIFRA)) {
      return false
    }

    if (name === 'NAZIV') {
      return true
    }

    if (!checkNameRequired(form.NAZIV)) {
      return false
    }
    return true
  }

  const columns = [
    {
      name: 'Šifra',
      selector: row => row.SIFRA,
       // sirina polja po rezolucijama
      width:
        windowSize.width > 1920 ? '100px'
          : windowSize.width > 1680 ? '100px'
            : windowSize.width > 1600 ? '100px'
              : windowSize.width > 1440 ? '100px'
                : windowSize.width > 1280 ? '100px'
                  : windowSize.width > 1024 ? '80px'
                    : '80px',
      right: 'boolean',
      cell: (row) => (
        <>
          <span id='media-query-rows'>{row.SIFRA}</span>
        </>
      ),
    },
    {
      name: 'Naziv',
      selector: row => row.NAZIV,
       // sirina polja po rezolucijama
      width:
        windowSize.width > 1920 ? '520px'
          : windowSize.width > 1680 ? '500px'
            : windowSize.width > 1600 ? '410px'
              : windowSize.width > 1440 ? '380px'
                : windowSize.width > 1280 ? '300px'
                  : windowSize.width > 1024 ? '410px'
                    : '220px',
      cell: (row) => (
        <>
          <span id='media-query-rows-text'>{row.NAZIV}</span>
        </>
      ),
    },
    {
      name: 'Poštanski broj',
      selector: row => row.POSTANSKIBROJ,
       // sirina polja po rezolucijama
      width:
        windowSize.width > 1920 ? '350px'
          : windowSize.width > 1680 ? '340px'
            : windowSize.width > 1600 ? '240px'
              : windowSize.width > 1440 ? '220px'
                : windowSize.width > 1280 ? '220px'
                  : windowSize.width > 1024 ? '220px'
                    : '200px',
      right: 'boolean',
      cell: (row) => (
        <>
          <span id='media-query-rows'>{row.POSTANSKIBROJ}</span>
        </>
      ),
    },
    {
      name: 'Regija',
      selector: row => row.regijaNaziv,
       // sirina polja po rezolucijama
      width:
        windowSize.width > 1920 ? '320px'
          : windowSize.width > 1680 ? '270px'
            : windowSize.width > 1600 ? '250px'
              : windowSize.width > 1440 ? '240px'
                : windowSize.width > 1280 ? '200px'
                  : windowSize.width > 1024 ? '220px'
                    : '200px',
      cell: (row) => (
        <>
          <span id='media-query-rows-text'>{row.regijaNaziv}</span>
        </>
      ),
    },
    {
      name: 'Država',
      selector: row => row.drzavaNaziv,
       // sirina polja po rezolucijama
      width:
        windowSize.width > 1920 ? '320px'
          : windowSize.width > 1680 ? '270px'
            : windowSize.width > 1600 ? '250px'
              : windowSize.width > 1440 ? '230px'
                : windowSize.width > 1280 ? '200px'
                  : windowSize.width > 1024 ? '220px'
                    : '200px',
      cell: (row) => (
        <>
          <span id='media-query-rows-text'>{row.drzavaNaziv}</span>
        </>
      ),
    },
    {
      name: 'Action',
      cell: (row) => (
        < div className='tableCells nav flex-column  flex-lg-row'>
          <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary naslov-ikonice nav flex-column flex-lg-row justify-content-end'>Edit</span>{'     '}
          <span onClick={() => onDelete(row.SIFRA)} id='media-query-table-buttons' className='btn btn-danger naslov-ikonice nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
        </div>
      ),
    }
  ];

  // funkcija za pretragu iz mreze
  // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
  // u responsu vraca podatke koje dobio iz upita 
  const searchChange = (e) => {
    e.preventDefault();
    const searchInput = e.target.value
    setSearchInput(searchInput);
    const fetchData = async () => {
      const res = await axios.get(`${API}/places/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
      setPlaces(res.data);
    };

    // uslov za za slanje zahtjeva za povlacenje podataka
    if (searchInput.length === 0 || searchInput.length >= 1) fetchData();

  };

  return (

    <div>
      {/* Kontejner za naslov  */}
      <div className="title-container">

        {/* Naziv registra */}
        <header className="title-header">
          <h1 id="media-query-title">Mjesta</h1>
        </header>

        {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}
        <div className="naslov-ikonice">
          <ul className="nav flex-column flex-lg-row justify-content-end">

            <li className="nav-item toolbaritem">
              <input type="image" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="6" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
            </li>

            <li className="nav-item toolbaritem">
              <input type="image" className='dugme-stampa' title='Štampa' tabIndex="7" src={require('./../assets/images/printer.png')} onClick={onPrint} />
            </li>

          </ul>
        </div>
      </div>

      {/* Kontejner pune sirine */}
      <Container fluid>
        <Row>
          <div className='products-form'>
            <Row>
              <div className="col-6" style={{ display: 'flex', marginBottom: '0px', width: '30%', marginRight: 'inherit' }}>
                {/* Sirina polja */}
                <div className="col-2">
                  <div className='col-form-right'>
                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                    <div className={errorInputCode ? 'my-input' : 'field-container-number'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                      <label id="media-query-label">Šifra:</label>
                      <NumericFormat
                        type="number"
                        getInputRef={codeRef}
                        maxLength="10"
                        decimalScale={0}
                        id="media-query-input-text"
                        tabIndex="1"
                        autoFocus={true}
                        placeholder="Šifra"
                        name='SIFRA'
                        onFocus={() => {
                          getPlacesFirstFreeNumberSifra()
                          unosIspravanDo("SIFRA")
                        }}
                        value={form?.SIFRA}
                        // Ako je izmjena na polju
                        onChange={(e) => handleChange(e)}
                        onClick={() => {
                          // ako je odradjen dvoklik na stavku u mrezi, cisti polja  
                          setForm(prev => ({
                            ...prev, NAZIV: '',
                            POSTANSKIBROJ: '',
                            REGIJA: '',
                            DRZAVA: '', stavkaPronadjena: false
                          }))
                          setCountry(null)
                          setRegion(null)
                        }} />
                      {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}
                    </div>
                  </div>
                </div>

                {/* Sirina polja */}
                <div className="col-10">
                  <div className={errorInputName ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                    <label id="media-query-label">Naziv:</label>
                    <input type="text"
                      id="media-query-input-text"
                      maxLength="45"
                      ref={nameRef}
                      tabIndex="2"
                      placeholder="Naziv"
                      name='NAZIV'
                      value={form ? form.NAZIV : ''}
                      onFocus={() => {
                        unosIspravanDo("NAZIV")
                      }}
                      // Ako je izmjena na polju
                      onChange={(e) => handleChange(e)}
                    />
                    {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}
                  </div>
                </div>
              </div>

              {/* Sirina polja */}
              <div className="col-7" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '3px', marginRight: 'auto', marginLeft: '0px' }}>
                <div className="col-2">
                  <div className='col-form-right'>
                    <div className='field-container-number' style={{ marginRight: '3px' }}>
                      <label id="media-query-label">Pošt. br.:</label>
                    </div>
                    <NumericFormat
                      type="number"
                      getInputRef={postRef}
                      maxLength="10"
                      decimalScale={0}
                      id="media-query-input-text"
                      tabIndex="3"
                      placeholder="Poštanski broj"
                      name='POSTANSKIBROJ'
                      value={form ? form.POSTANSKIBROJ : ''}
                      onFocus={() => {
                        unosIspravanDo("POSTANSKIBROJ")
                      }}
                      // Ako je izmjena na polju
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>

                {/* Sirina polja */}
                <div className="col-3">
                  <div className='field-container' style={{ marginRight: '3px' }}>
                    <label id="media-query-label">Regija:</label>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      {...defaultPropsRegion}
                      value={region}
                      disablePortal={true}
                      onFocus={() => {
                        unosIspravanDo("REGIJA")
                      }}
                      onChange={(event, newValue) => {
                        setForm(prev => ({ ...prev, REGIJA: newValue && newValue != null ? newValue.SIFRA : null }))
                        setRegion(newValue);
                        // fokus se mora vratiti inace tabIndex ne radi dobro
                        regionRef.current.focus();
                      }}
                      renderInput={(params) => (
                        <TextField {...params}
                          className="clients-textinput"
                          tabIndex='4'
                          name='REGIJA'
                          ref={regionRef}
                          placeholder=' Izaberite regiju'
                          variant="standard" />
                      )}
                    />
                  </div>
                </div>

                {/* Sirina polja */}
                <div className="col-3">
                  <div className='field-container' style={{ marginRight: '3px' }}>
                    <label id="media-query-label">Država:</label>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      {...defaultPropsCountry}
                      value={country}
                      disablePortal={true}
                      onFocus={() => {
                        unosIspravanDo("DRZAVA")
                      }}
                      // ako se promijeni artikal, uzima podatke za novi izabrani artikal
                      onChange={(event, newValue) => {
                        setForm(prev => ({ ...prev, DRZAVA: newValue && newValue != null ? newValue.SIFRA : null }))
                        setCountry(newValue);
                        // fokus se mora vratiti inace tabIndex ne radi dobro
                        countryRef.current.focus();
                      }}
                      renderInput={(params) => (
                        <TextField {...params}
                          className="clients-textinput"
                          tabIndex='5'
                          name='DRZAVA'
                          // fokus se mora vratiti inace tabIndex ne radi dobro(ne ide na upis)
                          onFocus={() => {
                            countryRef.current.focus();
                          }}
                          ref={countryRef}
                          placeholder=' Izaberite državu'
                          variant="standard" />
                      )}
                    />
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Row>
      </Container>

      {/* Stilovi i klase za polje za pretragu;  */}
      <div style={{ display: 'flex' }} className='search'>
        {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
        <input type='text' tabIndex='-1' id="media-query-input-text" placeholder='Pretraga...'
          onChange={(e) => {
            // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
            if (searchInput.length >= 0) {
              searchChange(e)
            } else {
              getTableData()
            }
          }}
          value={searchInput} />
      </div>

      {/* render za mrezu */}
      <DataTable
        columns={columns}
        data={places ? places : []}
        pagination
        responsive
        highlightOnHover
        striped
        dense
        pointerOnHover
        paginationComponentOptions={defaultComponentOptions}
        progressPending={pending}
      />

      {/* render za poruku */}
      <div>
        <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered  >
          <ModalHeader>Upozorenje!</ModalHeader>
          <ModalBody>
            <span>{`Nemate ovlašćenje za brisanje!`}</span>
          </ModalBody>
          <ModalFooter style={{justifyContent: 'center'}}>
            <div>
              <button onClick={() => setModalErrorAuthorizationOpen(false)} className="button-yes">Ok</button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}
      <div>
        <Modal isOpen={Boolean(modalErrorOpen)} centered  >
          <ModalHeader>Neispravan unos</ModalHeader>
          <ModalBody>
            <span>{`Unesite obavezna polja!`}</span>
          </ModalBody>
          <ModalFooter style={{justifyContent: 'center'}}>
            <div>
              {/* Dugme za zatvaranje modalnog forma */}
              <button onClick={() => setModalErrorOpen(false)} className="button-yes">Ok</button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        <Modal isOpen={Boolean(modalErrorOpenData)} centered  >
          <ModalHeader>Nema podataka!</ModalHeader>
          <ModalFooter style={{justifyContent: 'center'}}>
            <div>
              {/* Dugme za zatvaranje modalnog forma */}
              <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
            </div>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
      </div>

    </div >
  );

}

export default Places;


