// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from '../api/axios';
import { Spinner } from "reactstrap";
// komponenta koju kreiramo
function Opza() {
    // definisanje konstanti koje koristimo za fokusiranje polja


    const opisRef = useRef();
    const entityRef = useRef();
    const vatNumberRef = useRef();
    const placeRef = useRef();
    const vatPayerRef = useRef();
    const artikalRef = useRef();
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat

    const [opza, setOpza] = useState(null)
    const [opzaAll, setOpzaAll] = useState(null)
    const [total, setTotal] = useState(0)
    const [perPage, setPerPage] = useState(10);
    const [tax, setTax] = useState(null);
    const [VATPayer, setVATPayer] = useState(null);
    const [selectData, setSelectData] = useState([]);
    const [selectDataVATPayer, setSelectDataVATPayer] = useState([]);
    const [searchInput, setSearchInput] = useState([]);
    const [editOpza, setEditOpza] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    // povlacenje podataka iz lokal storidza

    const companyCode = typeof window !== 'undefined' ? localStorage.getItem('company') : null
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null
    const companyNaziv = typeof window !== 'undefined' ? localStorage.getItem('companyNaziv') : null
    const userCode = typeof window !== 'undefined' ? localStorage.getItem('userCode') : null

    // error row inputs
    const [errorInputArtikal, setErrorInputArtikal] = useState(false);
    const [errorInputOpis, setErrorInputOpis] = useState(false);
    const [errorInputEntity, setErrorInputEntity] = useState(false);
    const [errorInputVATPayer, setErrorInputVATPayer] = useState(false);
    const [errorInputVATNumber, setErrorInputVATNumber] = useState(false);

    // error messages inputs
    const [errorMsgArtikal, setErrorMsgArtikal] = useState('');
    const [errorMsgOpis, setErrorMsgOpis] = useState('');
    const [errorMsgEntity, setErrorMsgEntity] = useState('');
    const [errorMsgVATPayer, setErrorMsgVATPayer] = useState('');
    const [errorMsgVATNumber, setErrorMsgVATNumber] = useState('');

    const [codeFocus, setCodeFocus] = useState(false);
    const [opisFocus, setOpisFocus] = useState(false);
    const [entityFocus, setEntityFocus] = useState(false);
    const [vatNumberFocus, setVATNumberFocus] = useState(false);
    const [artikalFocus, setArtikalFocus] = useState(false);
    const [artikal, setArtikal] = useState(null);
    const [VATPayerFocus, setVATPayerFocus] = useState(false);
    const [addressFocus, setAddressFocus] = useState(false);
    const [spinner, setSpinner] = useState(false);
    // niz prema kome se formira pagination u mrezi(pri kreiranju 'DataTable')

    const defaultComponentOptions = {
        rowsPerPageText: 'Redovi po stranici:',
        rangeSeparatorText: 'od',
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Sve',
    };
    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija

    const [form, setForm] = useState({
        OPIS1: '',
        OPIS2: '',
        OPIS3: '',
        NOMENID: 0
    })
    // funkcija koja se poziva klikom na dugme 'Sacuvaj'

    const onSubmit = () => {
        // provjera obaveznih polja na dugmetu
        // ukoliko nisu popunjena obavezna polja, zaustavlja akciju i prikazuje upozorenje(poruka)
        // ukoliko su popunjena obavezna polja, upada u else i izvrsava se funkcija

        if (form.NOMENID == " " || form.NOMENID == 0 || form.OPIS1 == " " || form.OPIS1 == 0) {
            setModalErrorOpen(true)
            //     artikalRef.current.focus()
            opisRef.current.focus()
        } else {

            if (form && form.NOMENID && editOpza) {
                fetch(API + `/opza/update`, {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            OPIS1: '',
                            OPIS2: '',
                            OPIS3: '',
                            NOMENID: 0
                        })
                        setArtikal(null)
                        artikalRef.current.focus()
                        getTableData(1, perPage)
                    })

            }
            // u suprotnom ide na upis sloga
            // poziva api za upis
            // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)

            else {
                fetch(API + `/opza/new`, {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((response) => {
                        response.json()
                        setForm({
                            OPIS1: '',
                            OPIS2: '',
                            OPIS3: '',
                            NOMENID: 0
                        })
                        setArtikal(null)
                        artikalRef.current.focus()
                        getTableData(1, perPage)
                    })
            }
        }
    }
    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setIme) i puni ukupan broj stranica(setTotal)

    const getTableData = (page, limit) => {
        fetch(API + `/opza/all/${page}/${limit}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                // popuni mrezu i postavi podatke o komintentima
                setOpza(data.result)
                // postavi iyracunati total na serveru
                setTotal(data.total)
                //poziva funkcija prva slobodna sifra

            })
    }

    // poziva se inicijalno pri otvaranju forma i poziva api za popunjavanje tabele
    // postavlja fokus na polje sifra
    // prati promjenu state perPage 

    useEffect(() => {
        // getVATPayerData()
        getTableData(1, perPage)
        artikalRef.current.focus();
    }, [perPage])
    // poziva se inicijalno pri otvaranju forma i poziva api za popunjavanje tabele
    // postavlja fokus na polje mjesto

    useEffect(() => {
        getArtikalData()
    }, [!selectData])


    // funkcija za pronalazenje mjesta i odabir postojeceg

    const getArtikalData = () => {
        fetch(API + `/artikal/all`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setSelectData(data)
            })
    }

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  

    const onDelete = (NOMENID) => {
        fetch(API + '/opza/delete/' + NOMENID, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    OPIS1: '',
                    OPIS2: '',
                    OPIS3: '',
                    NOMENID: 0
                })
                setArtikal(null)
                artikalRef.current.focus()
                getTableData(1, perPage)
            })
    }
    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze

    const onEdit = (item) => {
        opisRef.current.focus()
        setArtikal(null)
        selectData.map((artikal) => {
            if (artikal.ID == item.NOMENID) {
                setArtikal(artikal)
            }
        })

        setForm(item)
        setEditOpza(true)
    }
    // funkcija koja se poziva klikom na dugme 'Stampa'

    const onPrint = () => {
        setSpinner(true)
        if (opza && opza.length === 0) {
            setSpinner(false)
            setModalErrorOpenData(true)
        } else {
            // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
            // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
            // poziva api za stampu, otvara izvjestaj(API + data.link)

            if (form) {
                form.NAZIV_PRED = companyNaziv
            }

            fetch(API + `/opza/print/${companyNaziv}`, {
                method: 'POST',
                body: JSON.stringify(form),
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },

            }).then((response) => response.json())
                .then(data => {
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            window.open(API + data.link)
                        }, 500);

                    }
                    setSpinner(false)
                })
        }
    }
    // funkcija koja se poziva prilikom promjene vrijednosti u inputu

    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));

    };

    // funkcija za validaciju broja i obaveznog polja

    const checkOpisRequired = (value) => {
        if (!value || value.length == 0) {
            setErrorMsgOpis('Opis je obavezan!')
            setErrorInputOpis(true)
            artikalRef.current.focus();
            setOpisFocus(true)
        } else {
            setErrorMsgOpis(null)
            setErrorInputOpis(false)
        }
    }

    // funkcija za validaciju broja i obaveznog polja

    const checkEntityRequired = (value) => {
        if (!value || value.length == 0) {
            setErrorMsgEntity('Entitet je obavezan!')
            setErrorInputEntity(true)
            entityRef.current.focus();
        } else {
            setErrorMsgEntity(null)
            setErrorInputEntity(false)
        }
    }
    // funkcija za validaciju broja i obaveznog polja

    const checkVATNumberRequired = (value) => {
        if (!value || value.length == 0) {
            setErrorMsgVATNumber('PDV broj je obavezan!')
            setErrorInputVATNumber(true)
            vatNumberRef.current.focus();
        } else {
            setErrorMsgVATNumber(null)
            setErrorInputVATNumber(false)
        }
    }
    // funkcija za validaciju broja i obaveznog polja

    const checkVATPayerRequired = (value) => {
        if (!value || value.length == 0) {
            setErrorMsgVATPayer('Porez je obavezan!')
            setErrorInputVATPayer(true)
            vatPayerRef.current.focus();
        } else {
            setErrorMsgVATPayer(null)
            setErrorInputVATPayer(false)
        }
    }
    // validacija praznog polja
    const helperValidateEmptyField = (input) => {
        if (input.length < 1) return 'Polje je obavezno!';
    }

    // stepenasta
    const formCheck = (event) => {

        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        switch (name) {
            case 'NOMENID': {
                setErrorMsgArtikal(helperValidateEmptyField(value))
                setErrorInputArtikal(helperValidateEmptyField(value) ? true : false)
                break;

            }
            case 'OPIS1': {
                setErrorMsgOpis(checkOpisRequired(value))
                setErrorInputOpis(checkOpisRequired(value) ? true : false)
                break;
            }

        }
        if (name === 'NOMENID') {
            setCodeFocus(false)
        }

        if (name === 'OPIS1') {
            setOpisFocus(false)
        }

    }

    useEffect(() => {
        if (opisRef.current && errorInputOpis) {
            opisRef.current.focus();
        }
    }, [!opisFocus]);

    // propsovi za auto komplit  - artikal
    const defaultProps = {
        options: selectData,
        getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
    };
    // definisu se polja u tabeli i popunjavaju

    const columns = [
        {
            name: 'Sifra',
            selector: row => row.nomenSifra,
            width: '100px',
            sortable: true,
            right: 'boolean',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.nomenSifra}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.nomenNaziv,
            width: '400px',
            sortable: true,
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.nomenNaziv}</span>
                </>
            ),
        },
        {
            name: 'Opis 1',
            selector: row => row.OPIS1,
            sortable: true,
            width: '350px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OPIS1}</span>
                </>
            ),
        },
        {
            name: 'Opis 2',
            selector: row => row.OPIS2,
            sortable: true,
            width: '350px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OPIS2}</span>
                </>
            ),
        },
        {
            name: 'Opis 3',
            selector: row => row.OPIS3,
            sortable: true,
            width: '350px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.OPIS3}</span>
                </>
            ),
        },
        {
            name: 'Action',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary nav flex-column flex-lg-row justify-content-end'>Edit</span>{'    '}
                    <span onClick={() => onDelete(row.NOMENID)} id='media-query-table-buttons' className='btn btn-danger nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>

            ),

        }
    ];

    // funkcija se poziva prilikom promjene stranice u tabeli stranica 1,2,3 ...

    const handlePageChange = page => {
        getTableData(page, perPage)
    }
    // funkcija se poziva prilikom promjene broja redova ispisa u tabeli 10, 15, 30 ...

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    }
    // const searchChange = (e) => {
    //     e.preventDefault();
    //     setSearchInput(e.target.value);

    //     var searchData = opzaAll.filter((item) => {
    //         return Object.keys(item).some(key => {
    //             if (item[key] !== null)
    //                 return item[key].toString().toLowerCase().includes(e.target.value.toLowerCase())
    //         })
    //     });
    //     setopza(searchData);
    // };

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 

    const searchChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);

        const fetchData = async () => {
            const res = await axios.get(`${API}/opza/query ?q=${searchInput}`);
            setOpza(res.data);
        };
        // uslov za za slanje zahtjeva za povlacenje podataka

        if (searchInput.length === 0 || searchInput.length > 2) fetchData();

    };

    // funkcija za brisanje pretrage klikom na dugme 'Ocisti'
    // osvjezava polje za pretragu
    // osvjezava mrezu

    const handleClear = () => {
        setSearchInput('');
        getTableData(1, perPage)
    }

    return (

        <div>
            {/* Kontejner za naslov  */}

            <div className="title-container">
                {/* Naziv registra */}

                <header className="title-header">
                    <h1 id="media-query-title">Opis za artikle</h1>
                </header>
                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}

                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="12" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" className='dugme-stampa' title='Štampa' tabIndex="13" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>

                    </ul>
                </div>

            </div>
            {/* Kontejner pune sirine */}
            <Container fluid >
                <Row>
                    <div className='products-form'>
                        <Row>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-12" style={{ display: 'flex', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                {/* Sirina polja */}
                                <div className="col-4">
                                    <div className='field-container' style={{ marginRight: '3px' }}>
                                        <label id="media-query-label">Artikal</label>
                                        {/* Ako je Autocomplete  poziva na odabri ARTIKAL*/}
                                        <Autocomplete
                                            {...defaultProps}
                                            value={artikal}
                                            disablePortal={true}
                                            onChange={(event, newValue) => {
                                                setForm(prev => ({ ...prev, NOMENID: newValue && newValue != null ? newValue.ID : null }))
                                                setArtikal(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField id='media-query' {...params} className="clients-textinput" name='NOMENID' onFocus={() => setArtikalFocus(true)} ref={artikalRef} tabIndex="1" onBlur={(e) => formCheck(e)} placeholder=' artikal' variant="standard" />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Row>

                        <Row>
                            <div className="col-12">
                                {/* Stilovi i klase za polje za pretragu;  */}
                                <div style={{ display: 'flex', alignItems: 'baseline', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                    <label style={{ marginLeft: '25px', alignItems: 'center', marginRight: '10px', color: '#00009D', fontWeight: '600', fontSize: '17px' }} id="media-query-input-text">Opis1</label>
                                    <input type="text" id="media-query-input-text" ref={opisRef} maxLength="100" tabIndex="3" className='field-container' placeholder="opis 1" name='OPIS1' value={form ? form.OPIS1 : ''} onChange={(e) => handleChange(e)} />
                                </div>
                                <div class="col-12" style={{ display: 'flex', alignItems: 'baseline', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                    <label style={{ marginLeft: '25px', alignItems: 'center', marginRight: '10px', color: '#00009D', fontWeight: '600', fontSize: '17px' }} id="media-query-input-text">Opis2</label>
                                    <input type="text" id="media-query-input-text" maxLength="100" tabIndex="4" placeholder="opis 2" name='OPIS2' value={form ? form.OPIS2 : ''} onChange={(e) => handleChange(e)} />
                                </div>


                                <div class="col-12" style={{ display: 'flex', alignItems: 'baseline', paddingRight: '0px', paddingLeft: '-1px', marginRight: 'auto', marginLeft: '0px' }}>
                                    <label style={{ marginLeft: '25px', alignItems: 'center', marginRight: '10px', color: '#00009D', fontWeight: '600', fontSize: '17px' }} id="media-query-input-text">Opis3</label>
                                    <input type="text" id="media-query-input-text" maxLength="100" tabIndex="5" placeholder="opis 3" name='OPIS3' value={form ? form.OPIS3 : ''} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>
                        </Row>
                    </div>
                </Row >
            </Container >

            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => searchChange(e)} value={searchInput} />
                {/* dugme 'Ocisti'( poziva funkciju handleClear za brisanje) */}
                <button onClick={() => handleClear()} className='dugme-clear'>Očisti</button>

                {/* // <img src={Pencil} className='search-icon' /> */}
            </div>
            {/* Mreza */}
            <DataTable
                columns={columns}
                data={opza ? opza : []}
                pagination
                paginationServer
                paginationTotalRows={total}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
            />
            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}

            <div>
                <Modal isOpen={modalErrorOpen} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpen(false)} className="button-no">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                <Modal isOpen={modalErrorOpenData} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

        </div >

    );

}

export default Opza;


