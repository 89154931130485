// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { API } from '../constants';
import axios from '../api/axios';
import { Spinner } from "reactstrap";
import { getCompanyData, defaultComponentOptions } from '../components/utilities';
import { NumericFormat } from 'react-number-format';
import { useWindowSize} from '../components/useWindowSize';

// komponenta koju kreiramo
function Tax() {

    const windowSize = useWindowSize();
    console.log(windowSize, 'windowSize')

    const codeRef = useRef();
    const nameRef = useRef();
    const percentageRef = useRef();

    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const [tax, setTax] = useState(null)
    const [perPage, setPerPage] = useState(10);
    const [searchInput, setSearchInput] = useState([]);

    // error row inputs
    const [errorInputCode, setErrorInputCode] = useState(false);
    const [errorInputName, setErrorInputName] = useState(false);
    const [errorMsgCode, setErrorMsgCode] = useState(false);
    const [errorMsgName, setErrorMsgName] = useState(false);
    const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);
    const [modalErrorAuthorizationData, setModalErrorAuthorizationOpen] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [pending, setPending] = React.useState(false);

    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null

    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        SIFRA: '',
        NAZIV: '',
        PROCENAT: '',
        KONTO: '',
        stavkaPronadjena: false,
    })

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    // funkcija koja se poziva klikom na dugme 'Sacuvaj'
    const onSubmit = () => {

        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
        if (!unosIspravanDo('UPIS')) return;

        // ide na upis sloga
        // poziva api za upis
        // osvezavanje polja, fokus na polje SIFRA, poziva funkciju getTableData(za upis u mrezu)         
        fetch(API + '/tax/new', {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    SIFRA: '',
                    NAZIV: '',
                    PROCENAT: '',
                    KONTO: '',
                    stavkaPronadjena: false
                })
                codeRef.current.focus()
                getTableData()
            })
    }

    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setIme) i puni ukupan broj stranica(setTotal)
    const getTableData = () => {
        fetch(API + `/tax/all`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                setTax(data.result)
                setPending(false)
            })
    }

    // prilikom pocetne inicijalizacije postavlja prvi slobodan broj
    useEffect(() => {
        const timeout = setTimeout(() => {
            getTableData()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    // funkcija za brisanje koja se poziva klikom na dugme 'Delete'
    //  api za brisanje
    // u responsu osvjezava polja, daje prvi slobodan broj za sifru, fokus na polje sifra, osvjezava mrezu  
    const onDelete = (ID) => {
        // provjera ovlascenja za brisanje 
        if (form.ovlfakt == 4) return setModalErrorAuthorizationOpen(true)

        fetch(API + '/tax/delete/' + ID, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => {
                response.json()
                setForm({
                    SIFRA: '',
                    NAZIV: '',
                    PROCENAT: '',
                    KONTO: '',
                    stavkaPronadjena: false,
                })
                codeRef.current.focus()
                getTableData()
            })
    }

    // funkcija koja se poziva klikom na dugme Edit
    // postavlja fokus na polje NAZIV 
    // popunjava polja podacima iz mreze
    const onEdit = (item) => {
        item.stavkaPronadjena = true
        setForm(item)

        // zaustavljanje fokusa
        const timeout = setTimeout(() => {
            nameRef.current.focus()
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }

    // funkcija koja se poziva prilikom promjene vrijednosti u inputu
    const handleChange = (event) => {
        const target = event.target;
        let value = target.value;
        const name = event.target.name;

        setForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    // funkcija koja se poziva klikom na dugme 'Stampa'
    const onPrint = () => {
        // uslov preko koga saljemo naziv preduzeca sa frontenda na bekend
        // ako je objekat form popunjen, napuni polje NAZIV_PRED iz lokal storidza
        // poziva api za stampu, otvara izvjestaj(API + data.link)
        setSpinner(true)
        if (tax && tax.length === 0) {
            setSpinner(false)
            setModalErrorOpenData(true)
        } else {
            if (form) {
                form.NAZIV_PRED = form.companyNaziv
            }
            fetch(API + `/tax/print`, {
                method: 'POST',
                body: JSON.stringify(form),
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-type': 'application/json; charset=UTF-8'
                },
            })
                .then((response) => response.json())
                .then(data => {
                    if (typeof window != 'undefined') {
                        setTimeout(() => {
                            window.open(API + data.link)
                        }, 200);
                    }
                    setSpinner(false)
                })
        }
    }

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkCodeRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgCode('Šifra je obavezna!')
            setErrorInputCode(true)
            codeRef.current.focus();
            return false
        } else {
            setErrorMsgCode(null)
            setErrorInputCode(false)
            return true
        }
    }

    // uslov: ako nije upisana ni vrijednost, izbaci poruku, setuje crveni okvir i fokusira polje  
    // u suprotnom prolazi dalje
    const checkNameRequired = (value) => {
        if (!value || value == 0) {
            setErrorMsgName('Naziv je obavezan!')
            setErrorInputName(true)
            nameRef.current.focus();
            return false
        } else {
            setErrorMsgName(null)
            setErrorInputName(false)
            return true
        }
    }

    // funkcija za kontrolu obaveznih polja
    function unosIspravanDo(name) {
        if (name === 'SIFRA') {
            return true
        }

        if (!checkCodeRequired(form.SIFRA)) {
            return false
        }

        if (name === 'NAZIV') {
            return true
        }

        if (!checkNameRequired(form.NAZIV)) {
            return false
        }

        if (name === 'PROCENAT') {
            return true
        }

        if (name === 'KONTO') {
            return true
        }
        return true
    }

    // definisu se polja u tabeli i popunjavaju
    const columns = [
        {
            name: 'Šifra',
            selector: row => row.SIFRA,
             // sirina polja po rezolucijama
            width:   
              windowSize.width > 1920 ? '130px'
            : windowSize.width > 1680 ? '100px'
            : windowSize.width > 1600 ? '100px'
            : windowSize.width > 1440 ? '100px'
            : windowSize.width > 1280 ? '100px'
            : windowSize.width > 1024 ? '100px'
            : '100px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.SIFRA}</span>
                </>
            ),
        },
        {
            name: 'Naziv',
            selector: row => row.NAZIV,
             // sirina polja po rezolucijama
            width:        
              windowSize.width > 1920 ? '430px'
            : windowSize.width > 1680 ? '430px'
            : windowSize.width > 1600 ? '390px'
            : windowSize.width > 1440 ? '350px'
            : windowSize.width > 1280 ? '300px'
            : windowSize.width > 1024 ? '200px'
            : '250px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.NAZIV}</span>
                </>
            ),
        },
        {
            name: 'Procenat',
            selector: row => row.PROCENAT,
            right: 'boolean',
             // sirina polja po rezolucijama
            width:        
              windowSize.width > 1920 ? '430px'
            : windowSize.width > 1680 ? '430px'
            : windowSize.width > 1600 ? '400px'
            : windowSize.width > 1440 ? '350px'
            : windowSize.width > 1280 ? '200px'
            : windowSize.width > 1024 ? '200px'
            : '150px',
            cell: (row) => (
                <>
                    <span id='media-query-rows'>{row.PROCENAT}</span>
                </>
            ),
        },
        {
            name: 'Konto',
            selector: row => row.KONTO,
             // sirina polja po rezolucijama
            width:        
              windowSize.width > 1920 ? '430px'
            : windowSize.width > 1680 ? '430px'
            : windowSize.width > 1600 ? '400px'
            : windowSize.width > 1440 ? '350px'
            : windowSize.width > 1280 ? '200px'
            : windowSize.width > 1024 ? '200px'
            : '250px',
            cell: (row) => (
                <>
                    <span id='media-query-rows-text'>{row.KONTO}</span>
                </>
            ),
        },
        {
            name: 'Action',
          //  width: '350px',
            right: 'boolean',
            cell: (row) => (
                <>
                    <span onClick={() => onEdit(row)} id='media-query-table-buttons' className='btn btn-primary naslov-ikonice nav flex-column flex-lg-row justify-content-end'>Edit</span>{''}
                    <span onClick={() => onDelete(row.ID)} id='media-query-table-buttons' className='btn btn-danger naslov-ikonice nav flex-column flex-lg-row justify-content-end'>Brisanje</span>
                </>
            ),
        }
    ];

    // funkcija za pretragu iz mreze
    // salje zahtjev na bekend prema upitu sa parametrima koje posaljemo
    // u responsu vraca podatke koje dobio iz upita 
    const searchChange = (e) => {
        e.preventDefault();
        const searchInput = e.target.value
        setSearchInput(searchInput);
        const fetchData = async () => {
            const res = await axios.get(`${API}/tax/query/${form.companyCode}?q=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } });
            setTax(res.data);
        };

        // uslov za za slanje zahtjeva za povlacenje podataka
        if (searchInput.length === 0 || searchInput.length >= 1) fetchData();
    };

    return (

        <div>
            {/* Kontejner za naslov  */}
            <div className="title-container">

                {/* Naziv registra */}
                <header className="title-header">
                    <h1 id="media-query-title">Porez</h1>
                </header>

                {/* Klasa za ikonice, nalazi se u istom redu kao i naziv, ikonice su formirane kao lista */}
                <div className="naslov-ikonice">
                    <ul className="nav flex-column flex-lg-row justify-content-end">

                        <li className="nav-item toolbaritem">
                            <input type="image" className='dugme-sacuvaj' title='Sačuvaj' tabIndex="5" src={require('./../assets/images/floppydisk.png')} onFocus={() => onSubmit()} />
                        </li>

                        <li className="nav-item toolbaritem">
                            <input type="image" className='dugme-stampa' title='Štampa' tabIndex="6" src={require('./../assets/images/printer.png')} onClick={onPrint} />
                        </li>
                    </ul>
                </div>
            </div>

            {/* Kontejner pune sirine */}
            <Container fluid>
                <Row>
                    <div className='products-form'>
                        <Row>
                            {/* Stilovi koji ce se primjenjivati na svim poljima */}
                            <div className="col-6" style={{ display: 'flex', marginBottom: '0px', width: '60%' }}>

                                {/* Sirina polja */}
                                <div className="col-1">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className={errorInputCode ? 'my-input' : 'field-container'} style={{ marginRight: '3px', marginBottom: '0px' }} >
                                        <label id="media-query-label">Šifra:</label>
                                        <input type="text"
                                            maxLength="6"
                                            tabIndex="1"
                                            id="media-query-input-text"
                                            autoFocus={true}
                                            placeholder="Šifra"
                                            name='SIFRA'
                                            value={form ? form.SIFRA : ''}
                                            ref={codeRef}
                                            onFocus={() => {
                                                unosIspravanDo("SIFRA")
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)}
                                            onClick={() => {
                                                // klikom na polje sifra, ciste se ostala polja
                                                setForm(prev => ({
                                                    ...prev, NAZIV: '', PROCENAT: '', KONTO: '', stavkaPronadjena: false
                                                }))
                                            }}></input>
                                        {/* Ako polje nije popunjeno i ako je errorInputCode true , prikazuje poruku o gresci */}
                                        {errorMsgCode ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgCode}</span> : ''}
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className={errorInputName ? 'my-input' : 'field-container'} style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Naziv:</label>
                                        <input type="text"
                                            maxLength="40"
                                            id="media-query-input-text"
                                            ref={nameRef}
                                            tabIndex="2"
                                            placeholder="Naziv"
                                            name='NAZIV'
                                            value={form ? form.NAZIV : ''}
                                            onFocus={() => {
                                                unosIspravanDo("NAZIV")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                        {errorMsgName ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgName}</span> : ''}
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-2">
                                    <div className='col-form-right'>
                                        {/* Provjera da li je polje popunjeno jer je obavezno */}
                                        <div className='field-container-number' style={{ marginRight: '3px' }} >
                                            <label id="media-query-label">Procenat:</label>
                                        </div>
                                        <NumericFormat
                                            type="number"
                                            id="media-query-input-text"
                                            getInputRef={percentageRef}
                                            maxLength="7"
                                            decimalScale={2}
                                            tabIndex="3"
                                            placeholder="procenat"
                                            name='PROCENAT'
                                            value={form ? form.PROCENAT : ''}
                                            onFocus={() => {
                                                unosIspravanDo("PROCENAT")
                                            }}
                                            // Ako je izmjena na polju
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>

                                {/* Sirina polja */}
                                <div className="col-2">
                                    {/* Provjera da li je polje popunjeno jer je obavezno */}
                                    <div className='field-container' style={{ marginRight: '3px' }} >
                                        <label id="media-query-label">Konto:</label>
                                        <input type="text" maxLength="8" id="media-query-input-text" tabIndex="4" placeholder="Konto" name='KONTO' value={form ? form.KONTO : ''}
                                            onFocus={() => {
                                                unosIspravanDo("KONTO")
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Row>

            </Container>
            {/* Stilovi i klase za polje za pretragu;  */}
            <div style={{ display: 'flex' }} className='search'>
                {/* vrsta inputa; poziva funkciju za pretragu(searchChange);  */}
                <input type='text' id="media-query-input-text" placeholder='Pretraga...' onChange={(e) => {
                    // pretraga mreze(ukoliko je unesen bilo koji karakter, vrsi pretragu)
                    if (searchInput.length >= 0) {
                        searchChange(e)
                    } else {
                        getTableData()
                    }
                }} value={searchInput} />
            </div>

            {/* Mreza */}
            <DataTable
                columns={columns}
                data={tax ? tax : []}
                pagination
                responsive
                highlightOnHover
                striped
                dense
                pointerOnHover
                paginationComponentOptions={defaultComponentOptions}
                progressPending={pending}
            />

            {/* render za poruku */}
            <div>
                <Modal isOpen={Boolean(modalErrorAuthorizationData)} centered  >
                    <ModalHeader>Upozorenje!</ModalHeader>
                    <ModalBody>
                        <span>{`Nemate ovlašćenje za brisanje!`}</span>
                    </ModalBody>
                    <ModalFooter style={{justifyContent: 'center'}}>
                        <div>
                            <button onClick={() => setModalErrorAuthorizationOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}
            <div>
                <Modal isOpen={Boolean(modalErrorOpen)} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja!`}</span>
                    </ModalBody>
                    <ModalFooter style={{justifyContent: 'center'}}>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}
            <div>
                <Modal isOpen={Boolean(modalErrorOpenData)} centered  >
                    <ModalHeader>Nema podataka!</ModalHeader>
                    <ModalFooter style={{justifyContent: 'center'}}>
                        <div>
                            {/* Dugme za zatvaranje modalnog forma */}
                            <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

            <div>
                {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
            </div>

        </div>
    );
}

export default Tax;





