// importovanje komponenti 
import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API } from '../constants';
import { RadioButton } from '../components/radioButton';
import { numberWithCommasColumns, numberWithCommas, roundTO, getCompanyData, defaultComponentOptions } from '../components/utilities';
import { pregledPoDokumentima } from '../invoicing/pregledPoDokumentima';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Spinner } from "reactstrap";
import { NumericFormat } from 'react-number-format';
import { useWindowSize } from '../components/useWindowSize';

// komponenta koju kreiramo

function InvoiceReport() {

    // definisanje konstanti koje koristimo za fokusiranje polja
    // definisanje stejtova i prva inicijalizacija; objekat i funkcija za taj objekat
    const windowSize = useWindowSize();
    const [invoiceReport, setInvoiceReport] = useState(null);
    const [sum, setSum] = useState(0);
    const [sumPorez, setSumPorez] = useState(0);
    const [sumVrijednost, setSumVrijednost] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [repository, setRepository] = useState(null)
    const [clients, setClients] = useState(null);
    const [service, setService] = useState(null);
    const [selectData, setSelectData] = useState([]);
    const [selectDataClients, setSelectDataClients] = useState([]);
    const [selectDataService, setSelectDataService] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(15);

    const [pending, setPending] = React.useState(false);

    const [errorInputRepository, setErrorInputRepository] = useState(false);
    const [errorInputYear, setErrorInputYear] = useState(false);
    const [errorDateFrom, setErrorDateFrom] = useState(false);
    const [errorDateTo, setErrorDateTo] = useState(false);

    const [errorMsgRepository, setErrorMsgRepository] = useState(false);
    const [errorMsgYear, setErrorMsgYear] = useState(false);
    const [errorMsgDateFrom, setErrorMsgDateFrom] = useState(false);
    const [errorMsgDateTo, setErrorMsgDateTo] = useState(false);

    const repositoryRef = useRef();
    const clientsRef = useRef();
    const serviceRef = useRef();
    const docFromRef = useRef();
    const docToRef = useRef();
    const yearRef = useRef();
    const dateFromRef = useRef();
    const dateToRef = useRef();


    // const [modalErrorOpen, setModalErrorOpen] = useState(false);
    const [modalErrorOpenPrint, setModalErrorOpenPrint] = useState(false);
    const [modalErrorOpenData, setModalErrorOpenData] = useState(false);

    const [date, setDate] = useState({
        checkDatDok: true,
        checkDatStorno: false
    });
    const [reversal, setReversal] = useState({
        checkAll: true,
        checkCancelled: false,
        checkNotCancelled: false
    });
    const [taxPayers, setTaxPayers] = useState({
        checkAllTaxPlayers: true,
        checkTaxPlayers: false,
        checkNotTaxPlayers: false

    });
    //const [buttonType, setButtonType] = useState(null);
    //  const [buttonTypeTotal, setButtonTypeTotal] = useState(null)

    // povlacenje podataka iz lokal storidza
    const token = typeof window !== 'undefined' ? localStorage.getItem('accessToken') : null;
    // const companyYear = typeof window !== 'undefined' ? localStorage.getItem('companyYear') : null

    //  DATUM: moment.unix(new Date(startDate).getTime() / 1000).format("DD.MM.YYYY"),
    // definisanje stejta(objekat i funkcija za taj objekat), prva inicijalizacija
    const [form, setForm] = useState({
        SKLAID: '',
        SIFRAS: '',
        NAZIV_SKLADISTA: '',
        KOMTID: '',
        GODINA: '',
        DOKUMENTOD: '',
        DOKUMENTDO: '',
        DATUMOD: '',
        DATUMDO: '',
        DATUM: '',
        DATSTORNO: '',
        buttonType: '',
        link: ''
    })

    // funkcija za povlacenje podataka o preduzecu
    getCompanyData(form)

    // funkcija za popunjavanje datumskih polja u zavisnosti od godine
    const checkCompanyYear = () => {
        //  let dateFrom
        //  let dateTo
        let currentDate = new Date()
        if (currentDate.getFullYear() === Number(form.companyYear)) {
            setForm(prev => ({ ...prev, DATUMOD: moment.unix(new Date(currentDate.getFullYear(), 0, 1).getTime() / 1000).format("DD.MM.YYYY"), DATUMDO: moment.unix(new Date().getTime() / 1000).format("DD.MM.YYYY") }))
        } else {
            let oldYear = new Date()
            oldYear = new Date(Number(oldYear.setFullYear(form.companyYear)))
            setForm(prev => ({ ...prev, DATUMOD: moment.unix(new Date(oldYear.getFullYear(), 0, 1).getTime() / 1000).format("DD.MM.YYYY"), DATUMDO: moment.unix(new Date(Number(oldYear.setFullYear(form.companyYear))).getTime() / 1000).format("DD.MM.YYYY") }))
        }
    }



    // funkcija koja se poziva pri prvoj inicijalizaciji
    // api za mrezu
    // u responsu puni mrezi(pomocu funkcije setCountry) i puni ukupan broj stranica(setTotal)
    const getReports = () => {
        // filter
        if (form) {
            form.SKLAID = repository && repository.ID ? repository.ID : null
            form.KOMTID = clients && clients.ID ? clients.ID : null
            form.USLUGA = service && service.SIFRA ? service.SIFRA : null
            form.checkDatDok = date.checkDatDok
            //  form.buttonType = buttonType
            //   form.buttonTypeTotal = buttonTypeTotal
        }

        fetch(API + `/invoiceReport/all`, {
            method: 'POST',
            body: JSON.stringify(form),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
            .then((response) => response.json())
            .then(data => {
                if (data && data.items && data.items.length !== 0) {
                    setInvoiceReport(data.items)
                    let VRIJEDNOSTSum = 0.00;
                    let POREZSum = 0.00;
                    let UKUPNOSum = 0.00;
                    // petlja za citanje podataka
                    for (let i = 0; i < data.items.length; i++) {
                        VRIJEDNOSTSum += data.items[i].VRED
                        POREZSum += data.items[i].POREZ
                        UKUPNOSum += data.items[i].UKUPNO
                    }
                    setSumVrijednost(numberWithCommas(roundTO(VRIJEDNOSTSum, 2)))
                    setSumPorez(numberWithCommas(roundTO(POREZSum, 2)))
                    setSum(numberWithCommas(roundTO(UKUPNOSum, 2)))
                    setPending(false)
                } else {
                    setModalErrorOpenData(true)
                }
            })
    }

    // pokretanje  dugmeta za stampu
    const print = () => {
        setSpinner(true)
        // uslov: ako nije izabrano skladiste, godina i broj dokumenta(ako je prvi slobodan dokument, a nema mreze tj. nema nema slogova), nece otvoriti izvjestaj 
        if (!unosIspravanDo('PRINT')) return setSpinner(false)
        if (form.buttonType === "") return setSpinner(false), setModalErrorOpenPrint(true)
        //filter
        if (form) {
            form.NAZIV_PRED = form.companyName
            form.GODINA = form.companyYear
            form.SKLAID = repository && repository.ID ? repository.ID : null
            form.KOMTID = clients && clients.ID ? clients.ID : null
            form.USLUGA = service && service.SIFRA ? service.SIFRA : null
            form.checkDatDok = date.checkDatDok
            //    form.buttonType = buttonType ? buttonType : null
            //   form.buttonTypeTotal = buttonTypeTotal ? buttonTypeTotal : null
        }

        // fetch za sql
        // u bodiju saljemo filter(form)
        // fetch(API + `/invoiceReport/print`, {
        //     method: 'POST',
        //     body: JSON.stringify({ form }),
        //     headers: {
        //         'Authorization': `Bearer ${token}`,
        //         'Content-type': 'application/json; charset=UTF-8'
        //     },
        // })
        //     .then((response) => response.json())
        //     .then(data => {

        //         // fetch za crtanje izvjestaja
        //         // u bodiju saljemo sql koji je vec pokupio i form
        //         fetch(API + `/` + form.buttonType + `/print`, {
        //             method: 'POST',
        //             body: JSON.stringify({ sql: data.sql, form: form }),
        //             responseType: 'blob',
        //             headers: {
        //                 'Authorization': `Bearer ${token}`,
        //                 'Content-type': 'application/json; charset=UTF-8',
        //             },
        //         })
        //             .then((response) => response.json())
        //             .then(data => {
        //                 window.open(API + data.link)
        //                 setSpinner(false)
        //             })
        //     })
        fetch(API + `/invoiceReport/print`, {
        // fetch(`http://testreactapi.infosistemweb.com/totalPoDokumentu/print` , {
            method: 'POST',
            body: JSON.stringify(form),
            responseType: 'blob',
            credentials: "same-origin",
           //  mode: 'no-cors',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
        .then((response) => response.json())
        .then(data => {
            if (typeof window != 'undefined') {
                setTimeout(() => {
                    window.open(API + data.link)
                }, 500);
            }
            setSpinner(false)
        })
}

// funkcija koja poziva citanje skladista
const getRepositoryData = () => {
    fetch(API + `/warehouse/all/${form.companyCode}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8'
        },
    })
        .then((response) => response.json())
        .then(data => {
            setSelectData(data)
        })
}

// funkcija koja poziva citanje komitenta
const getClientsData = () => {
    fetch(API + `/client/all/${form.companyCode}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8'
        },
    })
        .then((response) => response.json())
        .then(data => {
            setSelectDataClients(data)
        })
}
// funkcija koja poziva citanje usluga
const getServiceData = () => {
    fetch(API + `/serviceCharge/all`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json; charset=UTF-8'
        },
    })
        .then((response) => response.json())
        .then(data => {
            setSelectDataService(data)
        })
}

// funkcija koja prati promjenu stanja(poziva apije za citanje i popunjavanje autokomplitova) 
useEffect(() => {
    getRepositoryData()
    getClientsData()
    getServiceData()
    repositoryRef.current.focus();
}, [])

// // prati promjenu stanja za izvjestaje
// useEffect(() => {
//     getReports(1, perPage)
//     console.log('buttonType')
//     // ako je dugme popunjeno,poziva funkciju getReports
// }, [buttonType])

// useEffect(() => {
//     getReports(1, perPage)
//     // ako je dugme popunjeno,poziva funkciju getReports
// }, [])

// useEffect(() => {
//     getReports(1, perPage)
//     // osvjezava mrezu svaki put kad promijenimo neko od datih polja
// }, [clients || service || form.DOKUMENTOD || form.DOKUMENTDO || form.GODINA || form.DATUMOD || form.DATUMDO])

// funkcija koja se poziva prilikom promjene vrijednosti u inputu
const handleChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = event.target.name;
    // postavlja sa funkcijom  setForm((prevState) mijejenja predhodno stanje sa novom vrijednoscu
    setForm((prevState) => ({
        ...prevState,
        [name]: value
    }));
};

// funkcije za provjeru obaveznih polja - 3 skladiste, komitent, usluga
const checkRepositoryRequired = (value) => {
    if (value && !repository || !value && !repository) {
        //    console.log('sssss');
        setErrorMsgRepository('Skladiste mora biti izabrano')
        setErrorInputRepository(true)
        repositoryRef.current.focus();
        return false
    } else {
        setErrorMsgRepository(null)
        setErrorInputRepository(false)
        return true
    }
}

// uslov: ako nije upisana neka vrijednost ili je vrijednost 0, izbaci poruku, setuje crveni okvir i fokusira polje  
// u suprotnom prolazi dalje
const checkYearRequired = (value) => {
    if (!value || value == 0) {
        //    console.log('upada u rbr', value)
        setErrorMsgYear('Godina je obavezna!')
        setErrorInputYear(true)
        yearRef.current.focus();
        return false
    } else {
        setErrorMsgYear(null)
        setErrorInputYear(false)
        return true
    }
}

// funkcija za kontrolu obaveznih polja
function unosIspravanDo(name) {
    if (name === 'SKLAID') {
        return true
    }

    if (!checkRepositoryRequired(form.SKLAID)) {
        return false
    }

    if (name === 'KOMTID') {
        return true
    }

    if (name === 'USLUGA') {
        return true
    }

    if (name === 'DOKUMENTOD') {
        return true
    }

    if (name === 'GODINA') {
        return true
    }

    if (!checkYearRequired(form.GODINA)) {
        return false
    }

    if (name === 'DOKUMENTDO') {
        return true
    }

    if (name === 'DATUMOD') {
        return true
    }

    let reqex = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;

    if (!reqex.test(form.DATUMOD)) {
        setErrorDateFrom(true)
        setErrorMsgDateFrom('Datum od nije validan!')
        dateFromRef.current.focus();
        return false
    } else {
        setErrorDateFrom(false)
        setErrorMsgDateFrom(null)
    }

    if (name === 'DATUMDO') {
        return true
    }

    let reqexDATUMDO = /^(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})$/gi;
    if (!reqexDATUMDO.test(form.DATUMDO)) {
        setErrorDateTo(true)
        setErrorMsgDateTo('Datum do nije validan!')
        dateToRef.current.focus();
        return false
    } else {
        setErrorDateTo(false)
        setErrorMsgDateTo(null)
    }

    return true
}

// cekiranje radio dugmadi u zavisnosti odabrane opcije
const radioChangeHandler = (e) => {
    if (e.target.name === 'checkDatDok') {
        setDate({
            // odabir datuma dokumenata
            checkDatDok: true,
            checkDatStorno: false
        });
    } else {
        setDate({
            checkDatDok: false,
            // odabir datuma storniranja
            checkDatStorno: true
        });
    }
};

// cekiranje radio dugmadi u zavisnosti odabrane opcije povezano ya storniranje

const radioChangeHandlerCanceledNotCanceled = (e) => {
    // ako su odabrani stornirani dokumenti
    if (e.target.name === 'checkCancelled') {
        setReversal({
            checkAll: false,
            // odabir storniranih dokumenata
            checkCancelled: true,
            checkNotCancelled: false
        });
    }
    // ako su odabrani nestornirani dokumenti
    if (e.target.name === 'checkNotCancelled') {
        setReversal({
            checkAll: false,
            checkCancelled: false,
            // odabir nestorniranih dokumenata
            checkNotCancelled: true
        });
    }
    // ako su odabrani svi dokumenti
    if (e.target.name === 'checkAll') {
        setReversal({
            // odabir svih dokumenata
            checkAll: true,
            checkCancelled: false,
            checkNotCancelled: false
        });
    }
};
// cekiranje radio dugmadi u zavisnosti odabrane opcije povezano za obveznike

const radioChangeHandlerVatPlayers = (e) => {
    if (e.target.name === 'checkAllTaxPlayers') {
        setTaxPayers({
            // odabir svih obveznika
            checkAllTaxPlayers: true,
            checkNotTaxPlayers: false,
            checkTaxPlayers: false
        });
    }
    // ako su odabrani neobveznici
    if (e.target.name === 'checkNotTaxPlayers') {
        setTaxPayers({
            checkAllTaxPlayers: false,
            // odabir  neobveznika
            checkNotTaxPlayers: true,
            checkTaxPlayers: false
        });
    }
    // ako su odabrani obveznici
    if (e.target.name === 'checkTaxPlayers') {
        setTaxPayers({
            checkAllTaxPlayers: false,
            checkNotTaxPlayers: false,
            // odabir  obveznika
            checkTaxPlayers: true
        });
    }
};

// definisu se polja u tabeli i popunjavaju
const columns = [
    {
        name: 'Rbr',
        selector: row => row.RBR,
        // sirina polja po rezolucijama
        width:
            windowSize.width > 1920 ? '100px'
                : windowSize.width > 1680 ? '100px'
                    : windowSize.width > 1600 ? '100px'
                        : windowSize.width > 1440 ? '100px'
                            : windowSize.width > 1280 ? '80px'
                                : windowSize.width > 1024 ? '80px'
                                    : '80px',
        right: 'boolean',

        cell: (row) => (
            <>
                <span id='media-query-rows'>{row.RBR}</span>
            </>
        ),
    },
    {
        name: 'Dokument',
        selector: row => row.DOK,
        // sirina polja po rezolucijama
        width:
            windowSize.width > 1920 ? '150px'
                : windowSize.width > 1680 ? '100px'
                    : windowSize.width > 1600 ? '100px'
                        : windowSize.width > 1440 ? '100px'
                            : windowSize.width > 1280 ? '90px'
                                : windowSize.width > 1024 ? '90px'
                                    : '90px',
        right: 'boolean',

        cell: (row) => (
            <>
                <span id='media-query-rows'>{row.DOK}</span>
            </>
        ),
    },
    {
        name: 'Komitent',
        selector: row => row.SIFRA_KOMITENTA,
        // sirina polja po rezolucijama
        width:
            windowSize.width > 1920 ? '150px'
                : windowSize.width > 1680 ? '110px'
                    : windowSize.width > 1600 ? '100px'
                        : windowSize.width > 1440 ? '100px'
                            : windowSize.width > 1280 ? '90px'
                                : windowSize.width > 1024 ? '90px'
                                    : '90px',
        right: 'boolean',
        cell: (row) => (
            <>
                <span id='media-query-rows'>{row.SIFRA_KOMITENTA}</span>
            </>
        ),
    },
    {
        name: 'Datum',
        selector: row => moment(row.DATUM).format('DD.MM.YYYY'),
        // sirina polja po rezolucijama
        width:
            windowSize.width > 1920 ? '150px'
                : windowSize.width > 1680 ? '110px'
                    : windowSize.width > 1600 ? '100px'
                        : windowSize.width > 1440 ? '100px'
                            : windowSize.width > 1280 ? '95px'
                                : windowSize.width > 1024 ? '80px'
                                    : '80px',
        cell: (row) => (
            <>
                <span id='media-query-rows-text'>{moment(row.DATUM).format('DD.MM.YYYY')}</span>
            </>
        ),
    },

    {
        name: 'Usluga',
        selector: row => row.USLUGA,
        // sirina polja po rezolucijama
        width:
            windowSize.width > 1920 ? '150px'
                : windowSize.width > 1680 ? '110px'
                    : windowSize.width > 1600 ? '100px'
                        : windowSize.width > 1440 ? '100px'
                            : windowSize.width > 1280 ? '80px'
                                : windowSize.width > 1024 ? '80px'
                                    : '80px',
        right: 'boolean',
        cell: (row) => (
            <>
                <span id='media-query-rows'>{row.USLUGA}</span>
            </>
        ),
    },

    {
        name: 'Opis',
        selector: row => row.OPIS,
        // sirina polja po rezolucijama
        width:
            windowSize.width > 1920 ? '600px'
                : windowSize.width > 1680 ? '500px'
                    : windowSize.width > 1600 ? '340px'
                        : windowSize.width > 1440 ? '315px'
                            : windowSize.width > 1280 ? '260px'
                                : windowSize.width > 1024 ? '330px'
                                    : '175px',
        cell: (row) => (
            <>
                <span id='media-query-rows-text'>{row.uslugaNAZIV}</span>
            </>
        ),
    },
    {
        name: 'Količina',
        selector: row => row.KOL,
        // sirina polja po rezolucijama
        width:
            windowSize.width > 1920 ? '190px'
                : windowSize.width > 1680 ? '120px'
                    : windowSize.width > 1600 ? '110px'
                        : windowSize.width > 1440 ? '100px'
                            : windowSize.width > 1280 ? '90px'
                                : windowSize.width > 1024 ? '100px'
                                    : '80px',
        right: 'boolean',
        cell: (row) => (
            <>
                <span id='media-query-rows'>{numberWithCommasColumns(row.KOL)}</span>
            </>
        ),
    },
    {
        name: 'Cijena',
        selector: row => row.CENA,
        // sirina polja po rezolucijama
        width:
            windowSize.width > 1920 ? '190px'
                : windowSize.width > 1680 ? '120px'
                    : windowSize.width > 1600 ? '110px'
                        : windowSize.width > 1440 ? '100px'
                            : windowSize.width > 1280 ? '90px'
                                : windowSize.width > 1024 ? '100px'
                                    : '80px',
        right: 'boolean',
        cell: (row) => (
            <>
                <span id='media-query-rows'>{numberWithCommasColumns(row.CENA)}</span>
            </>
        ),
    },
    {
        name: 'Vrijednost',
        selector: row => row.VRED,
        // sirina polja po rezolucijama
        width:
            windowSize.width > 1920 ? '190px'
                : windowSize.width > 1680 ? '120px'
                    : windowSize.width > 1600 ? '110px'
                        : windowSize.width > 1440 ? '100px'
                            : windowSize.width > 1280 ? '90px'
                                : windowSize.width > 1024 ? '100px'
                                    : '80px',
        right: 'boolean',
        cell: (row) => (
            <>
                {/*uslov: ako dugme pocinje sa 'pregled', uzima prvu vrijednost; u suprotnom(ako je u pitanju total), uzima drugu vrijednost*/}
                <span id='media-query-rows'>{form.buttonType.startsWith('pregled') ? numberWithCommasColumns(row.VRED) : numberWithCommasColumns(row.SUMVRED)}</span>
            </>
        ),
    },
    {
        name: 'Porez',
        selector: row => row.POREZ,
        // sirina polja po rezolucijama
        width:
            windowSize.width > 1920 ? '190px'
                : windowSize.width > 1680 ? '120px'
                    : windowSize.width > 1600 ? '110px'
                        : windowSize.width > 1440 ? '100px'
                            : windowSize.width > 1280 ? '90px'
                                : windowSize.width > 1024 ? '100px'
                                    : '80px',
        right: 'boolean',
        cell: (row) => (
            <>
                {/*uslov: ako dugme pocinje sa 'pregled', uzima prvu vrijednost; u suprotnom(ako je u pitanju total), uzima drugu vrijednost*/}
                <span id='media-query-rows'>{form.buttonType.startsWith('pregled') ? numberWithCommasColumns(row.POREZ) : numberWithCommasColumns(row.SUMPOREZ)}</span>
            </>
        ),
    },
    {
        name: 'Ukupno',
        selector: row => row.UKUPNO,
        // sirina polja po rezolucijama
        width:
            windowSize.width > 1920 ? '190px'
                : windowSize.width > 1680 ? '120px'
                    : windowSize.width > 1600 ? '110px'
                        : windowSize.width > 1440 ? '100px'
                            : windowSize.width > 1280 ? '90px'
                                : windowSize.width > 1024 ? '100px'
                                    : '80px',
        right: 'boolean',
        cell: (row) => (
            <>
                {/*uslov: ako dugme pocinje sa 'pregled', uzima prvu vrijednost; u suprotnom(ako je u pitanju total), uzima drugu vrijednost*/}
                <span id='media-query-rows'>{form.buttonType.startsWith('pregled') ? numberWithCommasColumns(row.UKUPNO) : numberWithCommasColumns(row.SUMUKUPNO)}</span>
            </>
        ),
    },
];

// propsovi za auto komplit  - mjesto
const defaultProps = {
    options: selectData,
    getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
};
// propsovi za auto komplit  - komitent
const defaultPropsClients = {
    options: selectDataClients,
    getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
};
// propsovi za auto komplit  - usluge
const defaultPropsService = {
    options: selectDataService,
    getOptionLabel: (option) => option ? `${option.SIFRA} ${option.NAZIV}` : '',
};

const drawerWidth = {
    width: '50%',
    '@media(minWidth: 1400px)': {
        height: '25px'
    }
}


return (

    <div>
        {/* Kontejner za naslov  */}
        <div className="title-container-report">
            {/* Naziv forma */}
            <header className="title-header">
                <h2 id="media-query-title">Izvještaj fakturisanja</h2>
            </header>

            <div >
                <div>
                    {/* Klasa za ikonice, stampa*/}
                    <ul>
                        <input type="image" className='dugme-stampa' title='Štampa' tabIndex="15" src={require('./../assets/images/printer.png')} onClick={print} />
                    </ul>
                </div>
            </div>
        </div>

        <Container fluid>
            <div className="row">
                {/* <Col lg='3'> */}
                <Col lg='2'>

                    {/* <div className='products-form'> */}

                    {/* <Row > */}
                    {/* <div style={{ display: 'contents' }}> */}
                    <div className='form-field-container' style={{ display: 'contents' }}>
                        {/* red sirine */}
                        <Col lg='12'>
                            {/* definsanje froma za radio dugmad */}
                            <div className="col-sm radio-box" id='media-query-radioButton-date'>
                                <RadioButton
                                    changed={(e) => radioChangeHandler(e)}
                                    isSelected={date?.checkDatDok}
                                    label="Dat.dok."
                                    id="1"
                                    value={date?.checkDatDok}
                                    name='checkDatDok'
                                />

                                <RadioButton
                                    changed={(e) => radioChangeHandler(e)}
                                    isSelected={date?.checkDatStorno}
                                    label="Dat.stor."
                                    id="2"
                                    value={date?.checkDatStorno}
                                    name='checkDatStorno'
                                />

                            </div>
                        </Col>

                        {/*  sirina pol */}

                        {/* <div className="col-sm radio-box" id='media-query-radioButton'>
                                    <RadioButton
                                        changed={(e) => radioChangeHandlerCanceledNotCanceled(e)}
                                        isSelected={reversal?.checkAll}
                                        label="Sve"
                                        id="3"
                                        value={reversal?.checkAll}
                                        name='checkAll'
                                    />
                                    <RadioButton
                                        changed={(e) => radioChangeHandlerCanceledNotCanceled(e)}
                                        isSelected={reversal?.checkCancelled}
                                        label="Storno"
                                        id="4"
                                        value={reversal?.checkCancelled}
                                        name='checkCancelled'
                                    />
                                    <RadioButton
                                        changed={(e) => radioChangeHandlerCanceledNotCanceled(e)}
                                        isSelected={reversal?.checkNotCancelled}
                                        label="Nestorno"
                                        id="5"
                                        value={reversal?.checkNotCancelled}
                                        name='checkNotCancelled'
                                    />

                                </div> */}



                        {/* <div className="col-sm radio-box" id='media-query-radioButton'>

                                    <RadioButton
                                        changed={(e) => radioChangeHandlerVatPlayers(e)}
                                        isSelected={taxPayers?.checkAllTaxPlayers}
                                        label="Svi"
                                        id="6"
                                        value={taxPayers?.checkAllTaxPlayers}
                                        name='checkAllTaxPlayers'
                                    />

                                    <RadioButton
                                        changed={(e) => radioChangeHandlerVatPlayers(e)}
                                        isSelected={taxPayers?.checkTaxPlayers}
                                        label="Obveznici"
                                        id="7"
                                        value={taxPayers?.checkTaxPlayers}
                                        name='checkTaxPlayers'
                                    />

                                    <RadioButton
                                        changed={(e) => radioChangeHandlerVatPlayers(e)}
                                        isSelected={taxPayers?.checkNotTaxPlayers}
                                        label="Neobvezn."
                                        id="8"
                                        value={taxPayers?.checkNotTaxPlayers}
                                        name='checkNotTaxPlayers'
                                    />

                                </div> */}

                    </div>
                    {/* </Row> */}


                </Col>

                <Col lg='6'>

                    <div className="col-12" style={{ display: 'flex' }}>
                        <div className="col-12">
                            <div className={errorInputRepository ? 'form-group row my-input-columns' : 'form-group row required label-box-div'} >
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Skladište:</label>
                                <div className="col-10">
                                    <Autocomplete
                                        //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        {...defaultProps}
                                        value={repository}
                                        disablePortal={true}
                                        onFocus={() => {
                                            unosIspravanDo("SKLAID")
                                        }}
                                        // ako se promijeni skladiste, uzima podatke za nov izabran0 skladiste
                                        onChange={(event, newValue) => {
                                            setForm(prev => ({ ...prev, SKLAID: newValue && newValue != '' ? newValue.ID : '', SIFRAS: newValue && newValue != '' ? newValue.SIFRA : '', NAZIV_SKLADISTA: newValue && newValue != '' ? newValue.NAZIV : '' }))
                                            setRepository(newValue);
                                            // fokus se mora vratiti inace tabIndex ne radi dobro
                                            repositoryRef.current.focus()
                                        }}

                                        renderInput={(params) => (
                                            <TextField {...params}
                                                autoFocus
                                                className="clients-textinput"
                                                tabIndex="1"

                                                ref={repositoryRef}
                                                placeholder=' Izaberite skladište'
                                                variant="standard" />
                                        )}
                                    />
                                    {errorMsgRepository ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgRepository}</span> : ''}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12" style={{ display: 'flex' }}>
                        <div className="col-12">
                            <div className="form-group row label-box-div">
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Komitent:</label>
                                <div className="col-10">
                                    <Autocomplete
                                        //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        {...defaultPropsClients}
                                        value={clients}
                                        disablePortal={true}
                                        onFocus={() => {
                                            unosIspravanDo("KOMTID")
                                        }}
                                        // ako se promijeni komitent, uzima podatke za novog komitenta
                                        onChange={(event, newValue) => {
                                            setForm(prev => ({ ...prev, KOMTID: newValue && newValue != '' ? newValue.ID : '' }))
                                            setClients(newValue);
                                            // fokus se mora vratiti inace tabIndex ne radi dobro
                                            clientsRef.current.focus()
                                        }}

                                        renderInput={(params) => (
                                            <TextField {...params}
                                                className="clients-textinput"
                                                tabIndex="2"
                                                ref={clientsRef}
                                                placeholder=' Izaberite komitenta'
                                                variant="standard" />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12" style={{ display: 'flex' }}>
                        <div className="col-12">
                            <div className="form-group row label-box-div">
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Usluga:</label>
                                <div className="col-10">
                                    <Autocomplete
                                        //ukoliko nema ove funkcije, u konzoli izbacuje upozorenje da nije vrijednost jedinstvena
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        {...defaultPropsService}
                                        value={service}
                                        disablePortal={true}
                                        onFocus={() => {
                                            unosIspravanDo("USLUGA")
                                        }}

                                        // ako se promijeni skladiste, uzima podatke za nov izabran0 skladiste
                                        onChange={(event, newValue) => {
                                            setForm(prev => ({ ...prev, USLUGA: newValue && newValue != '' ? newValue.SIFRA : '' }))
                                            setService(newValue);
                                            // fokus se mora vratiti inace tabIndex ne radi dobro
                                            serviceRef.current.focus()
                                        }}

                                        renderInput={(params) => (
                                            <TextField {...params}
                                                className="clients-textinput"
                                                tabIndex="3"
                                                ref={serviceRef}
                                                placeholder=' Izaberite uslugu'
                                                variant="standard"
                                                // fokus se mora vratiti inace nece ici na polje dokument od
                                                onFocus={() => {
                                                    serviceRef.current.focus()
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}> */}
                    <div className="col-12" style={{ display: 'flex', }}>
                        <div className="col-12">

                            <div className="form-group row label-box-div">
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Dok. od:</label>
                                <div className="col-2" style={{ marginRight: 'auto' }}>
                                    <NumericFormat
                                        type="number"
                                        getInputRef={docFromRef}
                                        tabIndex="4"
                                        // broj decimala
                                        decimalScale={0}
                                        id="media-query-input"
                                        placeholder="dokument od"
                                        name='DOKUMENTOD'
                                        value={form ? form.DOKUMENTOD : ''}
                                        onFocus={() => {
                                            unosIspravanDo("DOKUMENTOD")
                                        }}
                                        onChange={(e) => handleChange(e)} />
                                </div>

                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-to">do:</label>
                                <div className="col-2" style={{ marginRight: 'auto' }}>
                                    <NumericFormat
                                        type="number"
                                        getInputRef={docToRef}
                                        // broj decimala
                                        decimalScale={0}
                                        tabIndex="6"
                                        id="media-query-input-to"
                                        placeholder="dokument do"
                                        name='DOKUMENTDO'
                                        value={form ? form.DOKUMENTDO : ''}
                                        onFocus={() => {
                                            unosIspravanDo("DOKUMENTDO")
                                            checkCompanyYear()
                                        }}
                                        onChange={(e) => handleChange(e)} />
                                </div>

                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-report-year">Godina:</label>
                                <div className="col-2" style={{ marginRight: 'auto' }}>
                                    <div className={errorInputYear ? 'my-input' : 'field-container'}>
                                        <NumericFormat
                                            type="number"
                                            getInputRef={yearRef}
                                            id="media-query-input"
                                            tabIndex="5"
                                            maxLength="4"
                                            // broj decimala
                                            decimalScale={0}
                                            placeholder="godina"
                                            name='GODINA'
                                            value={form ? form.GODINA : ''}
                                            onFocus={() => {
                                                unosIspravanDo("GODINA")
                                                setForm(prev => ({ ...prev, GODINA: form.companyYear }))
                                            }}
                                            onChange={(e) => handleChange(e)} />
                                        {errorMsgYear ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgYear}</span> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col-12" style={{ display: 'flex', }}>
                        <div className="col-12">

                            <div className="form-group row label-box-div">
                                <label className="col-form-label col-lg-2 label-box" id="media-query-label-report">Dat. od:</label>
                                <div className="col-2">
                                    <div className={errorDateFrom ? 'my-input' : 'field-container'}>
                                        <input
                                            type='text'
                                            tabIndex="7"
                                            id="media-query-input"
                                            ref={dateFromRef}
                                            //   selected={startDate}
                                            onFocus={() => {
                                                unosIspravanDo("DATUMOD")
                                            }}
                                            placeholder="datum"
                                            name='DATUMOD'
                                            value={form?.DATUMOD}
                                            onChange={(e) => handleChange(e)} />
                                        {errorMsgDateFrom ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDateFrom}</span> : ''}
                                    </div>
                                </div>

                                {/* <label className="col-form-label col-lg-1 label-box" id="media-query-label-report"></label>
                                    <div className="col-1" >
                                    </div> */}

                                <label className="col-form-label col-lg-2 label-box-do" id="media-query-label-DateTo">do:</label>
                                <div className="col-2">
                                    <div className={errorDateTo ? 'my-input' : 'field-container'}>
                                        <input type='text'
                                            tabIndex="8"
                                            id="media-query-input-to"
                                            ref={dateToRef}
                                            //  selected={endDate}
                                            onFocus={() => {
                                                unosIspravanDo("DATUMDO")
                                            }}
                                            placeholder="datum do"
                                            value={form?.DATUMDO}
                                            name='DATUMDO'
                                            onChange={(e) => handleChange(e)} />
                                        {errorMsgDateTo ? <span style={{ width: '100%', color: 'red', fontSize: '11px', fontFamily: 'cursive' }}>{errorMsgDateTo}</span> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Col>
                <Col lg='4'>
                    <Row>
                        <Col lg='12' >
                            <div className=" flex-lg-row justify-content-end" id='media-query-buttonType'>
                                <div style={{ display: 'inline-grid' }} className="btn-report">
                                    <button tabIndex={9} onClick={() => {
                                        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                        if (!unosIspravanDo('DUGME')) return;
                                        form.buttonType = 'pregledPoDokumentima'
                                        setPending(true)
                                        //  setButtonTypeTotal(null)
                                        //  setButtonOnClick(!buttonOnClick)
                                        getReports(1, perPage)
                                        //   setModalErrorOpen(false)


                                    }} > Pregled po dokumentima</button>
                                    <button tabIndex={10} onClick={() => {
                                        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                        if (!unosIspravanDo('DUGME')) return;
                                        form.buttonType = 'pregledPoKomitentima'
                                        setPending(true)
                                        //   setButtonTypeTotal(null)
                                        //   setButtonOnClick(!buttonOnClick)
                                        getReports(1, perPage)
                                        //   setModalErrorOpen(false)
                                    }
                                    }>Pregled po komitentima</button>
                                    <button tabIndex={11} onClick={() => {
                                        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                        if (!unosIspravanDo('DUGME')) return;
                                        form.buttonType = 'pregledPoUslugama'
                                        setPending(true)
                                        //    setButtonTypeTotal(null)
                                        getReports(1, perPage)
                                        //    setButtonOnClick(!buttonOnClick)
                                        //   setModalErrorOpen(false)
                                    }
                                    }>Pregled po uslugama</button>
                                </div>
                                <div style={{ display: 'inline-grid' }} className="btn-report">
                                    <button tabIndex={12} onClick={() => {
                                        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                        if (!unosIspravanDo('DUGME')) return;
                                        setPending(true)
                                        //   setButtonTypeTotal('totalPoDokumentu')
                                        form.buttonType = 'totalPoDokumentu'
                                        getReports(1, perPage)
                                        //  setButtonOnClick(!buttonOnClick)
                                        //    setModalErrorOpen(false)
                                    }}>Total po dokumentima</button>

                                    <button tabIndex={13} onClick={() => {
                                        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                        if (!unosIspravanDo('DUGME')) return;
                                        setPending(true)
                                        //    setButtonTypeTotal('totalPoKomitentima')
                                        form.buttonType = 'totalPoKomitentima'
                                        getReports(1, perPage)
                                        //   setButtonOnClick(!buttonOnClick)
                                        //    setModalErrorOpen(false)
                                    }} > Total po komitentima</button>
                                    <button tabIndex={14} onClick={() => {
                                        // uslov: ako nisu unesena obavezna polja, zaustavlja upis
                                        if (!unosIspravanDo('DUGME')) return;
                                        setPending(true)
                                        //    setButtonTypeTotal('totalPoUslugama')
                                        form.buttonType = 'totalPoUslugama'
                                        getReports(1, perPage)
                                        //    setButtonOnClick(!buttonOnClick)
                                        //   setModalErrorOpen(false)
                                    }}>Total po uslugama</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>

            </div>
        </Container >

        <div id='media-query-datatable'>
            {
                < DataTable
                    columns={columns}
                    data={invoiceReport ? invoiceReport : []}
                    pagination
                    responsive
                    highlightOnHover
                    striped
                    dense
                    pointerOnHover
                    paginationComponentOptions={defaultComponentOptions}
                    progressPending={pending}
                    paginationDefaultPage={[20]}

                />
            }
        </div>
        {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <label className="col-form-label col-lg-2 label-box" id="media-query-label-sum">Ukupno:</label>
                <input style={{ marginLeft: '10px', maxWidth: '10%', textAlign: 'right', paddingRight: '5px' }} type='text' className="total" readOnly={true} tabIndex="-1" id="media-query-input-text" value={sum && sum != 'NaN' ? numberWithCommas(sum) : 0} />
            </div> */}

        <div className="naslov-ikonice">
            <ul className="nav flex-column flex-lg-row justify-content-end">
                <div style={{ display: 'flex', justifyContent: 'end', position: 'fixed', bottom: '0', flex: '0 0 auto' }}>

                    <li className="nav-item toolbaritem" style={{ width: '23%', marginRight: '5px' }}>
                        <div className='col-form-right'>
                            <div className="field-container-number">
                                <label id="media-query-label-sum">Vrijednost</label>
                                <input className="form-control" readOnly={true} tabIndex="-1" type='text' id="media-query-input-text" value={sumVrijednost && sumVrijednost != 'NaN' ? sumVrijednost : 0} />
                            </div>
                        </div>
                    </li>

                    <li className="nav-item toolbaritem" style={{ width: '23%', marginRight: '4px' }}>
                        <div className='col-form-right'>
                            <div className="field-container-number">
                                <label id="media-query-label-sum">Porez</label>
                                <input className="form-control" readOnly={true} type='text' tabIndex="-1" id="media-query-input-text" value={sumPorez && sumPorez != 'NaN' ? sumPorez : 0} />
                            </div>
                        </div>
                    </li>

                    <li className="nav-item toolbaritem" style={{ width: '23%', marginRight: '7px' }}>
                        <div className='col-form-right'>
                            <div className="field-container-number">
                                <label id="media-query-label-sum">Ukupno</label>
                                <input type='text' className="total" readOnly={true} id="media-query-input-text" tabIndex="-1" value={sum && sum != 'NaN' ? (sum) : 0} />
                            </div>
                        </div>
                    </li>
                </div>
            </ul>
        </div>

        {/* <div>
                <Modal isOpen={modalErrorOpen} centered  >
                    <ModalHeader>Neispravan unos</ModalHeader>
                    <ModalBody>
                        <span>{`Unesite obavezna polja(skladište, godinu, datum od i datum do)!`}</span>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button onClick={() => setModalErrorOpen(false)} className="button-yes">Ok</button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div> */}

        {/* Modalni form za gresku; prikazuje se ako nisu unijeta obavezna polja */}

        <div>
            <Modal isOpen={modalErrorOpenPrint} centered  >
                <ModalHeader>Upozorenje!</ModalHeader>
                <ModalBody>
                    <span>{`Izaberite izvještaj koji želite štampati!`}</span>
                </ModalBody>
                <ModalFooter style={{ justifyContent: 'center' }}>
                    <div>
                        {/* Dugme za zatvaranje modalnog forma */}
                        <button onClick={() => setModalErrorOpenPrint(false)} className="button-yes">Ok</button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>

        <div>
            <Modal isOpen={modalErrorOpenData} centered  >
                <ModalHeader>Nema podataka!</ModalHeader>
                <ModalFooter style={{ justifyContent: 'center' }}>
                    <div>
                        {/* Dugme za zatvaranje modalnog forma */}
                        <button onClick={() => setModalErrorOpenData(false)} className="button-yes">Ok</button>
                    </div>
                </ModalFooter>
            </Modal>
        </div>

        {/* spiner ili progres bar ya prikaz pokretanja datog izvjestaja */}
        <div>
            {spinner ? <Spinner className='spinner' color="primary" id='loader' /> : ''}
        </div>
    </div >
);

}

export default InvoiceReport;





