import React, { useState } from 'react';
import './style/global.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './invoicing/home';
import Reports from './invoicing/reports';
import Konto from './invoicing/konto';
import VrstaDok from './invoicing/vrstaDok';
import Login from './invoicing/login';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './components/sidebar';
import Navbar from './components/navbar';
import OrganizationalUnit from './invoicing/organizationalUnit';
import Country from './invoicing/country';
import Blagajna from './components/blagajna/blagajna';
import VrstaKnjizenja from './invoicing/blagdok';
import Region from './invoicing/region';
import Client from './invoicing/client';
import Item from './invoicing/item';
import Company from './invoicing/company';
import Places from './invoicing/places';
import Commercialist from './invoicing/commercialist';
import Tax from './invoicing/tax';
import Note from './invoicing/note';
import Warehouse from './invoicing/warehouse';
import Ambalaza from './invoicing/ambalaza';
import Artikal from './invoicing/artikal';
//import Lager from './components/robno/lager';
import Brend from './invoicing/brend';
import Grupa from './invoicing/grupa';
import Opza from './invoicing/opza';
import Podgrupa from './invoicing/podgrupa';
import Poslovna from './invoicing/poslovna';
import Predracun from './components/robno/predracun';
import Smjena from './invoicing/smjena';
import Trosak from './invoicing/trosak';
import Valuta from './invoicing/valuta';
import Vozila from './invoicing/vozila';
import ServiceCharge from './invoicing/serviceCharge';
import ServiceChargeForClients from './invoicing/serviceChargeForClients';
import Invoices from './invoicing/invoices';
import FinNalog from './invoicing/finNalog';
import Dokument from './invoicing/dokument';
import InvoiceReport from './invoicing/invoiceReport';
import BlagajnaReport from './components/blagajna/blagajnaReport';
import FinReport from './components/finansije/finReport';
import FinBrutoBilans from './components/finansije/finBrutoBilans';
import FinBrutoBilans1 from './components/finansije/finBrutoBilans1';
import PrikazPoVrstiKnjizenja from './components/finansije/prikazPoVrstiKnjizenja';
import PregledPoVrstiIKomitentu from './components/finansije/pregledPoVrstiIKomitentu';
import User from './components/user';
import Layout from './components/layout';
import Missing from './invoicing/missing';
import Unauthorized from './invoicing/unauthorized';
import RequireAuth from './components/requireAuth';
import '../src/components/utilities';
import '../src/invoicing/pregledPoDokumentima';
const ROLES = {
  'User': 3,
  'Admin': 4,
  'SuperAdmin': 99
}

function App() {

  const windowWidthW = typeof window !== "undefined" ? window.innerWidth : 0
const windowHeightH = typeof window !== "undefined" ? window.innerHeight : 0

   const [windowWidth, setWindowWidth] = useState(0)
   const [windowHeight, setWindowHeight] = useState(0)
  
 const componentDidMount = () => {
    window.addEventListener("resize", windowWidthW, windowHeightH);

    setWindowWidth(windowWidth)
    setWindowHeight(windowHeight)   
 }
//  console.log(windowWidthW, 'windowWidth', windowHeightH, 'windowHeight' );
  const componentWillUnmount = () => {
    window.removeEventListener("resize", windowWidth, windowHeight);
  }

  return (
    <>
      <BrowserRouter>
        <div>
          <Routes>{componentDidMount}
            <Route path='/login' element={<Login />} />
            <Route path="unauthorized" element={<Unauthorized />} />
            <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
              <Route element= {<Layout />}>
                <Route path="/" element={<Home />} />
                <Route path='/reports' element={<Reports />} />
                <Route path='/konto' element={<Konto />} />
                <Route path='/vrstaDok' element={<VrstaDok />} />
                <Route path='/organizationalUnit' element={<OrganizationalUnit />} />
                <Route path='/blagajna' element={<Blagajna />} />
                <Route path='/blagdok' element={<VrstaKnjizenja />} />
                <Route path='/country' element={<Country />} />
                <Route path='/region' element={<Region />} />
                <Route path='/client' element={<Client />} />
                <Route path='/item' element={<Item />} />
                <Route path='/company' element={<Company />} />
                <Route path='/places' element={<Places />} />
                <Route path='/commercialist' element={<Commercialist />} />
                <Route path='/tax' element={<Tax />} />
                <Route path='/note' element={<Note />} />
                <Route path='/warehouse' element={<Warehouse />} />
                <Route path='/ambalaza' element={<Ambalaza />} />
                <Route path='/artikal' element={<Artikal />} />
                {/* <Route path='/lager' element={<Lager />} /> */}
                <Route path='/brend' element={<Brend />} />
                <Route path='/grupa' element={<Grupa />} />
                <Route path='/opza' element={<Opza />} />
                <Route path='/podgrupa' element={<Podgrupa />} />
                <Route path='/poslovna' element={<Poslovna />} />
                <Route path='/predracun' element={<Predracun />} />
                <Route path='/smjena' element={<Smjena />} />
                <Route path='/trosak' element={<Trosak />} />
                <Route path='/valuta' element={<Valuta />} />
                <Route path='/vozila' element={<Vozila />} />
                <Route path='/serviceCharge' element={<ServiceCharge />} />
                <Route path='/serviceChargeForClients' element={<ServiceChargeForClients />} />
                <Route path='/invoices' element={<Invoices />} />
                <Route path='/invoiceReport' element={<InvoiceReport />} />
                <Route path='/blagajnaReport' element={<BlagajnaReport />} />
                <Route path='/finBrutoBilans' element={<FinBrutoBilans />} />
                <Route path='/finBrutoBilans1' element={<FinBrutoBilans1 />} />
                <Route path='/prikazPoVrstiKnjizenja' element={<PrikazPoVrstiKnjizenja />} />
                <Route path='/pregledPoVrstiIKomitentu' element={<PregledPoVrstiIKomitentu />} />
                <Route path='/finReport' element={<FinReport />} />
                <Route path='/finNalog' element={<FinNalog />} />
                <Route path='/dokument' element={<Dokument />} />
                <Route path='/user' element={<User />} />

              </Route>
            </Route>

            {/* catch all */}
            <Route path="*" element={<Missing />} />
          </Routes>
        </div>

      </BrowserRouter>
    </>
  );
}

export default App;


